<template>
  <div class="settings--content">
    <loading-state v-if="uiFlags.isFetching || uiFlags.isFetchingAgentBot" />
    <form v-else class="row" @submit.prevent="updateActiveAgentBot">
      <settings-section
        :title="$t('AGENT_BOTS.BOT_CONFIGURATION.TITLE')"
        :sub-title="$t('AGENT_BOTS.BOT_CONFIGURATION.DESC')"
      >
        <div class="medium-7 columns label-wrapper">
          <label>
            <SelectTabDropdown
              :buttons="buttons"
              :options="botsOption"
              :button-type="defaultButtonType"
              :selected-item="selectedBot"
              :additional-action-bottom="
                $t('AGENT_BOTS.BOT_CONFIGURATION.ADD_BOT')
              "
              :placeholder="placeholderValue"
              @action="redirectToCreateBot"
              @select="handleSelectedBot"
              @changeButtonType="changeType"
            >
              <div class="no-assistant-wrapper">
                <p class="title">
                  {{ $t('AGENT_BOTS.BOT_CONFIGURATION.NO_RESULT_TITLE') }}
                </p>
                <p class="description">
                  {{ $t('AGENT_BOTS.BOT_CONFIGURATION.NO_RESULT_DESCRIPTION') }}
                </p>
                <div>
                  <div class="button" @click="redirectToCreateBot">
                    {{ $t('AGENT_BOTS.BOT_CONFIGURATION.ADD_BOT') }}
                  </div>
                </div>
              </div>
            </SelectTabDropdown>
          </label>
          <div v-if="!isCallInbox" class="beta-notice">
            <VFlag flag="GB" class="margin-right-2 beta-notice-flag" />
            <div>
              <div class="beta-notice-title">
                {{ $t('AGENT_BOTS.BOT_CONFIGURATION.BETA_NOTICE') }}
              </div>
              <div class="beta-notice-desc">
                {{ $t('AGENT_BOTS.BOT_CONFIGURATION.BETA_DESC') }}
              </div>
            </div>
          </div>
          <div class="button-container label-wrapper__buttons">
            <woot-submit-button
              :button-text="$t('AGENT_BOTS.BOT_CONFIGURATION.SUBMIT')"
              :loading="uiFlags.isSettingAgentBot"
              :disabled="!selectedBot.id"
            />
            <woot-button
              type="button"
              :disabled="!selectedAgentBotId"
              :loading="uiFlags.isDisconnecting"
              variant="smooth"
              color-scheme="alert"
              class="button--disconnect"
              @click="disconnectBot"
            >
              {{ $t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECT') }}
            </woot-button>
          </div>
        </div>
      </settings-section>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingsSection from 'dashboard/components/SettingsSection';
import LoadingState from 'dashboard/components/widgets/LoadingState';
import alertMixin from 'shared/mixins/alertMixin';
import onboardingMixin, {
  ACTIONS,
} from '../../../../../mixins/onboardingMixin';
import agentBotsApi from '../../../../../api/agentBots';
import VFlag from 'dashboard/components/ui/VFlag';
import SelectTabDropdown from '../../../helpcenter/components/SelectTabDropdown.vue';

export default {
  components: {
    SelectTabDropdown,
    LoadingState,
    SettingsSection,
    VFlag,
  },
  mixins: [alertMixin, onboardingMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedAgentBotId: null,
      buttons: [
        { label: 'Rule-Based', type: 'rule' },
        { label: 'Knowledge-Based', type: 'knowledge' },
      ],
      botsOption: [],
      selectedBot: {},
      defaultButtonType: 'rule',
    };
  },
  computed: {
    ...mapGetters({
      agentBots: 'agentBots/getBots',
      uiFlags: 'agentBots/getUIFlags',
    }),
    isCallInbox() {
      return this.inbox.channel_type === 'Channel::Call';
    },
    allAgentBots() {
      if (
        this.inbox.channel_type !== 'Channel::WebWidget' &&
        this.inbox.channel_type !== 'Channel::Telegram'
      )
        return this.agentBots.filter(
          bot => bot.description !== '___typebot___'
        );
      return this.agentBots;
    },
    activeAgentBot() {
      return this.$store.getters['agentBots/getActiveAgentBot'](this.inbox.id);
    },
    placeholderValue() {
      return this.selectedBot?.label
        ? this.selectedBot.label
        : this.$t('AGENT_BOTS.BOT_CONFIGURATION.TITLE');
    },
  },
  watch: {
    allAgentBots(newValue) {
      this.botsOption = newValue.map(bot => {
        return {
          ...bot,
          type: bot.description === '___typebot___' ? 'rule' : 'knowledge',
          label: bot.name,
        };
      });
    },
    activeAgentBot() {
      this.selectedAgentBotId = this.activeAgentBot.id;
      if (this.activeAgentBot?.id) {
        this.selectedBot = this.botsOption.find(
          i => i.id === this.activeAgentBot.id
        );
        this.defaultButtonType = this.selectedBot.type;
      } else {
        const resultType = this.botsOption.find(i => i.type === 'rule');
        this.defaultButtonType = resultType?.type
          ? resultType.type
          : 'knowledge';
      }
    },
  },
  mounted() {
    this.$store.dispatch('agentBots/get');
    this.$store.dispatch('agentBots/fetchAgentBotInbox', this.inbox.id);
  },
  methods: {
    changeType(type) {
      this.defaultButtonType = type;
    },
    async updateActiveAgentBot() {
      try {
        const canUpdate = await this.checkCanUpdate(this.selectedAgentBotId);
        if (!canUpdate) return;

        const botId = this.selectedAgentBotId
          ? this.selectedAgentBotId
          : undefined;

        await this.$store.dispatch('agentBots/setAgentBotInbox', {
          inboxId: this.inbox.id,
          // Added this to make sure that empty values are not sent to the API
          botId,
        });
        this.showAlert(this.$t('AGENT_BOTS.BOT_CONFIGURATION.SUCCESS_MESSAGE'));

        if (botId) {
          this.sendProductFruitsEvent(ACTIONS.AGENTBOT_ASSIGNED_TO_INBOX);
          if (this.activeAgentBot.description === '___typebot___') {
            this.sendProductFruitsEvent(
              ACTIONS.RULE_BASED_AGENTBOT_ASSIGNED_TO_INBOX
            );
          } else {
            this.sendProductFruitsEvent(
              ACTIONS.KNOWLEDGE_BASED_AGENTBOT_ASSIGNED_TO_INBOX
            );
          }
        }
      } catch (error) {
        this.showAlert(this.$t('AGENT_BOTS.BOT_CONFIGURATION.ERROR_MESSAGE'));
      }
    },
    async checkCanUpdate(botId) {
      const bot = this.agentBots.find(b => b.id === botId);
      if (bot?.description === '___typebot___') {
        const { data: typebot } = await agentBotsApi.getTypebot(botId);
        if (!typebot.published) {
          this.showAlert(
            this.$t('AGENT_BOTS.BOT_CONFIGURATION.NOT_PUBLISHED_ERROR_MESSAGE')
          );
          return false;
        }
      }
      return true;
    },
    updateQueryParam() {
      const currentQuery = { ...this.$route.query };
      currentQuery.is_redirected = 0;
      // Build the new query string
      const newQueryString = Object.keys(currentQuery)
        .map(key => `${key}=${encodeURIComponent(currentQuery[key])}`)
        .join('&');
      const newUrl = `${this.$route.path}?${newQueryString}`;
      // updated the URL without redirecting
      window.history.replaceState(null, '', newUrl);
    },
    async redirectToCreateBot() {
      await this.$router.push({
        name: 'agent_bots',
        query: { is_redirected: 1 },
      });
      this.updateQueryParam();
    },
    handleSelectedBot(bot) {
      this.selectedBot = bot;
      this.selectedAgentBotId = bot.id;
    },
    async disconnectBot() {
      try {
        await this.$store.dispatch('agentBots/disconnectBot', {
          inboxId: this.inbox.id,
        });
        this.selectedBot = {};
        this.defaultButtonType = '';
        this.showAlert(
          this.$t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECTED_SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(
          error?.message ||
            this.$t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECTED_ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.no-assistant-wrapper {
  min-height: 160px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: var(--font-size-medium);
    color: var(--d-800);
    font-weight: var(--font-weight-black);
  }
  .description {
    text-align: center;
    color: var(--d-500);
    font-size: var(--font-size-small);
  }
  .button {
    margin-top: 16px;
    padding: 10px 60px;
  }
}
.button--disconnect {
  margin-left: var(--space-small);
  &:hover {
    background: none !important;
  }
}
.assistants-list {
  padding: 10px 12px 10px 12px;
  cursor: pointer;
  &:hover {
    background: #f7f7f9;
  }
}
.label-wrapper {
  min-width: fit-content;
  &__buttons {
    min-width: max-content;
  }
}
.beta-notice {
  padding: var(--space-small) var(--space-snug);
  background-color: #f5f4fa;
  border-radius: var(--border-radius-normal);
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: var(--space-slab);
  .beta-notice-flag {
    height: 18px;
    width: 28px;
  }
  .beta-notice-title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-normal);
    color: var(--color-woot);
  }
  .beta-notice-desc {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-normal);
    color: var(--d-500);
  }
}
</style>
