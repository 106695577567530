import * as rudderanalytics from 'rudder-sdk-js';
class RudderStackHelper {
  static RUDDER_STACK_JS = 'RUDDER_STACK_JS';

  static RUDDER_STACK_APP = 'RUDDER_STACK_APP';

  constructor(source) {
    this.data_plane_url = 'https://hoorypourvxbkb.dataplane.rudderstack.com';
    this.write_key = RudderStackHelper.get_write_key(source);
    if (this.write_key) {
      rudderanalytics.load(this.write_key, this.data_plane_url);
      this.instance = rudderanalytics;
    }
  }

  static get_write_key(source) {
    const hooryConfig = window.hooryConfig || {};
    switch (source) {
      case RudderStackHelper.RUDDER_STACK_JS:
        return hooryConfig.rudderStackWriteKeyJS;
      case RudderStackHelper.RUDDER_STACK_APP:
        return hooryConfig.rudderStackWriteKeyApp;
      default:
        return null;
    }
  }

  track(event, payload) {
    if (this.instance) {
      this.instance.track(event, payload);
    }
  }

  identify(userID, traits) {
    if (this.instance) {
      this.instance.identify(userID, traits);
    }
  }

  page(route) {
    if (this.instance) {
      this.instance.page(route);
    }
  }
}

export default RudderStackHelper;
