<template>
  <div class="tab-container">
    <woot-tabs :index="activeTab" :border="false" @change="onTabChange">
      <template v-for="item in tabs">
        <woot-tabs-item
          v-if="item"
          :key="item.key"
          :test-id="item.key"
          :name="item.name"
          :count="item.count"
          :max-count="item.maxCount"
          :show-badge="item.showBadge"
        />
      </template>
    </woot-tabs>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  watch: {
    selectedTab(value, oldValue) {
      if (value !== oldValue) {
        this.activeTab = this.selectedTab;
      }
    },
  },
  methods: {
    onTabChange(index) {
      this.activeTab = index;
      this.$emit('tab-change', this.tabs[index].key);
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-container {
  margin-top: var(--space-smaller);
  border-bottom: 1px solid var(--s-100);
}
</style>
