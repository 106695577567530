import { mapActions, mapGetters } from 'vuex';
import idleMixin from './idleMixin';

const STATUS_MAP = {
  online: 0,
  offline: 1,
  busy: 2,
};

export default {
  mixins: [idleMixin],
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    ...mapActions('accounts', ['logActivity']),
    addAccountScoping(url) {
      return `/app/accounts/${this.accountId}/${url}`;
    },
    onOnline() {
      this.logActivity({
        accountId: this.accountId,
        status: STATUS_MAP.online,
      });
    },
    onBusy() {
      this.logActivity({
        accountId: this.accountId,
        status: STATUS_MAP.busy,
      });
    },
    onOffline() {
      this.logActivity({
        accountId: this.accountId,
        status: STATUS_MAP.offline,
      });
    },
  },
};
