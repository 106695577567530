<template>
  <div class="search-input-box">
    <div class="search-input">
      <fluent-icon icon="search" class="search--icon" size="20" />
      <input
        v-model="inputValue"
        type="text"
        class="input"
        :placeholder="placeholder"
        @input="handleUpdate"
      />
      <div style="cursor: pointer; margin-top: 4px;" @click="removeValue">
        <fluent-icon
          v-if="inputValue.length > 0"
          icon="add-circle"
          class="reset-search-icon"
          size="20"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    },
  },
  methods: {
    handleUpdate(e) {
      this.$emit('update:value', e.target.value.trim());
    },
    removeValue() {
      this.inputValue = '';
      this.$emit('update:value', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input-box {
  max-width: 400px;
  height: 44px;
  padding: 0 12px 0 12px;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid var(--s-200, #e5e5e5);
  background: var(--v-50, #fff);
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
}
.input {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.reset-search-icon {
  transform: rotate(135deg);
}
.search-input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-smaller);
}
</style>
