<template>
  <div
    v-if="loaded && showIndicator"
    class="onboarding-indicator"
    @click="onClick"
  >
    <div class="progress-bar">
      <div class="bg-radial" />
      <radial-progress-bar
        :diameter="50"
        :completed-steps="completedSteps === 0 ? 1 : completedSteps"
        :total-steps="completedSteps === 0 ? 20 : totalSteps"
        :stroke-width="4"
        :inner-stroke-width="4"
        inner-stroke-color="#ffffff"
        start-color="#B8A6ED"
        stop-color="#B8A6ED"
      >
        <p>{{ percentage }}</p>
      </radial-progress-bar>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RadialProgressBar from 'vue-radial-progress';
import onboardingMixin from 'dashboard/mixins/onboardingMixin';

export default {
  components: { RadialProgressBar },
  mixins: [onboardingMixin],
  data() {
    return {
      loaded: false,
      showIndicator: false,
      completedSteps: 0,
      totalSteps: 0,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      activeChecklist: 'onboarding/activeChecklist',
    }),
    percentage() {
      const p = (100 * this.completedSteps) / this.totalSteps;
      return p.toFixed(0) + '%';
    },
  },
  watch: {
    activeChecklist() {
      this.calcPrecentage();
    },
  },
  mounted() {
    if (!window.productFruits?.api) {
      window.addEventListener('productfruits_ready', () => this.load());
    } else {
      this.load();
    }
  },
  methods: {
    onClick() {
      if (this.completedSteps === this.totalSteps) return;
      this.$emit('click');
    },
    async calcPrecentage() {
      if (this.activeChecklist) {
        this.completedSteps = this.activeChecklist.items.reduce(
          (total, item) => (item.state === 'done' ? total + 1 : total),
          0
        );
        this.totalSteps = this.activeChecklist.items.length;
        this.showIndicator = true;
      } else {
        this.showIndicator = false;
      }
    },
    async load() {
      this.calcPrecentage();
      const api = this.getChecklistAPI();
      api.listen('item-completed', () => {
        this.calcPrecentage();
      });
      this.loaded = true;
    },
  },
};
</script>
<style scoped lang="scss">
.onboarding-indicator {
  margin-bottom: var(--space-small);
  cursor: pointer;
}

.progress-bar {
  position: relative;
  .bg-radial {
    border-radius: 100%;
    width: 42px;
    height: 42px;
    position: absolute;
    left: 0;
    top: 0;
    background: #7f55f8;
    left: 4px;
    top: 4px;
  }

  p {
    margin: 0;
    font-weight: 800;
    font-size: var(--font-size-one);
    color: white;
  }
}
</style>
