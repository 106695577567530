import axios from 'axios';
import ApiClient from './ApiClient';
import wootConstants from 'dashboard/constants/globals.js';

class AgentBotsAPI extends ApiClient {
  SERVICES_PATH = wootConstants.SERVICES_PATH;

  constructor() {
    super('agent_bots', { accountScoped: true });
  }

  getAgentBotAssistants(account_id) {
    return axios.get(
      `/${this.SERVICES_PATH.BOT}/v1/agentbots/accounts/${account_id}/assistants`
    );
  }

  getAllAIModels() {
    return axios.get(`/${this.SERVICES_PATH.BOT}/v1/agentbots/models`);
  }

  getTypebot(botId) {
    return axios.get(
      `/${this.SERVICES_PATH.TYPEBOT_BUILDER}/api/typebots/${botId}/status`
    );
  }
}

export default new AgentBotsAPI();
