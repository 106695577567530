import types from '../mutation-types';

const state = {
  isPanelOpen: false,
  checklists: [],
};

export const getters = {
  isPanelOpen($state) {
    return $state.isPanelOpen;
  },
  checklists: $state => {
    return $state.checklists;
  },
  activeChecklist: $state => {
    return $state.checklists.length > 0 ? $state.checklists[0] : null;
  },
};

export const actions = {
  setPanelOpen: async ({ commit }, { isOpen }) => {
    commit(types.SET_ONBOARDING_PANEL_OPEN, isOpen);
  },
  setChecklists: async ({ commit }, { checklists }) => {
    commit(types.SET_ONBOARDING_CHECKLISTS, checklists);
  },
};

export const mutations = {
  [types.SET_ONBOARDING_PANEL_OPEN]($state, isOpen) {
    $state.isPanelOpen = isOpen;
  },
  [types.SET_ONBOARDING_CHECKLISTS]($state, checklists) {
    $state.checklists = checklists;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
