<template>
  <div v-if="isOffline" class="alert-connection" @click="onClick">
    <fluent-icon icon="alert-connection" size="32" class="warning-icon" />
    {{ $t('CONNECTION_ALERT.TEXT') }}
    <woot-button
      size="small"
      variant="smooth"
      color-scheme="alert"
      @click="onClick"
    >
      {{ $t('CONNECTION_ALERT.BUTTON') }}
    </woot-button>
  </div>
</template>

<script>
import FluentIcon from 'shared/components/FluentIcon/Index.vue';

export default {
  components: {
    FluentIcon,
  },
  data() {
    return {
      isOffline: false,
    };
  },
  computed: {},
  mounted() {
    window.addEventListener('offline', () => {
      this.isOffline = true;
    });
  },
  methods: {
    onClick() {
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.alert-connection {
  background: #fbdfe6;
  color: #ed5e80;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px;
  max-width: 410px;
  position: fixed;
  top: 24px;
  right: 24px;
  border-radius: 12px;
  font-size: 14px;
  ::v-deep {
    svg > path:nth-child(2) {
      fill: #fff !important;
    }
  }
}
</style>
