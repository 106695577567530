<template>
  <li
    v-show="isMenuItemVisible"
    class="sidebar-item"
    :class="{
      'has-sub-menu': hasSubMenu,
      'has-filter-height': hasFilter,
    }"
  >
    <div v-if="hasSubMenu" class="secondary-menu--wrap">
      <span class="secondary-menu--header fs-small">
        {{ $t(`SIDEBAR.${menuItem.label}`) }}
      </span>
      <div v-if="menuItem.showNewButton" class="submenu-icons">
        <woot-button
          size="tiny"
          variant="clear"
          color-scheme="secondary"
          icon="add"
          class="submenu-icon"
          @click="onClickOpen"
        />
      </div>

      <router-link
        v-if="isAdmin"
        v-slot="{ href, isActive, navigate }"
        :to="menuItem.toState"
        custom
      >
        <a
          :href="href"
          class="button small link clear secondary add-btn"
          :class="{ 'is-active': isActive }"
          @click="e => newLinkClick(e, navigate)"
        >
          <fluent-icon icon="add" size="16" />
        </a>
      </router-link>
    </div>
    <router-link
      v-else
      class="secondary-menu--title secondary-menu--link fs-small"
      :class="computedClass"
      :to="menuItem && menuItem.toState"
      :data-testid="`sidebar-${dataTestId}`"
    >
      <fluent-icon
        :icon="menuItem.icon"
        class="secondary-menu--icon"
        size="14"
      />
      {{ $t(`SIDEBAR.${menuItem.label}`) }}
      <span v-if="showChildCount(menuItem.count)" class="count-view">
        {{ `${menuItem.count}` }}
      </span>
      <span
        v-if="menuItem.beta"
        data-view-component="true"
        label="Beta"
        class="beta"
      >
        {{ $t('SIDEBAR.BETA') }}
      </span>
    </router-link>

    <div v-if="hasSubMenu && hasFilter" class="search-wrapper">
      <input
        v-model="filterText"
        :placeholder="$t('CONTACTS_PAGE.FILTER_CONTACTS')"
        type="text"
        class="filter-input"
      />
      <fluent-icon icon="search" />
    </div>
    <div v-if="hasSubMenu" class="nested vertical menu overflow-control">
      <ul
        v-if="filteredChildren.length > 0"
        class="nested vertical menu overflow-control"
      >
        <secondary-child-nav-item
          v-for="child in filteredChildren"
          :key="child.id"
          :to="child.toState"
          :label="child.label"
          :label-color="child.color"
          :should-truncate="child.truncateLabel"
          :icon="computedInboxClass(child)"
          :warning-icon="computedInboxErrorClass(child)"
          :show-child-count="showChildCount(child.count)"
          :child-item-count="child.count"
        />
      </ul>
      <div v-else class="empty-state">
        {{
          $t('SIDEBAR.NO_ITEM_FOUND', { item: $t(`SIDEBAR.${menuItem.label}`) })
        }}
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import enLanguage from '../../../../dashboard/i18n/locale/en/settings.json';

import adminMixin from '../../../mixins/isAdmin';
import {
  getInboxClassByType,
  getInboxWarningIconClass,
} from 'dashboard/helper/inbox';

import SecondaryChildNavItem from './SecondaryChildNavItem';
import {
  isOnArchived,
  isOnMentionsView,
  isOnUnattendedView,
} from '../../../store/modules/conversations/helpers/actionHelpers';
import { replaceToDashLowerCaseE2E } from '../../../helper/commons';

export default {
  components: { SecondaryChildNavItem },
  mixins: [adminMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filterText: '',
      dataTestIdKey: '',
    };
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      globalConfig: 'globalConfig/get',
    }),
    hasFilter() {
      return this.menuItem.children && this.menuItem.children.length > 10000000; // TODO: hide filter for large number of items
    },
    dataTestId() {
      return enLanguage
        ? replaceToDashLowerCaseE2E(enLanguage.SIDEBAR[`${this.dataTestIdKey}`])
        : '';
    },
    filteredChildren() {
      if (!this.filterText) {
        return this.menuItem.children;
      }
      return this.menuItem.children.filter(child => {
        return child.label
          .toLowerCase()
          .includes(this.filterText.toLowerCase());
      });
    },
    hasSubMenu() {
      return !!this.menuItem.children;
    },
    isMenuItemVisible() {
      if (this.menuItem.globalConfigFlag) {
        return !!this.globalConfig[this.menuItem.globalConfigFlag];
      }
      if (this.menuItem.featureFlag) {
        return this.isFeatureEnabledonAccount(
          this.accountId,
          this.menuItem.featureFlag
        );
      }
      return true;
    },
    isAllConversations() {
      return (
        this.$store.state.route.name === 'inbox_conversation' &&
        this.menuItem.toStateName === 'home'
      );
    },
    isMentions() {
      return (
        isOnMentionsView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_mentions'
      );
    },
    isUnattended() {
      return (
        isOnUnattendedView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_unattended'
      );
    },
    isArchived() {
      return (
        isOnArchived({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_archived'
      );
    },
    isTeamsSettings() {
      return (
        this.$store.state.route.name === 'settings_teams_edit' &&
        this.menuItem.toStateName === 'settings_teams_list'
      );
    },
    isInboxSettings() {
      return (
        this.$store.state.route.name === 'settings_inbox_show' &&
        this.menuItem.toStateName === 'settings_inbox_list'
      );
    },
    isIntegrationsSettings() {
      return (
        this.$store.state.route.name === 'settings_integrations_webhook' &&
        this.menuItem.toStateName === 'settings_integrations'
      );
    },
    isApplicationsSettings() {
      return (
        this.$store.state.route.name === 'settings_applications_integration' &&
        this.menuItem.toStateName === 'settings_applications'
      );
    },
    isCurrentRoute() {
      return this.$store.state.route.name.includes(this.menuItem.toStateName);
    },

    computedClass() {
      // If active inbox is present, do not highlight conversations
      if (this.activeInbox) return ' ';
      if (
        this.isAllConversations ||
        this.isMentions ||
        this.isUnattended ||
        this.isArchived ||
        this.isCurrentRoute
      ) {
        return 'is-active';
      }
      if (this.hasSubMenu) {
        if (
          this.isTeamsSettings ||
          this.isInboxSettings ||
          this.isIntegrationsSettings ||
          this.isApplicationsSettings
        ) {
          return 'is-active';
        }
        return ' ';
      }

      return '';
    },
  },
  watch: {
    menuItem(value) {
      this.dataTestIdKey = value.label;
    },
  },
  mounted() {
    this.dataTestIdKey = this.menuItem?.label;
  },
  methods: {
    computedInboxClass(child) {
      const { type, phoneNumber, icon } = child;
      if (!type && !icon) return '';
      if (type) {
        const classByType = getInboxClassByType(type, phoneNumber);
        return classByType;
      }
      return icon;
    },
    computedInboxErrorClass(child) {
      const { type, reauthorizationRequired } = child;
      if (!type) return '';
      const warningClass = getInboxWarningIconClass(
        type,
        reauthorizationRequired
      );
      return warningClass;
    },
    newLinkClick(e, navigate) {
      if (this.menuItem.newLinkRouteName) {
        navigate(e);
      } else if (this.menuItem.showModalForNewItem) {
        if (this.menuItem.modalName === 'AddLabel') {
          e.preventDefault();
          this.$emit('add-label');
        }
      }
    },
    onClickOpen() {
      this.$emit('open');
    },
    showChildCount(count) {
      return Number.isInteger(count);
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar-item {
  margin: var(--space-smaller) 0 0;

  &.has-sub-menu {
    display: flex;
    flex-direction: column;
    margin-top: var(--space-normal);
  }
  &.has-filter-height {
    min-height: 140px;
  }
  .add-btn {
    height: 26px;
    width: 26px;
    &:hover {
      background: var(--s-200);
    }
  }
  .filter-input {
    background: var(--s-50);
    border: 1px solid var(--s-200);
    border-radius: var(--border-radius-normal);
    padding: var(--space-smaller) var(--space-large) var(--space-smaller)
      var(--space-small);
    color: var(--s-600);
    height: 28px;
    font-size: 13px;
  }
  .search-wrapper {
    position: relative;
    margin: 0 var(--space-smaller);
    svg {
      position: absolute;
      right: 8px;
      top: 4px;
      color: var(--s-600);
      width: 18px;
    }
  }
}

.secondary-menu--wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.secondary-menu--header {
  color: var(--s-700);
  display: flex;
  font-weight: var(--font-weight-medium);
  line-height: var(--space-normal);
  margin: var(--space-small) 0;
  padding: 0 var(--space-small);
}
.secondary-menu--title {
  color: var(--s-600);
  display: flex;
  font-weight: var(--font-weight-medium);
  line-height: var(--space-normal);
  margin: var(--space-small) 0;
  padding: 0 var(--space-small);
}

.secondary-menu--link {
  display: flex;
  align-items: center;
  margin: 0;
  padding: var(--space-small);
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius-normal);
  color: var(--s-700);

  &:hover {
    background: var(--white);
    color: var(--s-700);
    box-shadow: var(--shadow-small);
  }

  &:focus {
    border-color: var(--v-300);
  }

  &.router-link-exact-active,
  &.is-active {
    background: var(--white);
    color: var(--v-400);
    border-color: var(--s-25);
    box-shadow: var(--shadow-small);
  }

  &.is-active .count-view {
    background: var(--s-75);
    color: var(--v-600);
  }
}

.secondary-menu--icon {
  margin-right: var(--space-small);
  min-width: var(--space-normal);
  width: 1.8rem;
  height: 1.8rem;
  position: relative;
  left: -1px;
}

.sub-menu-link {
  color: var(--s-600);
}

.wrap {
  display: flex;
  align-items: center;
}

.label-color--display {
  border-radius: var(--space-smaller);
  height: var(--space-normal);
  margin-right: var(--space-small);
  min-width: var(--space-normal);
  width: var(--space-normal);
}

.inbox-icon {
  position: relative;
  top: -1px;
}

.sidebar-item .menu-item--new {
  padding: var(--space-small) 0;

  .button {
    display: inline-flex;
    color: var(--s-700);
    padding: var(--space-small);
  }
}

.beta {
  padding-right: var(--space-smaller) !important;
  padding-left: var(--space-smaller) !important;
  margin: 0 var(--space-smaller) !important;
  display: inline-block;
  font-size: var(--font-size-micro);
  font-weight: var(--font-weight-medium);
  line-height: 14px;
  border: 1px solid transparent;
  border-radius: 2em;
  color: var(--g-800);
  border-color: var(--g-700);
}

.count-view {
  background: var(--s-50);
  border-radius: var(--border-radius-normal);
  color: var(--s-600);
  font-size: var(--font-size-micro);
  font-weight: var(--font-weight-bold);
  margin: 0 var(--space-smaller);
  padding: var(--space-zero) var(--space-smaller);
}

.submenu-icons {
  display: flex;
  align-items: center;

  .submenu-icon {
    padding: 0;
    margin-left: var(--space-small);
  }
}
.empty-state {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-small);
  color: var(--s-600);
  padding: var(--space-large) var(--space-normal);
}
</style>
