export const INBOX_TYPES = {
  WEB: 'Channel::WebWidget',
  FB: 'Channel::FacebookPage',
  TWITTER: 'Channel::TwitterProfile',
  TWILIO: 'Channel::TwilioSms',
  WHATSAPP: 'Channel::Whatsapp',
  API: 'Channel::Api',
  EMAIL: 'Channel::Email',
  TELEGRAM: 'Channel::Telegram',
  LINE: 'Channel::Line',
  SMS: 'Channel::Sms',
  CALL: 'Channel::Call',
};

export const INBOX_FEATURES = {
  REPLY_TO: 'replyTo',
  REPLY_TO_OUTGOING: 'replyToOutgoing',
};

// This is a single source of truth for inbox features
// This is used to check if a feature is available for a particular inbox or not
export const INBOX_FEATURE_MAP = {
  [INBOX_FEATURES.REPLY_TO]: [
    INBOX_TYPES.FB,
    INBOX_TYPES.WEB,
    INBOX_TYPES.TWITTER,
    INBOX_TYPES.WHATSAPP,
    INBOX_TYPES.TELEGRAM,
    INBOX_TYPES.API,
  ],
  [INBOX_FEATURES.REPLY_TO_OUTGOING]: [
    INBOX_TYPES.WEB,
    INBOX_TYPES.TWITTER,
    INBOX_TYPES.WHATSAPP,
    INBOX_TYPES.TELEGRAM,
    INBOX_TYPES.API,
  ],
};

import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showCallBasedPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      newInboxType: 'inboxes/getNewInboxType',
      newInboxData: 'inboxes/getNewInboxData',
      selectedNumber: 'inboxes/getSelectedNumber',
      inboxActionMode: 'inboxes/getInboxActionMode',
      currentAccount: 'getCurrentAccount',
      currentUser: 'getCurrentUser',
    }),
    primaryNumber() {
      return this.inbox.numbers[0];
    },
    channelType() {
      return this.inbox.channel_type;
    },
    whatsAppAPIProvider() {
      return this.inbox.provider || '';
    },
    isAPIInbox() {
      return this.channelType === INBOX_TYPES.API;
    },
    isATwitterInbox() {
      return this.channelType === INBOX_TYPES.TWITTER;
    },
    isAFacebookInbox() {
      return this.channelType === INBOX_TYPES.FB;
    },
    isAWebWidgetInbox() {
      return this.channelType === INBOX_TYPES.WEB;
    },
    isATwilioChannel() {
      return this.channelType === INBOX_TYPES.TWILIO;
    },
    isALineChannel() {
      return this.channelType === INBOX_TYPES.LINE;
    },
    isAnEmailChannel() {
      return this.channelType === INBOX_TYPES.EMAIL;
    },
    isATelegramChannel() {
      return this.channelType === INBOX_TYPES.TELEGRAM;
    },
    showConfigAllowMessagesAfterResolved() {
      return (
        this.isAWebWidgetInbox ||
        this.isATelegramChannel ||
        this.isAFacebookInbox ||
        this.isAWhatsAppChannel
      );
    },
    isATwilioSMSChannel() {
      const { medium: medium = '' } = this.inbox;
      return this.isATwilioChannel && medium === 'sms';
    },
    isASmsInbox() {
      return this.channelType === INBOX_TYPES.SMS || this.isATwilioSMSChannel;
    },
    isACallInbox() {
      return this.channelType === INBOX_TYPES.CALL;
    },
    isATwilioWhatsAppChannel() {
      const { medium: medium = '' } = this.inbox;
      return this.isATwilioChannel && medium === 'whatsapp';
    },
    isAWhatsAppCloudChannel() {
      return (
        this.channelType === INBOX_TYPES.WHATSAPP &&
        this.whatsAppAPIProvider === 'whatsapp_cloud'
      );
    },
    is360DialogWhatsAppChannel() {
      return (
        this.channelType === INBOX_TYPES.WHATSAPP &&
        this.whatsAppAPIProvider === 'default'
      );
    },
    chatAdditionalAttributes() {
      const { additional_attributes: additionalAttributes } = this.chat || {};
      return additionalAttributes || {};
    },
    isTwitterInboxTweet() {
      return this.chatAdditionalAttributes.type === 'tweet';
    },
    twilioBadge() {
      return `${this.isATwilioSMSChannel ? 'sms' : 'whatsapp'}`;
    },
    twitterBadge() {
      return `${this.isTwitterInboxTweet ? 'twitter-tweet' : 'twitter-dm'}`;
    },
    facebookBadge() {
      return this.chatAdditionalAttributes.type || 'facebook';
    },
    inboxBadge() {
      let badgeKey = '';
      if (this.isATwitterInbox) {
        badgeKey = this.twitterBadge;
      } else if (this.isAFacebookInbox) {
        badgeKey = this.facebookBadge;
      } else if (this.isATwilioChannel) {
        badgeKey = this.twilioBadge;
      } else if (this.isAWhatsAppChannel) {
        badgeKey = 'whatsapp';
      }
      return badgeKey || this.channelType;
    },
    isAWhatsAppChannel() {
      return (
        this.channelType === INBOX_TYPES.WHATSAPP ||
        this.isATwilioWhatsAppChannel
      );
    },
    getDefaultGreeting() {
      return this.$t('INBOX_MGMT.ADD.CALL_CHANNEL.GREETING.PLACEHOLDER', {
        name: this.currentAccount.name,
      });
    },
  },
  methods: {
    goToGivenStep(name, params = {}) {
      this.$router.replace({ name, params });
    },
    gotToSettings() {
      this.goToGivenStep('settings_inbox_show', {
        inboxId: this.$route.params.inbox_id,
      });
    },
    setNewInboxType(type) {
      this.$store.commit('inboxes/SET_NEW_INBOX_TYPE', type);
    },
    setNewInboxData(data) {
      this.$store.commit('inboxes/SET_NEW_INBOX_DATA', data);
    },
    setSelectedNumber(number) {
      this.$store.commit('inboxes/SET_SELECTED_NUMBER', number);
    },
    setInboxActionMode(number) {
      this.$store.commit('inboxes/SET_INBOX_ACTION_MODE', number);
    },
    openCallBasedPopup() {
      this.showCallBasedPopup = true;
    },
    hideCallBasedPopup() {
      this.showCallBasedPopup = false;
    },
    inboxHasFeature(feature) {
      return INBOX_FEATURE_MAP[feature]?.includes(this.channelType) ?? false;
    },
  },
};
