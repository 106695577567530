<template>
  <div
    class="context-menu-container"
    :style="style"
    tabindex="0"
    @blur="$emit('close')"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    resetPosition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      left: this.x,
      top: this.y,
      show: false,
    };
  },
  computed: {
    style() {
      if (this.resetPosition) {
        return {
          top: 0,
          left: 0,
        };
      }
      return this.y > window.innerHeight / 2
        ? {
            bottom: `${window.innerHeight - this.top}px`,
            left: `${this.left}px`,
          }
        : {
            top: `${this.top}px`,
            left: `${this.left}px`,
          };
    },
  },
  mounted() {
    this.$nextTick(() => this.$el.focus());
  },
};
</script>
<style>
.context-menu-container {
  position: fixed;
  z-index: var(--z-index-very-high);
  outline: none;
  cursor: pointer;
}
</style>
