export default {
  check_need_dns: email => {
    if (email !== undefined) {
      let domain = email.split('@');
      return domain[1] !== 'hoory-mail.com';
    }
    return false;
  },
  getDomainFromEmail: email => {
    let domain = email.split('@');
    return domain[1];
  },
};
