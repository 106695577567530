/* global axios */
import ApiClient from './ApiClient';

class NotificationsAPI extends ApiClient {
  constructor() {
    super('notifications', { accountScoped: true });
  }

  get(page) {
    return axios.get(`${this.url}?page=${page}`);
  }

  missed() {
    return axios.get(
      `${this.url}?notification_type=missed_call&only_unread=true`
    );
  }

  getNotifications(contactId) {
    return axios.get(`${this.url}/${contactId}/notifications`);
  }

  getUnreadCount() {
    return axios.get(`${this.url}/unread_count`);
  }

  getMissedUnreadCount() {
    return axios.get(
      `${this.url}/unread_count?notification_type=missed_call&only_unread=true`
    );
  }

  read(primaryActorType, primaryActorId) {
    return axios.post(`${this.url}/read_all`, {
      primary_actor_type: primaryActorType,
      primary_actor_id: primaryActorId,
    });
  }

  readAll() {
    return axios.post(`${this.url}/read_all`);
  }

  readMissedCall(primaryActorType = 'Conversation', primaryActorId) {
    const params = {
      notification_type: 'missed_call',
      ...(primaryActorId !== 'all' && {
        primary_actor_type: primaryActorType,
        primary_actor_id: primaryActorId,
      }),
    };

    return axios.post(`${this.url}/read_all`, params);
  }
}

export default new NotificationsAPI();
