<template>
  <banner
    v-if="shouldShowBanner"
    class="update-banner"
    color-scheme="purple"
    :banner-message="bannerMessage"
    :href-link="billingLink"
    :href-link-text="billingText"
    has-close-button
    @close="dismissTrialBanner"
  />
</template>
<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapActions, mapGetters } from 'vuex';
import { hasAnUpdateAvailable } from './versionCheckHelper';
import bannerMixin from 'dashboard/mixins/banner';
import { format } from 'date-fns';

export default {
  components: { Banner },
  mixins: [bannerMixin],
  props: {
    latestChatwootVersion: { type: String, default: '' },
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      subscription: 'billing/getBillingInfo',
      uiFlagsBilling: 'billing/getUIFlags',
    }),
    updateAvailable() {
      return hasAnUpdateAvailable(
        this.latestChatwootVersion,
        this.globalConfig.appVersion
      );
    },
    bannerMessage() {
      return this.$t('APP_GLOBAL.TRIAL_BANNER.MESSAGE', {
        pricingPlan: this.subscription?.product?.name,
        expireDate: this.getTrialExpireDate(),
      });
    },
    billingText() {
      return this.$t('APP_GLOBAL.TRIAL_BANNER.BILLING_BUTTON');
    },
    billingLink() {
      return `/app/accounts/${this.currentAccountId}/settings/billing?utm_source=Trial%20yellow%20banner&utm_medium=Free%20trial%20yellow%20banner&utm_campaign=Trial%20banner`;
    },
  },
  mounted() {
    this.fetchBillingInfo();
  },
  methods: {
    ...mapActions('billing', ['setUserDismissedBanner']),
    async fetchBillingInfo() {
      await this.$store.dispatch(
        'billing/getBillingInfo',
        this.currentAccountId
      );
      if (!this.subscription?.createdAt && this.currentAccountId !== 1) {
        setTimeout(this.fetchBillingInfo, 1000);
      }
    },
    getTrialExpireDate() {
      return format(new Date(this.subscription?.trialEndDate), 'MMM d, yyyy');
    },
    dismissTrialBanner() {
      // LocalStorage.set(
      //   LOCAL_STORAGE_KEYS.DISMISSED_TRIAL_BANNER_EXPIRE,
      //   new Date().getTime() + 3600 * 24 * 1000
      // );
      this.setUserDismissedBanner(true);
    },
  },
};
</script>

<style scoped>
.update-banner {
  position: fixed;
  width: 100%;
  z-index: 2;
}
</style>
