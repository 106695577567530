<template>
  <form
    class="login-box medium-4 column align-self-middle"
    @submit.prevent="submit()"
  >
    <h4>{{ $t('RESET_PASSWORD.TITLE') }}</h4>
    <div class="column log-in-form">
      <label :class="{ error: $v.credentials.email.$error }">
        {{ $t('RESET_PASSWORD.EMAIL.LABEL') }}
        <input
          v-model.trim="credentials.email"
          type="text"
          :placeholder="$t('RESET_PASSWORD.EMAIL.PLACEHOLDER')"
          :disabled="resetPassword.showLoading"
          @input="$v.credentials.email.$touch"
        />
        <span v-if="$v.credentials.email.$error" class="message">
          {{ $t('RESET_PASSWORD.EMAIL.ERROR') }}
        </span>
      </label>
      <woot-submit-button
        v-if="!progress"
        :disabled="$v.credentials.email.$invalid || resetPassword.showLoading"
        :button-text="$t('RESET_PASSWORD.SUBMIT')"
        :loading="resetPassword.showLoading"
        button-class="expanded"
      />
      <div v-else class="progress-wrapper">
        <div class="progress-bar" :style="{ width: progress + '%' }" />
      </div>
    </div>
  </form>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import Auth from '../../api/auth';

export default {
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      progress: 0,
      localErrMsg: null,
      localSucMsg: null,
      credentials: {
        email: '',
      },
      resetPassword: {
        message: '',
        showLoading: false,
      },
      error: '',
    };
  },
  validations: {
    credentials: {
      email: {
        required,
        email,
        minLength: minLength(4),
      },
    },
  },
  methods: {
    showAlert(message, loading = false) {
      // Reset loading, current selected agent
      this.resetPassword.showLoading = loading;
      bus.$emit('newToastMessage', message);
    },
    startProgress() {
      this.progress = 0;
      const interval = setInterval(() => {
        if (this.progress < 94) {
          this.progress += 2;
        }
        if (this.progress >= 100) {
          clearInterval(interval);
          this.passwordChanged();
        }
      }, 20);
    },
    passwordChanged() {
      if (this.localSucMsg) {
        this.showAlert(this.localSucMsg, true);
      } else {
        this.showAlert(this.localErrMsg, false);
        this.progress = 0;
      }
    },
    submit() {
      this.resetPassword.showLoading = true;
      this.startProgress();
      setTimeout(() => {
        Auth.resetPassword(this.credentials)
          .then(res => {
            this.localSucMsg =
              res.data && res.data.message
                ? res.data.message
                : this.$t('RESET_PASSWORD.API.SUCCESS_MESSAGE');
            this.progress = 100;
          })
          .catch(error => {
            this.localErrMsg = error?.response?.data?.message
              ? error.response.data.message
              : this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
            this.progress = 100;
          });
      }, 1000);
    },
  },
};
</script>

<style scoped>
.progress-wrapper {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  height: 8px;
}
.progress-bar {
  height: 100%;
  background-color: #623bea;
  transition: width 0.02s linear;
}
</style>
