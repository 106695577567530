<template>
  <div class="wizard-body call-based-body columns small-9">
    <div class="congrats-page">
      <page-header
        class="channel-type-header"
        :header-title="
          $t('INBOX_MGMT.ADD.CALL_CHANNEL.FINISH.CONGRATS') +
            ' 🎉 ' +
            $t('INBOX_MGMT.ADD.CALL_CHANNEL.FINISH.CONGRATS_MSG')
        "
      />
      <section class="margin-top-2 margin-bottom-2 greeting-section">
        <p class="color-primary fs-small fw-medium">
          {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.GREETING.HEADER') }}
        </p>
        <p class="margin-bottom-2">
          {{ newInboxData.greeting_message }}
        </p>
      </section>
      <p class="color-primary fs-small fw-medium">
        {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.USAGE_PRICING') }}
      </p>
      <section class="pricing">
        <div class="pricing-item">
          <div class="pricing-item-icon">
            <fluent-icon icon="phone-call" size="22" />
          </div>
          <div class="pricing-item-body">
            <p class="color-primary fs-small fw-medium">
              {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.CHANNEL') }}
            </p>
            <div v-if="isBillingDataLoaded" class="pricing-item-row">
              <p>
                {{
                  $t('INBOX_MGMT.ADD.CALL_CHANNEL.FINISH.CHANNEL_SUBSCRIPTION')
                }}
              </p>
              <p>
                {{ formattedPrice(callBaseChannelPrice) }} /
                {{ isMonthlyText }}
              </p>
            </div>
            <div
              v-if="isBillingDataLoaded && selectedNumber"
              class="pricing-item-row"
            >
              <p v-if="selectedNumber">
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.FINISH.BUSINESS_NUMBER') }}
                ({{ selectedNumber.phone_number }})
              </p>
              <p>
                {{ formattedPrice(selectedNumber.fee * 100) }} /
                {{ isMonthlyText }}
              </p>
            </div>
            <p
              v-if="isBillingDataLoaded && selectedNumber"
              class="locality-text fs-small"
            >
              {{ formattedLocation }}
            </p>
          </div>
        </div>
        <div v-if="selectedNumber" class="pricing-item">
          <div class="pricing-item-icon">
            <fluent-icon icon="speaker-1" size="22" view-box="0 0 20 20" />
          </div>
          <div class="pricing-item-body">
            <p class="color-primary fs-small fw-medium">
              {{
                $t(
                  'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.CAPABILITIES.VOICE'
                )
              }}
            </p>
            <div class="pricing-item-row">
              <p class="text-capitalize">
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.OUTBOUND_CALLS') }}
              </p>
              <p>
                {{
                  formattedPrice(numberPricing ? numberPricing.voice.make : 6)
                }}
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PER_MINUTE') }}
              </p>
            </div>
            <div class="pricing-item-row">
              <p class="text-capitalize">
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.INBOUND_CALLS') }}
              </p>
              <p>
                {{
                  formattedPrice(
                    numberPricing ? numberPricing.voice.receive : 4
                  )
                }}
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PER_MINUTE') }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="selectedNumber" class="pricing-item">
          <div class="pricing-item-icon">
            <fluent-icon icon="brand-sms" size="22" />
          </div>
          <div class="pricing-item-body">
            <p class="color-primary fs-small fw-medium">
              {{
                $t(
                  'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.CAPABILITIES.SMS'
                )
              }}
            </p>
            <div class="pricing-item-row">
              <p class="text-capitalize">
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.OUTBOUND_SMS') }}
              </p>
              <p>
                {{ formattedPrice(numberPricing ? numberPricing.sms.out : 3) }}
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PER_SMS') }}
              </p>
            </div>
            <div class="pricing-item-row no-border padding-bottom-0">
              <p class="text-capitalize">
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.INBOUND_SMS') }}
              </p>
              <p>
                {{ formattedPrice(numberPricing ? numberPricing.sms.in : 3) }}
                {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PER_SMS') }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="total-section">
        <div class="total-item fw-bold">
          <p class="fs-normal">
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.FINISH.TOTAL_TODAY') }}
          </p>
          <p v-if="isBillingDataLoaded" class="fs-default">
            {{ formattedPrice(getTotal) }}
          </p>
        </div>
      </section>
    </div>
    <div>
      <woot-submit-button
        :button-text="$t('INBOX_MGMT.ADD.CALL_CHANNEL.FINISH.BUTTON')"
        type="button"
        class="next-btn"
        @click="gotToSettings()"
      />
    </div>
  </div>
</template>

<script>
import configMixin from 'shared/mixins/configMixin';
import PageHeader from '../../../SettingsSubPageHeader';
import billingMixin from 'dashboard/mixins/billing';
import inboxMixin from 'shared/mixins/inboxMixin';
import { mapGetters } from 'vuex';

export default {
  components: {
    PageHeader,
  },
  mixins: [configMixin, billingMixin, inboxMixin],
  computed: {
    ...mapGetters({
      currentAccount: 'getCurrentAccount',
    }),
    getTotal() {
      let total = this.callBaseChannelPrice;
      if (this.selectedNumber) {
        total += this.selectedNumber.fee * 100;
      }
      return total;
    },
    formattedLocation() {
      const parts = [];
      if (this.selectedNumber.locality) {
        parts.push(this.selectedNumber.locality);
      }
      if (this.selectedNumber.region) {
        parts.push(this.selectedNumber.region);
      }
      if (this.countryBasePricing.selectedCountry.name) {
        parts.push(this.countryBasePricing.selectedCountry.name);
      }
      return parts.join(', ');
    },
  },
  created() {
    if (this.newInboxType !== 'call') {
      this.goToGivenStep('settings_inbox_new');
    }
  },
  beforeDestroy() {
    this.setNewInboxData(null);
  },
};
</script>
<style lang="scss" scoped>
.congrats-page {
  width: 80%;
  .pricing {
    p {
      margin-bottom: 0;
    }
    .pricing-item {
      padding-bottom: var(--space-two);
      display: flex;
      align-items: self-start;
      justify-content: start;
      .pricing-item-body {
        width: 100%;
      }
      .pricing-item-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--s-100);
        padding: var(--space-slab) 0;
      }
      .pricing-item-icon {
        margin-right: var(--space-normal);
        font-size: var(--font-size-big);
      }
    }
  }
  .greeting-section {
    border-bottom: 1px solid var(--s-100);
  }
  .total-section {
    padding-top: var(--space-two);
    border-top: 1px solid var(--s-100);
    .total-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .locality-text {
    margin: var(--space-one) 0;
    color: var(--b-400);
  }
}
</style>
