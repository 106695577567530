<template>
  <transition-group name="toast-fade" tag="div" class="ui-snackbar-container">
    <template v-for="snackMessage in snackMessages">
      <woot-snackbar
        v-if="snackMessage"
        :key="snackMessage.key"
        :message="snackMessage.message"
        :action="snackMessage.action"
        :title="snackMessage.title"
      />
    </template>
  </transition-group>
</template>

<script>
import WootSnackbar from './Snackbar';

export default {
  components: {
    WootSnackbar,
  },
  props: {
    duration: {
      type: Number,
      default: 3500,
    },
  },

  data() {
    return {
      snackMessages: [],
    };
  },

  mounted() {
    bus.$on('newToastMessage', this.onNewToastMessage);
  },
  beforeDestroy() {
    bus.$off('newToastMessage', this.onNewToastMessage);
  },
  methods: {
    onNewToastMessage(message, action, title) {
      this.snackMessages.push({
        key: new Date().getTime(),
        message,
        action,
        title,
      });
      window.setTimeout(() => {
        this.snackMessages.splice(0, 1);
      }, this.duration);
    },
  },
};
</script>
