<template>
  <div
    class="reply-to-container"
    :class="{
      'incoming-message': messageType === MESSAGE_TYPE.INCOMING,
      'outgoing-message': messageType === MESSAGE_TYPE.OUTGOING,
      'no-attachments': !parentHasAttachments,
    }"
    @click="scrollToMessage"
  >
    <MessagePreview
      class="message-preview"
      :message="message"
      :message-id="messageID"
      :show-message-type="true"
      :default-empty-message="$t('CONVERSATION.REPLY_MESSAGE_NOT_FOUND')"
    />
  </div>
</template>
<script>
import MessagePreview from 'dashboard/components/widgets/conversation/MessagePreview.vue';
import { MESSAGE_TYPE } from 'shared/constants/messages';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  name: 'ReplyTo',
  components: {
    MessagePreview,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    messageType: {
      type: Number,
      required: true,
    },
    parentHasAttachments: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      MESSAGE_TYPE,
      messageID: null,
    };
  },
  methods: {
    scrollToMessage() {
      this.messageID = this.message.id;
      bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE, {
        messageId: this.message.id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/scss/widgets/reply-box';
</style>
