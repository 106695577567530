<template>
  <div class="billing-page-action-block">
    <div class="fw-medium fs-normal">
      {{ title }}
    </div>
    <div class="billing-page-action-block-content">
      <div class="fs-normal">
        {{ description }}
      </div>
      <woot-button
        type="button"
        variant="smooth"
        color-scheme="secondary"
        @click="$emit('click')"
      >
        {{ buttonLabel }}
      </woot-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.billing-page-action-block {
  padding: var(--space-normal);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--shadow-medium);
  margin-top: var(--space-medium);
  .billing-page-action-block-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
