<template>
  <label class="input-container slug-input--wrap">
    <span>
      <fluent-icon
        v-tooltip="$t(tooltipMessage)"
        class="tooltip-icon"
        size="16"
        :icon="inputIcon"
      />
      {{ $t('INBOX_MGMT.MYHOORY.INBOX_SLUG') }}
    </span>
    <div class="slug-input" :class="{ 'has-error': error }">
      <div v-tooltip="channelDomain + value" class="starter-text-wrap">
        {{ channelDomain }}
      </div>
      <input
        type="text"
        class="slug-input--field"
        style="padding-left: 3px"
        :value="value"
        :placeholder="$t('INBOX_MGMT.MYHOORY.INPUT_PLACEHOLDER')"
        @input="onChange"
      />

      <div class="help-icon" :class="!showCopyBtn && 'top-zero'">
        <span v-if="loading" class="spinner" />
        <a
          v-else-if="channelId != 0"
          :href="`${channelDomain}${value}`"
          target="_blank"
        >
          <woot-button
            type="button"
            color-scheme="secondary"
            size="small"
            variant="clear"
            class="actionButtons"
          >
            <fluent-icon icon="arrow-up-right" size="18" />
          </woot-button>
        </a>
        <woot-button
          v-if="showCopyBtn"
          v-tooltip="{
            content: $t('INBOX_MGMT.MYHOORY.COPIED_TOOLTIP'),
            show: copiedTooltipShow,
            trigger: 'manual',
          }"
          type="button"
          color-scheme="secondary"
          size="small"
          variant="clear"
          class="actionButtons"
          @click="copyTextToClipboard"
        >
          <fluent-icon icon="copy" size="25" />
        </woot-button>
      </div>
    </div>
    <span
      v-if="error && alternativeSlug.length > 0"
      class="alternative-options"
    >
      {{ $t('INBOX_MGMT.MYHOORY.AVAILABLE_OPTIONS') }}
      <woot-button
        v-for="aSlug in alternativeSlug"
        :key="aSlug"
        size="small"
        variant="smooth"
        color-scheme="secondary"
        @click="selectAlternativeSlug(aSlug)"
      >
        {{ aSlug }}
      </woot-button>
    </span>
  </label>
</template>

<script>
import { copyTextToClipboard } from 'shared/helpers/clipboard';

export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    channelId: {
      type: [String, Number],
      default: '',
    },
    showCopyBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slug: this.value,
      error: false,
      loading: false,
      timeout: '',
      alternativeCheckCount: 0,
      alternativeSlug: [],
      copiedTooltipShow: false,
      copiedTooltipShowHandle: null,
    };
  },
  computed: {
    channelDomain() {
      return `${window.hooryConfig.myAiURL}/`;
    },
    inputIcon() {
      if (this.error) {
        return 'dismiss-circle';
      }
      return 'info';
    },
    tooltipMessage() {
      if (this.error) {
        return 'INBOX_MGMT.MYHOORY.UNAVAILABLE_SLUG';
      }
      return 'INBOX_MGMT.MYHOORY.ACCOUNT_SLUG_SUBTEXT';
    },
  },
  methods: {
    async copyTextToClipboard() {
      await copyTextToClipboard(`${this.channelDomain}` + this.value);

      this.copiedTooltipShow = true;
      clearTimeout(this.copiedTooltipShowHandle);
      this.copiedTooltipShowHandle = setTimeout(() => {
        this.copiedTooltipShow = false;
        this.copiedTooltipShowHandle = null;
      }, 1000);
    },
    createSlug(value) {
      return value
        .toString() // Cast to string (optional)
        .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-'); // Replace multiple - with single -
    },
    selectAlternativeSlug(slug) {
      this.slug = slug;
      this.error = false;
      this.$emit('input', this.slug);
      this.$emit('ok');
    },
    async getAlternativeSlug() {
      const slugToCheck = `${this.slug}-${Math.floor(Math.random() * 100) +
        10}`;
      this.alternativeCheckCount += 1;

      if (this.alternativeCheckCount > 10) return;

      try {
        const response = await this.$store.dispatch(
          'inboxSlug/checkAvailability',
          {
            slug: slugToCheck,
            channel_id: this.channelId,
          }
        );
        const { data } = response;
        if (data && data.state === 'AVAILABLE_SLUG') {
          this.alternativeSlug.push(slugToCheck);
          if (this.alternativeSlug.length < 3) {
            await this.getAlternativeSlug();
          }
        } else {
          await this.getAlternativeSlug();
        }
      } catch (error) {
        // handle error
      }
    },
    onChange(e) {
      this.slug = this.createSlug(e.target.value);
      this.$emit('input', this.slug);

      this.checkSlug();
    },
    checkSlug(forcedValue) {
      if (forcedValue) {
        this.slug = forcedValue;
      }

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.loading = true;
        this.alternativeCheckCount = 0;
        this.alternativeSlug = [];
        try {
          const response = await this.$store.dispatch(
            'inboxSlug/checkAvailability',
            {
              slug: this.slug,
              channel_id: this.channelId,
            }
          );
          const { data } = response;
          if (data && data.state === 'AVAILABLE_SLUG') {
            this.$emit('ok');
            this.error = false;
          } else {
            this.$emit('error');
            this.error = true;
            await this.getAlternativeSlug();
          }
          this.loading = false;
        } catch (error) {
          this.error = true;
          this.loading = false;
          this.$emit('error');
        }
      }, 800);
    },
  },
};
</script>
<style scoped lang="scss">
.slug-input--wrap {
  position: relative;

  .slug-input {
    min-width: 215px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: var(--space-normal);
    border: 1px solid var(--s-100);
    border-radius: var(--border-radius-normal);

    &.has-error {
      border: 1px solid var(--r-400);
      svg {
        color: var(--r-400);
      }
    }
  }
  .starter-text-wrap {
    font-size: 14px;
    padding: 0 0 0 10px;
    text-wrap: nowrap;
    cursor: pointer;
  }

  .slug-input--field {
    margin-bottom: 0;
    border: 0;
  }

  .help-icon {
    position: relative;
    top: 5px;
    right: 7px;
    display: flex;
    &.top-zero {
      top: 0;
    }
    button {
      padding: 0 1px;
      margin-left: 3px;
      position: relative;
      top: -4px;
      width: 30px;
      svg {
        position: relative;
        right: -2px;
      }
    }
  }

  .spinner {
    transform: scale(0.8) translate(10px, -3px);
    position: relative;
    top: -4px;
    right: -2px;
  }

  .alternative-options {
    display: block;
    font-size: 12px;
    position: relative;
    top: -6px;
    button {
      margin-left: 5px;
      height: 2.4rem;
      padding: 8px;
    }
  }
}
.tooltip-icon {
  position: relative;
  bottom: -2px;
}
</style>
