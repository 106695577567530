/* global axios */
import ApiClient from '../ApiClient';

class PortalsAPI extends ApiClient {
  constructor() {
    super('portals', { accountScoped: true });
  }

  getPortal({ portalSlug, locale }) {
    return axios.get(`${this.url}/${portalSlug}?locale=${locale}`);
  }

  async updatePortal({ portalSlug, portalObj }) {
    return axios.patch(`${this.url}/${portalSlug}`, portalObj);
  }

  async updatePortalAttachment({ portalSlug, portalObj }) {
    let formData = new FormData();
    if (portalObj.file && portalObj.file !== 'empty') {
      formData.append('favicon', portalObj.file);
    }

    delete portalObj.file;

    Object.entries(portalObj).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return axios.patch(`${this.url}/${portalSlug}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deletePortal(portalSlug) {
    return axios.delete(`${this.url}/${portalSlug}`);
  }
}

export default PortalsAPI;
