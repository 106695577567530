var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wizard-body columns small-9"},[_c('empty-state',{attrs:{"title":'🎉 ' + _vm.$t('INBOX_MGMT.FINISH.TITLE'),"message":_vm.message,"button-text":_vm.$t('INBOX_MGMT.FINISH.BUTTON_TEXT')}},[_c('div',{staticClass:"medium-12 columns text-center"},[_c('div',{staticClass:"website--code"},[(_vm.currentInbox.web_widget_script)?_c('woot-code',{attrs:{"script":_vm.scriptWithSettings}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"medium-6 small-offset-3"},[(_vm.isATwilioInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),(_vm.isWhatsAppCloudInbox)?_c('div',{staticClass:"medium-6 small-offset-3"},[_c('p',{staticClass:"config--label"},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_URL'))+"\n        ")]),_vm._v(" "),_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}),_vm._v(" "),_c('p',{staticClass:"config--label"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_VERIFICATION_TOKEN'
            ))+"\n        ")]),_vm._v(" "),_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.provider_config.webhook_verify_token}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"medium-6 small-offset-3"},[(_vm.isALineInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"medium-6 small-offset-3"},[(_vm.isASmsInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),(_vm.isACallInbox)?_c('div',{staticClass:"medium-6 small-offset-3"},[_c('p',{staticClass:"config--label"},[_vm._v("\n          To complete the configuration, set the following URL as a webhook on\n          the Twilio dashboard for handling incoming calls:\n        ")]),_vm._v(" "),_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.call_webhook_url}}),_vm._v(" "),_c('p',{staticClass:"config--label"},[_vm._v("\n          for detailed instructions, please refer to\n          "),_c('a',{attrs:{"target":"_blank","href":"https://www.twilio.com/docs/voice/tutorials/how-to-respond-to-incoming-phone-calls/ruby#buy-and-configure-a-phone-number"}},[_vm._v("\n            Twilio's documentation.\n          ")])])],1):_vm._e(),_vm._v(" "),(_vm.isAEmailInbox && !_vm.currentInbox.provider)?_c('div',{staticClass:"medium-6 small-offset-3"},[_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.forward_to_email}})],1):_vm._e(),_vm._v(" "),(_vm.isAEmailInbox && !_vm.currentInbox.provider && _vm.need_dns)?_c('div',{staticClass:"medium-12"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('router-link',{staticClass:"button hollow primary settings-button",attrs:{"to":{
            name: 'settings_inbox_show',
            params: { inboxId: this.$route.params.inbox_id },
          }}},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.FINISH.MORE_SETTINGS'))+"\n        ")]),_vm._v(" "),_c('router-link',{staticClass:"button success",attrs:{"to":{
            name: 'inbox_dashboard',
            params: { inboxId: this.$route.params.inbox_id },
          }}},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.FINISH.BUTTON_TEXT'))+"\n        ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }