var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('woot-modal-header',{attrs:{"header-title":_vm.$t('FILTER.TITLE')}},[_c('p',[_vm._v(_vm._s(_vm.$t('FILTER.SUBTITLE')))])]),_vm._v(" "),_c('div',{staticClass:"row modal-content"},[_c('div',{staticClass:"medium-12 columns filters-wrap"},[_vm._l((_vm.appliedFilters),function(filter,i){return [(
            _vm.conversationType !== 'archived' ||
              (_vm.conversationType === 'archived' &&
                _vm.appliedFilters[i].attribute_key !== 'status')
          )?_c('filter-input-box',{key:i,attrs:{"filter-groups":_vm.filterGroups,"conversation-type":_vm.conversationType,"input-type":_vm.getInputType(
              _vm.appliedFilters[i].attribute_key,
              _vm.appliedFilters[i].filter_operator
            ),"operators":_vm.getOperators(_vm.appliedFilters[i].attribute_key),"dropdown-values":_vm.getDropdownValues(_vm.appliedFilters[i].attribute_key),"show-query-operator":i !== _vm.appliedFilters.length - 1,"show-user-input":_vm.showUserInput(_vm.appliedFilters[i].filter_operator),"grouped-filters":true,"v":_vm.$v.appliedFilters.$each[i]},on:{"resetFilter":function($event){return _vm.resetFilter(i, _vm.appliedFilters[i])},"removeFilter":function($event){return _vm.removeFilter(i)}},model:{value:(_vm.appliedFilters[i]),callback:function ($$v) {_vm.$set(_vm.appliedFilters, i, $$v)},expression:"appliedFilters[i]"}}):_vm._e()]}),_vm._v(" "),_c('div',{staticClass:"filter-actions"},[_c('woot-button',{attrs:{"icon":"add","color-scheme":"success","variant":"smooth","size":"small"},on:{"click":_vm.appendNewFilter}},[_vm._v("\n          "+_vm._s(_vm.$t('FILTER.ADD_NEW_FILTER'))+"\n        ")])],1)],2),_vm._v(" "),_c('div',{staticClass:"medium-12 columns"},[_c('div',{staticClass:"modal-footer justify-content-end w-full"},[_c('woot-button',{staticClass:"button clear",on:{"click":function($event){$event.preventDefault();return _vm.onClose($event)}}},[_vm._v("\n          "+_vm._s(_vm.$t('FILTER.CANCEL_BUTTON_LABEL'))+"\n        ")]),_vm._v(" "),_c('woot-button',{on:{"click":_vm.submitFilterQuery}},[_vm._v("\n          "+_vm._s(_vm.$t('FILTER.SUBMIT_BUTTON_LABEL'))+"\n        ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }