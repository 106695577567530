<template>
  <div class="modal-mask">
    <div v-on-clickaway="closePanel" class="panel-wrap">
      <div ref="clContainer" class="cl-container" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import rtlMixin from 'shared/mixins/rtlMixin';
import onboardingMixin from '../../../../mixins/onboardingMixin';

export default {
  components: {},
  mixins: [clickaway, rtlMixin, onboardingMixin],
  data() {
    return {
      pageSize: 15,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      activeChecklist: 'onboarding/activeChecklist',
    }),
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (this.activeChecklist) {
        const api = this.getChecklistAPI();
        await api.injectToElement(
          this.activeChecklist.id,
          this.$refs.clContainer
        );
      }
    },
    closePanel() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.panel-wrap {
  height: 60vh;
  max-height: 80vh;
  width: 40rem;
  position: absolute;
  left: var(--space-jumbo);
  margin: var(--space-small);
  border-radius: var(--border-radius-medium);
  flex-direction: column;
  overflow: hidden;

  .cl-container {
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 30px #00000022;
    border-radius: 10px;
    max-height: 360px;
    align-self: end;
  }
}
</style>
