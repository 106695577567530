<template>
  <div class="account-context--group" @click="$emit('toggle-accounts')">
    {{ $t('PROFILE_SETTINGS.WORKSPACE') }}
    <p class="account-context--name text-ellipsis">
      {{ account.name }}
    </p>
    <transition name="fade">
      <div class="account-context--switch-group">
        <woot-button
          size="large"
          icon="switch-workspace"
          variant="clear"
          color-scheme="secondary"
          view-box="0 0 20 20"
          class="switch-button"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return { showSwitchButton: false };
  },
  computed: {
    ...mapGetters({
      account: 'getCurrentAccount',
      userAccounts: 'getUserAccounts',
    }),
  },
  methods: {
    setShowSwitch() {
      this.showSwitchButton = this.account.name;
    },
    resetShowSwitch() {
      this.showSwitchButton = false;
    },
  },
};
</script>
<style scoped lang="scss">
.account-context--group {
  cursor: pointer;
  border-radius: var(--border-radius-medium);
  background-color: var(--s-75);
  padding: var(--space-small) var(--space-one);
  margin: 0 var(--space-small);
  position: relative;
  color: var(--s-600);
  font-size: var(--font-size-mini);

  &:hover {
    background: var(--s-100);
  }

  .account-context--name {
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--space-zero);
    color: var(--s-800);
    font-size: var(--font-size-small);
  }
}

.switch-button {
  margin: 0 var(--space-small);
  &:hover {
    background: transparent;
  }
}

.account-context--switch-group {
  align-items: center;
  background-image: var(--overlay-shadow);
  border-top-left-radius: 0;
  border-top-right-radius: var(--border-radius-normal);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--border-radius-normal);
  display: flex;
  height: 100%;
  justify-content: flex-end;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
