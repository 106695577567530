<template>
  <div
    class="flag-item"
    :style="{
      backgroundImage: svgFlag
        ? `url('data:image/svg+xml,${encodeURIComponent(svgFlag)}')`
        : 'none',
    }"
  />
</template>

<script lang="ts">
import { hasFlag } from 'country-flag-icons';
import Vue from 'vue';

export default Vue.extend({
  name: 'VFlag',
  props: {
    flag: {
      type: String,
      required: true,
      validator: value => {
        return hasFlag(value);
      },
    },
  },
  data() {
    return {
      svgFlag: null,
    };
  },
  watch: {
    flag: {
      immediate: true,
      handler: async function flagHandler(newFlag, oldFlag) {
        if (newFlag !== oldFlag) {
          const svg = await import(
            `country-flag-icons/string/3x2/${newFlag}.js`
          );
          this.svgFlag = svg?.default;
        }
      },
    },
  },
});
</script>
<style scoped lang="scss">
.flag-item {
  background-size: cover;
  background-position: center;
  box-shadow: var(--shadow-medium);
  display: inline-block;
}
</style>
