const maxWidthMixin = {
  data() {
    return {
      dynamicStyle: {},
      subtractValue: 0,
    };
  },
  mounted() {
    this.updateMaxWidth();
    window.addEventListener('resize', this.updateMaxWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMaxWidth);
  },
  methods: {
    updateMaxWidth() {
      this.dynamicStyle = {
        'max-width': `${Math.round(
          window.innerWidth - this.subtractValue
        )}px !important`,
      };
    },
  },
  watch: {
    subtractValue(newValue) {
      this.subtractValue = newValue;
      this.updateMaxWidth();
    },
  },
};

export default maxWidthMixin;
