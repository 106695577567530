<template>
  <modal :show.sync="show" :on-close="onClose">
    <woot-modal-header
      :header-title="title"
      :header-content="message"
      :header-content-value="messageValue"
    />
    <form v-if="showForm" class="row">
      <div class="columns medium-6">
        <label :class="{ error: $v.ipAddress.$error }">
          {{ $t('CONTACT_BAN_FORM.FORM.IP_ADDRESS.LABEL') }}
          <input
            v-model.trim="ipAddress"
            type="text"
            :placeholder="$t('CONTACT_BAN_FORM.FORM.IP_ADDRESS.PLACEHOLDER')"
            @input="$v.ipAddress.$touch"
          />
          <span v-if="$v.ipAddress.$error" class="message">
            {{ $t('CONTACT_BAN_FORM.FORM.IP_ADDRESS.ERROR') }}
          </span>
        </label>
      </div>
      <div class="columns medium-1" />
      <div class="columns medium-5">
        <label :class="{ error: $v.durationHours.$error }">
          {{ $t('CONTACT_BAN_FORM.FORM.DURATION_HOURS.LABEL') }}
          <input
            v-model.trim="durationHours"
            type="number"
            :placeholder="
              $t('CONTACT_BAN_FORM.FORM.DURATION_HOURS.PLACEHOLDER')
            "
            @input="$v.durationHours.$touch"
          />
          <span v-if="$v.durationHours.$error" class="message">
            {{ $t('CONTACT_BAN_FORM.FORM.DURATION_HOURS.ERROR') }}
          </span>
        </label>
      </div>
    </form>
    <div class="modal-footer delete-item">
      <woot-button variant="clear" class="action-button" @click="onClose">
        {{ rejectText }}
      </woot-button>
      <woot-button
        color-scheme="alert"
        class="action-button"
        variant="smooth"
        @click="
          () => {
            if ($v.$invalid) {
              return;
            }
            onConfirm(ipAddress, durationHours);
          }
        "
      >
        {{ confirmText }}
      </woot-button>
    </div>
  </modal>
</template>

<script>
import Modal from 'dashboard/components/Modal';
import {
  minLength,
  integer,
  required,
  minValue,
} from 'vuelidate/lib/validators';

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    showForm: { type: Boolean, default: true },
    onClose: { type: Function, default: () => {} },
    onConfirm: { type: Function, default: () => {} },
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    messageValue: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    rejectText: { type: String, default: '' },
    initialIpAddress: { type: String, default: '' },
  },
  data() {
    return {
      ipAddress: this.initialIpAddress,
      durationHours: 48,
    };
  },
  validations: {
    ipAddress: {
      minLength: minLength(7),
      required,
    },
    durationHours: {
      required,
      integer,
      minValue: minValue(1),
    },
  },
};
</script>
<style lang="scss" scoped>
.action-button {
  max-width: var(--space-giga);
}
::v-deep .wrap-content {
  min-height: 7rem;
}
</style>
