<template>
  <div
    class="conversations-list-wrap"
    :class="{
      hide: !showConversationList,
      'list--full-width': isOnExpandedLayout,
    }"
  >
    <slot />
    <div
      class="chat-list__top"
      :class="{ filter__applied: hasAppliedFiltersOrActiveFolders }"
    >
      <div class="flex-center chat-list__title wrapper">
        <h1
          class="page-sub-title text-truncate margin-bottom-0"
          :title="pageTitle"
        >
          {{ pageTitle }}
          <span
            v-if="hasAppliedFilters && !chatListLoading"
            style="cursor: pointer"
            @mouseover="showFilters = true"
            @mouseleave="showFilters = false"
          >
            ({{ allCount }})
          </span>
        </h1>
        <selected-filters-view
          :show-filters="showFilters"
          :all-counts="allCount"
          :selected-filters="selectedFilters"
          :conversation-type="conversationType"
        />
        <span
          v-if="
            !hasAppliedFiltersOrActiveFolders && conversationType !== 'archived'
          "
          class="conversation--status-pill"
        >
          {{
            this.$t(`CHAT_LIST.CHAT_STATUS_FILTER_ITEMS.${activeStatus}.TEXT`)
          }}
        </span>
      </div>
      <div class="filter--actions">
        <div v-if="hasAppliedFilters && !hasActiveFolders">
          <woot-button
            v-if="conversationType !== 'archived'"
            v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.ADD.SAVE_BUTTON')"
            size="small"
            variant="smooth"
            color-scheme="secondary"
            icon="save"
            @click="onClickOpenAddFoldersModal"
          />
          <woot-button
            v-tooltip.top-end="$t('FILTER.CLEAR_BUTTON_LABEL')"
            size="small"
            variant="smooth"
            color-scheme="alert"
            icon="dismiss-circle"
            @click="clearAdvancedFilters"
          />
        </div>
        <div v-if="hasActiveFolders">
          <woot-button
            v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.DELETE.DELETE_BUTTON')"
            size="small"
            variant="smooth"
            color-scheme="alert"
            icon="delete"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            fill-color="none"
            @click="onClickOpenDeleteFoldersModal"
          />
        </div>
        <woot-button
          v-else
          v-tooltip.right="$t('FILTER.TOOLTIP_LABEL')"
          v-filtered-circle="isFiltered"
          variant="smooth"
          color-scheme="secondary"
          icon="filter"
          size="small"
          view-box="0 0 20 20"
          @click="onToggleAdvanceFiltersModal"
        />
        <conversation-basic-filter
          v-if="!hasAppliedFiltersOrActiveFolders"
          :conversation-type="conversationType"
          @changeFilter="onBasicFilterChange"
        />
      </div>
    </div>

    <add-custom-views
      v-if="showAddFoldersModal"
      :custom-views-query="foldersQuery"
      :open-last-saved-item="openLastSavedItemInFolder"
      @close="onCloseAddFoldersModal"
    />

    <delete-custom-views
      v-if="showDeleteFoldersModal"
      :show-delete-popup.sync="showDeleteFoldersModal"
      :active-custom-view="activeFolder"
      :custom-views-id="foldersId"
      :open-last-item-after-delete="openLastItemAfterDeleteInFolder"
      @close="onCloseDeleteFoldersModal"
    />

    <chat-type-tabs
      v-if="!hasAppliedFiltersOrActiveFolders"
      :items="assigneeTabItems"
      :active-tab="activeAssigneeTab"
      class="tab--chat-type"
      @chatTabChange="updateAssigneeTab"
    />

    <p v-if="!chatListLoading && !conversationList.length" class="content-box">
      {{ $t('CHAT_LIST.LIST.404') }}
    </p>
    <conversation-bulk-actions
      v-if="selectedConversations.length && conversationType !== 'archived'"
      :conversations="selectedConversations"
      :all-conversations-selected="allConversationsSelected"
      :selected-inboxes="uniqueInboxes"
      :show-open-action="allSelectedConversationsStatus('open')"
      :show-resolved-action="allSelectedConversationsStatus('resolved')"
      :show-snoozed-action="allSelectedConversationsStatus('snoozed')"
      @select-all-conversations="selectAllConversations"
      @assign-agent="onAssignAgent"
      @update-conversations="onUpdateConversations"
      @assign-labels="onAssignLabels"
      @assign-team="onAssignTeamsForBulk"
    />
    <div
      ref="activeConversation"
      class="conversations-list"
      :class="{ 'is-context-menu-open': isContextMenuOpen }"
    >
      <recycle-scroller
        ref="scroller"
        v-slot="{ item }"
        class="recycleview"
        :class="{ 'scroll-end': scrollEnd }"
        :items="conversationList"
        :item-size="115"
        key-field="id"
        @scroll.native="onScroll"
      >
        <conversation-card
          :active-label="label"
          :team-id="teamId"
          :folders-id="foldersId"
          :chat="item"
          :conversation-type="conversationType"
          :show-assignee="showAssigneeInConversationCard"
          :selected="isConversationSelected(item.id)"
          @select-conversation="selectConversation"
          @de-select-conversation="deSelectConversation"
          @context-menu-toggle="onContextMenuToggle"
          @context-menu-data="setContextMenuData"
        />
      </recycle-scroller>
      <div v-if="scrollEnd" class="scroll-end">
        <div v-if="chatListLoading" class="text-center">
          <span class="spinner" />
        </div>

        <woot-button
          v-if="!hasCurrentPageEndReached && !chatListLoading && hasMoreItems"
          variant="clear"
          size="expanded"
          @click="loadMoreConversations"
        >
          {{ $t('CHAT_LIST.LOAD_MORE_CONVERSATIONS') }}
        </woot-button>

        <p
          v-if="showEndOfListMessage"
          class="text-center text-muted end-of-list-text"
        >
          {{ $t('CHAT_LIST.EOF') }}
        </p>
      </div>
    </div>
    <woot-context-menu
      v-if="showContextMenu && contextMenuData"
      ref="menu"
      :x="contextMenu.x"
      :y="contextMenu.y"
      @close="closeContextMenu"
    >
      <conversation-context-menu
        :conversation-id="conversationId"
        :status="contextMenuData.chat.status"
        :inbox-id="contextMenuData.inbox.id"
        :priority="contextMenuData.chat.priority"
        :has-unread-messages="contextMenuData.hasUnread"
        :context-menu-y="contextMenu.y"
        @update-conversation="toggleConversationStatus"
        @assign-agent="onAssignAgent"
        @assign-label="onAssignLabels"
        @assign-team="onAssignTeam"
        @mark-as-unread="markAsUnread"
        @assign-priority="assignPriority"
        @archive="archive"
      />
    </woot-context-menu>
    <woot-modal
      :show.sync="showAdvancedFilters"
      :on-close="closeAdvanceFiltersModal"
      size="medium"
    >
      <conversation-advanced-filter
        v-if="showAdvancedFilters"
        :initial-filter-types="advancedFilterTypes"
        :initial-applied-filters="appliedFilter"
        :on-close="closeAdvanceFiltersModal"
        :conversation-type="conversationType"
        @applyFilter="onApplyFilter"
      />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { LocalStorage } from 'shared/helpers/localStorage';

import ConversationAdvancedFilter from './widgets/conversation/ConversationAdvancedFilter';
import ConversationBasicFilter from './widgets/conversation/ConversationBasicFilter';
import ChatTypeTabs from './widgets/ChatTypeTabs';
import ConversationCard from './widgets/conversation/ConversationCard';
import timeMixin from '../mixins/time';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import conversationMixin from '../mixins/conversations';
import wootConstants from 'dashboard/constants/globals';
import advancedFilterTypes from './widgets/conversation/advancedFilterItems';
import filterQueryGenerator from '../helper/filterQueryGenerator.js';
import AddCustomViews from 'dashboard/routes/dashboard/customviews/AddCustomViews';
import DeleteCustomViews from 'dashboard/routes/dashboard/customviews/DeleteCustomViews.vue';
import ConversationBulkActions from './widgets/conversation/conversationBulkActions/Index.vue';
import alertMixin from 'shared/mixins/alertMixin';
import filterMixin from 'shared/mixins/filterMixin';
import { RecycleScroller } from 'vue-virtual-scroller';
import ConversationContextMenu from './widgets/conversation/contextMenu/Index.vue';
import rtlMixin from 'shared/mixins/rtlMixin';
import { throttle } from 'lodash';

import {
  hasPressedAltAndJKey,
  hasPressedAltAndKKey,
} from 'shared/helpers/KeyboardHelpers';
import { conversationListPageURL } from '../helper/URLHelper';
import {
  isOnMentionsView,
  isOnUnattendedView,
} from '../store/modules/conversations/helpers/actionHelpers';
import { CONVERSATION_EVENTS } from '../helper/AnalyticsHelper/events';
import SelectedFiltersView from './ui/SelectedFiltersView.vue';

export default {
  components: {
    SelectedFiltersView,
    AddCustomViews,
    ChatTypeTabs,
    ConversationCard,
    ConversationAdvancedFilter,
    DeleteCustomViews,
    ConversationBulkActions,
    ConversationBasicFilter,
    RecycleScroller,
    ConversationContextMenu,
  },
  mixins: [
    timeMixin,
    conversationMixin,
    eventListenerMixins,
    alertMixin,
    filterMixin,
    rtlMixin,
  ],
  props: {
    conversationInbox: {
      type: [String, Number],
      default: 0,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    conversationType: {
      type: String,
      default: '',
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
    showConversationList: {
      default: true,
      type: Boolean,
    },
    isOnExpandedLayout: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      activeAssigneeTab: wootConstants.ASSIGNEE_TYPE.ME,
      activeStatus: wootConstants.STATUS_TYPE.OPEN,
      activeSortBy: wootConstants.SORT_BY_TYPE.LATEST,
      activeOrder: wootConstants.ORDER_TYPE.DESCENDING,
      showAdvancedFilters: false,
      advancedFilterTypes: advancedFilterTypes.map(filter => ({
        ...filter,
        attributeName: this.$t(`FILTER.ATTRIBUTES.${filter.attributeI18nKey}`),
      })),
      // chatsOnView is to store the chats that are currently visible on the screen,
      // which mirrors the conversationList.
      chatsOnView: [],
      foldersQuery: {},
      showAddFoldersModal: false,
      showDeleteFoldersModal: false,
      selectedConversations: [],
      selectedInboxes: [],
      isContextMenuOpen: false,
      isFiltered: false,
      allCount: 0,
      appliedFilter: [],
      scrollEnd: false,
      hasMoreItems: false,
      showContextMenu: false,
      contextMenu: {
        x: null,
        y: null,
      },
      contextMenuData: null,
      showFilters: false,
      selectedFilters: [],
      abortController: null,
      isRequestActive: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      chatLists: 'getAllConversations',
      mineChatsList: 'getMineChats',
      allChatList: 'getAllStatusChats',
      unAssignedChatsList: 'getUnAssignedChats',
      chatListLoading: 'getChatListLoadingStatus',
      currentUserID: 'getCurrentUserID',
      activeInbox: 'getSelectedInbox',
      conversationStats: 'conversationStats/getStats',
      oldTab: 'conversationStats/getOldTab',
      appliedFilters: 'getAppliedConversationFilters',
      folders: 'customViews/getCustomViews',
      inboxes: 'inboxes/getInboxes',
      liveUpdateTiming: 'accounts/getLiveUpdateTiming',
    }),
    conversationId() {
      return this.contextMenuData ? this.contextMenuData.chat.id : null;
    },
    hasAppliedFilters() {
      return this.appliedFilters.length !== 0;
    },
    hasActiveFolders() {
      return this.activeFolder && this.foldersId !== 0;
    },
    hasAppliedFiltersOrActiveFolders() {
      return this.hasAppliedFilters || this.hasActiveFolders;
    },
    savedFoldersValue() {
      if (this.hasActiveFolders) {
        const payload = this.activeFolder.query;
        this.fetchSavedFilteredConversations(payload);
      }
      return {};
    },
    showEndOfListMessage() {
      return (
        this.conversationList.length &&
        this.hasCurrentPageEndReached &&
        !this.chatListLoading
      );
    },
    currentUserDetails() {
      const { id, name } = this.currentUser;
      return {
        id,
        name,
      };
    },
    assigneeTabItems() {
      return this.updateCounts();
    },
    showAssigneeInConversationCard() {
      return (
        this.hasAppliedFiltersOrActiveFolders ||
        this.activeAssigneeTab === wootConstants.ASSIGNEE_TYPE.ALL
      );
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.activeInbox);
    },
    currentPage() {
      return this.$store.getters['conversationPage/getCurrentPageFilter'](
        this.activeAssigneeTab
      );
    },
    currentPageFilterKey() {
      return this.hasAppliedFiltersOrActiveFolders
        ? 'appliedFilters'
        : this.activeAssigneeTab;
    },
    currentFiltersPage() {
      return this.$store.getters['conversationPage/getCurrentPageFilter'](
        this.currentPageFilterKey
      );
    },
    hasCurrentPageEndReached() {
      return this.$store.getters['conversationPage/getHasEndReached'](
        this.currentPageFilterKey
      );
    },
    activeAssigneeTabCount() {
      const { activeAssigneeTab } = this;
      const count = this.assigneeTabItems.find(
        item => item.key === activeAssigneeTab
      ).count;
      return count;
    },
    conversationFilters() {
      return {
        inboxId: this.conversationInbox ? this.conversationInbox : undefined,
        assigneeType: this.activeAssigneeTab,
        status: this.activeStatus,
        sortBy: this.activeSortBy,
        order: this.activeOrder,
        page: this.conversationListPagination,
        labels: this.label ? [this.label] : undefined,
        teamId: this.teamId || undefined,
        conversationType: this.conversationType || undefined,
        folders: this.hasActiveFolders ? this.savedFoldersValue : undefined,
      };
    },
    conversationListPagination() {
      const conversationsPerPage = 25;
      const isNoFiltersOrFoldersAndChatListNotEmpty =
        !this.hasAppliedFiltersOrActiveFolders && this.chatsOnView !== [];
      const isUnderPerPage =
        this.chatsOnView.length < conversationsPerPage &&
        this.activeAssigneeTabCount < conversationsPerPage &&
        this.activeAssigneeTabCount > this.chatsOnView.length;

      if (isNoFiltersOrFoldersAndChatListNotEmpty && isUnderPerPage) {
        return 1;
      }
      return this.currentPage + 1;
    },
    pageTitle() {
      if (this.hasAppliedFilters) {
        return this.$t('CHAT_LIST.TAB_HEADING');
      }
      if (this.inbox.name) {
        return this.inbox.name;
      }
      if (this.activeTeam.name) {
        return this.activeTeam.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      if (this.conversationType === 'mention') {
        return this.$t('CHAT_LIST.MENTION_HEADING');
      }
      if (this.conversationType === 'participating') {
        return this.$t('CONVERSATION_PARTICIPANTS.SIDEBAR_MENU_TITLE');
      }
      if (this.conversationType === 'unattended') {
        return this.$t('CHAT_LIST.UNATTENDED_HEADING');
      }
      if (this.conversationType === 'archived') {
        return this.$t('CHAT_LIST.ARCHIVED_HEADING');
      }
      if (this.hasActiveFolders) {
        return this.activeFolder.name;
      }
      return this.$t('CHAT_LIST.TAB_HEADING');
    },
    conversationList() {
      let conversationList = [];
      if (!this.hasAppliedFiltersOrActiveFolders) {
        const filters = this.conversationFilters;
        if (this.activeAssigneeTab === 'me') {
          conversationList = [...this.mineChatsList(filters)];
        } else if (this.activeAssigneeTab === 'unassigned') {
          conversationList = [...this.unAssignedChatsList(filters)];
        } else {
          conversationList = [...this.allChatList(filters)];
        }
      } else {
        conversationList = [...this.chatLists];
      }

      if (this.conversationType === 'archived') {
        conversationList = conversationList.filter(c => c.archived);
      }
      return conversationList;
    },
    activeFolder() {
      if (this.foldersId) {
        const activeView = this.folders.filter(
          view => view.id === Number(this.foldersId)
        );
        const [firstValue] = activeView;
        return firstValue;
      }
      return undefined;
    },
    activeTeam() {
      if (this.teamId) {
        return this.$store.getters['teams/getTeam'](this.teamId);
      }
      return {};
    },
    allConversationsSelected() {
      return (
        this.conversationList.length === this.selectedConversations.length &&
        this.conversationList.every(el =>
          this.selectedConversations.includes(el.id)
        )
      );
    },
    uniqueInboxes() {
      return [...new Set(this.selectedInboxes)];
    },
  },
  watch: {
    liveUpdateTiming(newVal, oldVal) {
      if (oldVal !== -1) {
        bus.$off('fetch_conversation_stats', this.getStats);
      }
      if (newVal !== -1) {
        this.getStats = throttle(() => {
          this.fetchConversationsMeta();
        }, newVal);

        bus.$on('fetch_conversation_stats', this.getStats);
      }
    },
    hasAppliedFiltersOrActiveFolders(newValue) {
      this.isFiltered = !!newValue;
    },
    activeTeam() {
      this.resetAndFetchData();
    },
    conversationInbox() {
      let advancedConversationFilters = LocalStorage.get(
        'advancedConversationFilters'
      );
      if (advancedConversationFilters) {
        if (this.conversationInbox === 0) {
          advancedConversationFilters = advancedConversationFilters.filter(
            f => f.attribute_key !== 'inbox_id'
          );
        } else {
          const inboxFilter = advancedConversationFilters.find(
            f => f.attribute_key === 'inbox_id'
          );
          const inboxes = this.$store.getters['inboxes/getInboxes'];
          const inbox = inboxes.find(
            i => i.id.toString() === this.conversationInbox
          );
          if (inboxFilter) {
            inboxFilter.values = inbox;
          } else {
            advancedConversationFilters.push({
              attribute_key: 'inbox_id',
              custom_attribute_type: '',
              filter_operator: 'equal_to',
              query_operator: 'and',
              values: inbox,
            });
          }
        }
        LocalStorage.set(
          'advancedConversationFilters',
          advancedConversationFilters
        );
      }
      this.resetAndFetchData();
    },
    label() {
      this.resetAndFetchData();
    },
    conversationType() {
      this.resetAndFetchData();
    },
    activeFolder() {
      if (!this.hasAppliedFilters) {
        this.resetAndFetchData();
      }
    },
    chatLists() {
      this.chatsOnView = this.conversationList;
    },
  },
  updated() {
    this.updateCounts();
    this.evaluateLoadMoreCondition();
  },
  mounted() {
    const basicFilterStatus = LocalStorage.get('basicFilter.status');
    const basicFilterSort = LocalStorage.get('basicFilter.sort');
    const basicFilterOrder = LocalStorage.get('basicFilter.order');
    if (basicFilterStatus) this.activeStatus = basicFilterStatus;
    if (basicFilterSort) this.activeSortBy = basicFilterSort;
    if (basicFilterOrder) this.activeOrder = basicFilterOrder;
    const hasFilterValues = localStorage.getItem('advancedConversationFilters');
    this.selectedFilters = hasFilterValues ? JSON.parse(hasFilterValues) : [];
    if (hasFilterValues) {
      this.isFiltered = true;
    }
    this.activeAssigneeTab =
      LocalStorage.get('activeAssigneeTab') || wootConstants.ASSIGNEE_TYPE.ME;

    this.$store.dispatch('setChatStatusFilter', this.activeStatus);
    this.$store.dispatch('setChatSortFilter', this.activeSortBy);
    this.$store.dispatch('setChatOrderFilter', this.activeOrder);
    this.resetAndFetchData();
  },
  methods: {
    evaluateLoadMoreCondition() {
      const scrollWrapperHeight = this.$refs.scroller.$refs?.wrapper
        ?.clientHeight;
      const conversationListHeight = this.$refs.activeConversation
        ?.offsetHeight;
      this.hasMoreItems = scrollWrapperHeight >= conversationListHeight;
    },
    onApplyFilter(payload) {
      this.resetBulkActions();
      this.foldersQuery = filterQueryGenerator(payload);
      this.selectedFilters = payload;
      this.$store.dispatch('conversationPage/reset');
      this.$store.dispatch('emptyAllConversations');
      this.fetchFilteredConversations(payload);
      this.fetchConversationsMeta();

      LocalStorage.set('advancedConversationFilters', payload);
    },
    updateCounts() {
      const ASSIGNEE_TYPE_TAB_KEYS = {
        me: 'mineCount',
        unassigned: 'unAssignedCount',
        all: 'allCount',
      };
      return Object.keys(ASSIGNEE_TYPE_TAB_KEYS).map(key => {
        const count = this.conversationStats[ASSIGNEE_TYPE_TAB_KEYS[key]];
        if (key === 'all') {
          this.allCount = count;
        }
        return {
          key,
          name: this.$t(`CHAT_LIST.ASSIGNEE_TYPE_TABS.${key}`),
          count,
        };
      });
    },
    onScroll() {
      const el = this.$refs.scroller.$el;
      const parent = this.$refs.activeConversation;
      this.scrollEnd =
        parent.offsetHeight + el.scrollTop >= el.scrollHeight - 10;
    },
    onClickOpenAddFoldersModal() {
      this.showAddFoldersModal = true;
    },
    onCloseAddFoldersModal() {
      this.showAddFoldersModal = false;
    },
    onClickOpenDeleteFoldersModal() {
      this.showDeleteFoldersModal = true;
    },
    onCloseDeleteFoldersModal() {
      this.showDeleteFoldersModal = false;
    },
    onToggleAdvanceFiltersModal() {
      if (!this.hasAppliedFilters) {
        this.initializeExistingFilterToModal();
      }
      this.showAdvancedFilters = true;
    },
    closeAdvanceFiltersModal() {
      this.showAdvancedFilters = false;
      this.appliedFilter = [];
    },
    getKeyboardListenerParams() {
      const allConversations = this.$refs.activeConversation.querySelectorAll(
        'div.conversations-list div.conversation'
      );
      const activeConversation = this.$refs.activeConversation.querySelector(
        'div.conversations-list div.conversation.active'
      );
      const activeConversationIndex = [...allConversations].indexOf(
        activeConversation
      );
      const lastConversationIndex = allConversations.length - 1;
      return {
        allConversations,
        activeConversation,
        activeConversationIndex,
        lastConversationIndex,
      };
    },
    handleKeyEvents(e) {
      if (hasPressedAltAndJKey(e)) {
        const {
          allConversations,
          activeConversationIndex,
        } = this.getKeyboardListenerParams();
        if (activeConversationIndex === -1) {
          allConversations[0].click();
        }
        if (activeConversationIndex >= 1) {
          allConversations[activeConversationIndex - 1].click();
        }
      }
      if (hasPressedAltAndKKey(e)) {
        const {
          allConversations,
          activeConversationIndex,
          lastConversationIndex,
        } = this.getKeyboardListenerParams();
        if (activeConversationIndex === -1) {
          allConversations[lastConversationIndex].click();
        } else if (activeConversationIndex < lastConversationIndex) {
          allConversations[activeConversationIndex + 1].click();
        }
      }
    },
    clearAdvancedFilters() {
      LocalStorage.remove('advancedConversationFilters');
      this.appliedFilter = [];
      this.isFiltered = false;
      this.resetAndFetchData();
    },
    resetAndFetchData() {
      this.resetBulkActions();
      this.$store.dispatch('conversationPage/reset');
      this.$store.dispatch('emptyAllConversations');
      this.$store.dispatch('clearConversationFilters');
      this.$store.dispatch('clearConversationFilters');
      this.$store.dispatch('conversationStats/set', {
        mine_count: null,
        unassigned_count: null,
        all_count: null,
      });

      if (this.hasActiveFolders) {
        const payload = this.activeFolder.query;
        this.fetchSavedFilteredConversations(payload);
      }
      if (this.foldersId) {
        return;
      }

      /* restore last state */
      const advancedConversationFilters = LocalStorage.get(
        'advancedConversationFilters'
      );
      if (advancedConversationFilters) {
        this.$store.dispatch(
          'setConversationFilters',
          advancedConversationFilters
        );
        this.fetchFilteredConversations(advancedConversationFilters);
      } else {
        this.fetchConversations();
        this.appliedFilter = [];
      }
      this.fetchConversationsMeta();
    },
    conversationLoad() {
      this.onScroll();
      this.$emit('conversation-load');
    },
    fetchConversations() {
      this.$store
        .dispatch('fetchAllConversations', this.conversationFilters)
        .then(() => this.conversationLoad());
    },
    getActiveTab() {
      switch (this.$route.name) {
        case 'conversation_mentions':
        case 'conversation_through_mentions':
          return 'mention';
        case 'conversation_unattended':
        case 'conversation_through_unattended':
          return 'unattended';
        case 'conversation_archived':
        case 'conversation_through_archived':
          return 'archived';
        default:
          return '';
      }
    },
    async fetchConversationsMeta() {
      const conversationTab = this.getActiveTab();
      if (conversationTab !== this.oldTab || !this.isRequestActive) {
        this.initAbortController();
        const filterData = this.appliedFilters.length
          ? filterQueryGenerator(this.appliedFilters)
          : null;
        this.isRequestActive = true;
        try {
          await this.$store.dispatch('conversationStats/get', {
            params: this.conversationFilters,
            appliedFilters: filterData,
            archived: this.conversationType === 'archived',
            conversationTab,
            signal: this.abortController.signal,
          });
        } finally {
          this.isRequestActive = false;
          this.abortController = null;
        }
      }
    },
    initAbortController() {
      if (this.abortController) this.abortController.abort();
      this.abortController = new AbortController();
    },
    loadMoreConversations() {
      if (!this.hasAppliedFiltersOrActiveFolders) {
        this.fetchConversations();
      }
      if (this.hasActiveFolders) {
        const payload = this.activeFolder.query;
        this.fetchSavedFilteredConversations(payload);
      }
      if (this.hasAppliedFilters) {
        this.fetchFilteredConversations(this.appliedFilters);
      }
    },
    fetchFilteredConversations(payload) {
      let page = this.currentFiltersPage + 1;
      this.$store
        .dispatch('fetchFilteredConversations', {
          queryData: filterQueryGenerator(payload),
          page,
          archived: this.conversationType === 'archived',
          conversationType: this.conversationType,
        })
        .then(() => this.conversationLoad());
      this.showAdvancedFilters = false;
    },
    fetchSavedFilteredConversations(payload) {
      let page = this.currentFiltersPage + 1;
      this.$store
        .dispatch('fetchFilteredConversations', {
          queryData: payload,
          page,
          archived: this.conversationType === 'archived',
        })
        .then(() => this.conversationLoad());
      this.fetchConversationsMeta();
    },
    updateAssigneeTab(selectedTab) {
      if (this.activeAssigneeTab !== selectedTab) {
        LocalStorage.set('activeAssigneeTab', selectedTab);
        this.resetBulkActions();
        bus.$emit('clearSearchInput');
        this.activeAssigneeTab = selectedTab;
        if (!this.currentPage) {
          this.fetchConversations();
          this.fetchConversationsMeta();
        }
      }
    },
    resetBulkActions() {
      this.selectedConversations = [];
      this.selectedInboxes = [];
    },
    onBasicFilterChange(value, type) {
      if (type === 'status') {
        this.activeStatus = value;
      } else if (type === 'sort') {
        this.activeSortBy = value;
      } else {
        this.activeOrder = value;
      }
      LocalStorage.set(`basicFilter.${type}`, value);

      this.resetAndFetchData();
    },
    openLastSavedItemInFolder() {
      const lastItemOfFolder = this.folders[this.folders.length - 1];
      const lastItemId = lastItemOfFolder.id;
      this.$router.push({
        name: 'folder_conversations',
        params: { id: lastItemId },
      });
    },
    openLastItemAfterDeleteInFolder() {
      if (this.folders.length > 0) {
        this.openLastSavedItemInFolder();
      } else {
        this.$router.push({ name: 'home' });
        this.fetchConversations();
        this.fetchConversationsMeta();
      }
    },
    isConversationSelected(id) {
      return this.selectedConversations.includes(id);
    },
    selectConversation(conversationId, inboxId) {
      this.selectedConversations.push(conversationId);
      this.selectedInboxes.push(inboxId);
    },
    deSelectConversation(conversationId, inboxId) {
      this.selectedConversations = this.selectedConversations.filter(
        item => item !== conversationId
      );
      this.selectedInboxes = this.selectedInboxes.filter(
        item => item !== inboxId
      );
    },
    selectAllConversations(check) {
      if (check) {
        this.selectedConversations = this.conversationList.map(item => item.id);
        this.selectedInboxes = this.conversationList.map(item => item.inbox_id);
      } else {
        this.resetBulkActions();
      }
    },
    // Same method used in context menu, conversationId being passed from there.
    async onAssignAgent(agent, conversationId = null) {
      this.closeContextMenu();
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: conversationId || this.selectedConversations,
          fields: {
            assignee_id: agent.id,
          },
        });
        this.selectedConversations = [];
        if (conversationId) {
          this.showAlert(
            this.$t(
              'CONVERSATION.CARD_CONTEXT_MENU.API.AGENT_ASSIGNMENT.SUCCESFUL',
              {
                agentName: agent.name,
                conversationId,
              }
            )
          );
        } else {
          this.showAlert(this.$t('BULK_ACTION.ASSIGN_SUCCESFUL'));
        }
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.ASSIGN_FAILED'));
      }
    },
    async assignPriority(priority, conversationId = null) {
      this.closeContextMenu();
      this.$store.dispatch('setCurrentChatPriority', {
        priority,
        conversationId,
      });
      this.$store
        .dispatch('assignPriority', { conversationId, priority })
        .then(() => {
          this.$track(CONVERSATION_EVENTS.CHANGE_PRIORITY, {
            newValue: priority,
            from: 'Context menu',
          });
          this.showAlert(
            this.$t('CONVERSATION.PRIORITY.CHANGE_PRIORITY.SUCCESSFUL', {
              priority:
                priority || this.$t('CONVERSATION.PRIORITY.OPTIONS.NONE'),
              conversationId,
            })
          );
        });
    },
    async markAsUnread(conversationId) {
      this.closeContextMenu();
      try {
        await this.$store.dispatch('markMessagesUnread', {
          id: conversationId,
        });
        const {
          params: { accountId, inbox_id: inboxId, label, teamId },
          name,
        } = this.$route;
        let conversationType = '';
        if (isOnMentionsView({ route: { name } })) {
          conversationType = 'mention';
        } else if (isOnUnattendedView({ route: { name } })) {
          conversationType = 'unattended';
        }
        this.$router.push(
          conversationListPageURL({
            accountId,
            conversationType: conversationType,
            customViewId: this.foldersId,
            inboxId,
            label,
            teamId,
          })
        );
      } catch (error) {
        // Ignore error
      }
    },
    async onAssignTeam(team, conversationId = null) {
      this.closeContextMenu();
      try {
        await this.$store.dispatch('assignTeam', {
          conversationId,
          teamId: team.id,
        });
        this.showAlert(
          this.$t(
            'CONVERSATION.CARD_CONTEXT_MENU.API.TEAM_ASSIGNMENT.SUCCESFUL',
            {
              team: team.name,
              conversationId,
            }
          )
        );
      } catch (error) {
        this.showAlert(
          this.$t('CONVERSATION.CARD_CONTEXT_MENU.API.TEAM_ASSIGNMENT.FAILED')
        );
      }
    },
    // Same method used in context menu, conversationId being passed from there.
    async onAssignLabels(labels, conversationId = null) {
      this.closeContextMenu();
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: conversationId || this.selectedConversations,
          labels: {
            add: labels,
          },
        });
        this.selectedConversations = [];
        if (conversationId) {
          this.showAlert(
            this.$t(
              'CONVERSATION.CARD_CONTEXT_MENU.API.LABEL_ASSIGNMENT.SUCCESFUL',
              {
                labelName: labels[0],
                conversationId,
              }
            )
          );
        } else {
          this.showAlert(this.$t('BULK_ACTION.LABELS.ASSIGN_SUCCESFUL'));
        }
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.LABELS.ASSIGN_FAILED'));
      }
    },
    async onAssignTeamsForBulk(team) {
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: this.selectedConversations,
          fields: {
            team_id: team.id,
          },
        });
        this.selectedConversations = [];
        this.showAlert(this.$t('BULK_ACTION.TEAMS.ASSIGN_SUCCESFUL'));
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.TEAMS.ASSIGN_FAILED'));
      }
    },
    async onUpdateConversations(status) {
      try {
        await this.$store.dispatch('bulkActions/process', {
          type: 'Conversation',
          ids: this.selectedConversations,
          fields: {
            status,
          },
        });
        this.selectedConversations = [];
        this.showAlert(this.$t('BULK_ACTION.UPDATE.UPDATE_SUCCESFUL'));
      } catch (err) {
        this.showAlert(this.$t('BULK_ACTION.UPDATE.UPDATE_FAILED'));
      }
    },
    toggleConversationStatus(conversationId, status, snoozedUntil) {
      this.closeContextMenu();
      this.$store
        .dispatch('toggleStatus', {
          conversationId,
          status,
          snoozedUntil,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'));
          this.isLoading = false;
        });
    },
    allSelectedConversationsStatus(status) {
      if (!this.selectedConversations.length) return false;
      return this.selectedConversations.every(item => {
        return this.$store.getters.getConversationById(item).status === status;
      });
    },

    openContextMenu(e) {
      this.contextMenu.x = e.pageX || e.clientX;
      this.contextMenu.y = e.pageY || e.clientY;
      if (this.isRTLView) {
        this.contextMenu.x = (e.pageX || e.clientX) - 180;
      }
      this.showContextMenu = true;
    },

    closeContextMenu() {
      this.showContextMenu = false;
      this.contextMenu.x = null;
      this.contextMenu.y = null;
    },

    onContextMenuToggle(state, e = null) {
      this.isContextMenuOpen = state;
      if (state) {
        this.openContextMenu(e);
      } else {
        this.closeContextMenu();
      }
    },

    setContextMenuData(data) {
      this.contextMenuData = data;
    },

    archive(conversationId) {
      this.$store
        .dispatch('archive', {
          conversationId,
        })
        .then(archived => {
          if (archived) {
            this.showAlert(this.$t('CONVERSATION.ARCHIVED'));
          } else {
            this.showAlert(this.$t('CONVERSATION.ARCHIVE_FAILED'));
          }
        });
      this.closeContextMenu();
    },
    unArchive(conversationId) {
      this.$store
        .dispatch('unArchive', {
          conversationId,
        })
        .then(archived => {
          if (archived) {
            this.showAlert(this.$t('CONVERSATION.ARCHIVED'));
          } else {
            this.showAlert(this.$t('CONVERSATION.ARCHIVE_FAILED'));
          }
        });
      this.closeContextMenu();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/woot';

.wrapper {
  position: relative;
}

.spinner {
  margin-top: var(--space-smaller);
  margin-bottom: var(--space-smaller);
}

.conversations-list {
  scrollbar-gutter: stable both-edges;
  // Prevent the list from scrolling if the submenu is opened
  &.is-context-menu-open {
    overflow: hidden !important;
  }
}

.conversations-list-wrap {
  flex-shrink: 0;
  flex-basis: clamp(32rem, 4vw + 34rem, 44rem);
  overflow: hidden;

  &.hide {
    display: none;
  }

  &.list--full-width {
    flex-basis: 100%;
  }

  .page-sub-title {
    font-size: var(--font-size-two);
  }
}
.filter--actions {
  display: flex;
  align-items: center;
  gap: var(--space-smaller);
}

.filter__applied {
  padding-bottom: var(--space-slab) !important;
  border-bottom: 1px solid var(--color-border);
}

.conversation--status-pill {
  background: var(--color-background);
  border-radius: var(--border-radius-small);
  color: var(--color-medium-gray);
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-medium);
  margin: var(--space-micro) var(--space-small) 0;
  padding: var(--space-smaller);
  text-transform: capitalize;
}

.chat-list__title {
  max-width: 85%;
}

.recycleview {
  height: 100%;

  &.scroll-end {
    height: calc(100% - 41px);
  }
  ::v-deep .vue-recycle-scroller__item-view {
    background-color: white;

    &:hover {
      z-index: 1;
    }
  }
}

.scroll-end {
  height: 40px;
  border-top: 1px solid var(--s-75);
  ::v-deep .button {
    border-radius: 0px;
  }
}
</style>
