import InboxSlugAPI from '../../api/inboxSlug';

export const actions = {
  checkAvailability(_, args) {
    return InboxSlugAPI.checkAvailability(args);
  },
};

export default {
  namespaced: true,
  actions,
};
