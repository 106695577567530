import Vue from 'vue';
import types from '../mutation-types';
import ConversationApi from '../../api/inbox/conversation';

const state = {
  mineCount: null,
  unAssignedCount: null,
  allCount: null,
  oldTab: null,
};

export const getters = {
  getStats: $state => $state,
  getOldTab: $state => $state.oldTab,
};

export const actions = {
  get: async (
    { commit },
    { params, appliedFilters, archived, conversationTab, signal }
  ) => {
    commit(types.SET_CONV_OLD_TAB, conversationTab);
    const response = await ConversationApi.meta(
      params,
      appliedFilters,
      archived,
      conversationTab,
      signal
    );

    if (signal.aborted) return;

    const {
      data: { meta },
    } = response;
    commit(types.SET_CONV_TAB_META, meta);
  },
  set({ commit }, meta) {
    commit(types.SET_CONV_TAB_META, meta);
  },
};

export const mutations = {
  [types.SET_CONV_OLD_TAB]($state, tab) {
    $state.oldTab = tab;
  },
  [types.SET_CONV_TAB_META](
    $state,
    {
      mine_count: mineCount,
      unassigned_count: unAssignedCount,
      all_count: allCount,
    } = {}
  ) {
    Vue.set($state, 'mineCount', mineCount);
    Vue.set($state, 'allCount', allCount);
    Vue.set($state, 'unAssignedCount', unAssignedCount);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
