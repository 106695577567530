<template>
  <div
    class="wizard-body call-based-body columns"
    :class="{ 'small-9': inboxActionMode !== 'edit_call' }"
  >
    <main>
      <page-header
        class="channel-type-header"
        :header-title="$t('INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.TITLE')"
        :header-content="
          $t('INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.DESCRIPTION')
        "
      />
      <div :class="{ 'filters-wrapper': showAdvanced }">
        <section class="business-number-filters">
          <div class="container">
            <div class="row">
              <div class="medium-8 padding-right-1">
                <label class="business-number-filter-label">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.COUNTRY.LABEL'
                    )
                  }}
                </label>

                <multiselect
                  v-model="countryCode"
                  track-by="id"
                  label="name"
                  :placeholder="
                    $t(
                      'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.COUNTRY.PLACEHOLDER'
                    )
                  "
                  selected-label
                  :select-label="
                    $t('CONTACT_FORM.FORM.COUNTRY.SELECT_PLACEHOLDER')
                  "
                  :deselect-label="$t('CONTACT_FORM.FORM.COUNTRY.REMOVE')"
                  :custom-label="countryNameWithCode"
                  :max-height="160"
                  :options="countries"
                  :allow-empty="true"
                  :option-height="104"
                />
              </div>

              <div class="medium-4 padding-right-1">
                <label class="business-number-filter-label">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.CAPABILITIES.LABEL'
                    )
                  }}
                  <div class="coming-soon-sms">
                    ({{
                      $t(
                        'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.CAPABILITIES.SMS_COMING_SOON'
                      )
                    }})
                  </div>
                </label>
                <div class="business-number-multiple-checkbox">
                  <div class="checkbox-wrapper">
                    <input
                      id="voice"
                      v-model="voiceEnabled"
                      type="checkbox"
                      value="voice"
                    />
                    <label for="voice">
                      {{
                        $t(
                          'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.CAPABILITIES.VOICE'
                        )
                      }}
                    </label>
                  </div>
                  <div class="checkbox-wrapper">
                    <input
                      id="sms"
                      v-model="smsEnabled"
                      type="checkbox"
                      value="sms"
                    />
                    <label for="sms">
                      {{
                        $t(
                          'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.CAPABILITIES.SMS'
                        )
                      }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="medium-4 padding-right-1">
                <label class="business-number-filter-label">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.SEARCH_CRITERIA.LABEL'
                    )
                  }}
                  <select v-model="searchCriteria" class="default-input-height">
                    <option value="Number">
                      {{
                        $t(
                          'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.SEARCH_CRITERIA.NUMBER'
                        )
                      }}
                    </option>
                    <option value="Locality">
                      {{
                        $t(
                          'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.SEARCH_CRITERIA.LOCALITY'
                        )
                      }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="no-label medium-4 padding-right-1">
                <input
                  v-model.trim="searchText"
                  type="text"
                  class="mb-0 default-input-height"
                  placeholder="Search by digits or phrases"
                />
              </div>
              <div class="no-label medium-2 padding-right-1">
                <woot-button
                  class="w-full default-input-height"
                  size="medium"
                  @click="searchNumbers()"
                >
                  {{
                    $t(
                      'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.SERACH_BTN'
                    )
                  }}
                </woot-button>
              </div>
              <div class="no-label medium-2">
                <woot-button
                  variant="clear"
                  color-scheme="secondary"
                  size="medium"
                  class="w-full default-input-height"
                  @click="resetFilters"
                >
                  {{
                    $t(
                      'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.RESET_BTN'
                    )
                  }}
                </woot-button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <button class="advansed-search" @click="showAdvanced = !showAdvanced">
            <span>{{
              $t(
                'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.ADVANCED_SEARCH'
              )
            }}</span>
            <fluent-icon :icon="showAdvanced ? 'chevron-up' : 'chevron-down'" />
          </button>
          <transition name="fade">
            <div v-if="showAdvanced" class="advansed-search-body">
              <div class="padding-bottom-3">
                <label class="business-number-filter-label">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.NUMBER_TYPE.LABEL'
                    )
                  }}
                </label>
                <div class="business-number-multiple-checkbox">
                  <div class="checkbox-wrapper">
                    <input
                      id="local"
                      v-model="local"
                      type="checkbox"
                      value="local"
                    />
                    <label for="local">
                      {{
                        $t(
                          'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.NUMBER_TYPE.LOCAL'
                        )
                      }}
                    </label>
                  </div>
                  <div class="checkbox-wrapper">
                    <input
                      id="mobile"
                      v-model="mobile"
                      type="checkbox"
                      value="mobile"
                    />
                    <label for="mobile">
                      {{
                        $t(
                          'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.NUMBER_TYPE.MOBILE'
                        )
                      }}
                    </label>
                  </div>
                  <div class="checkbox-wrapper">
                    <input
                      id="tollfree"
                      v-model="tollfree"
                      type="checkbox"
                      value="tollfree"
                    />
                    <label for="tollfree">
                      {{
                        $t(
                          'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.NUMBER_TYPE.TALL_FREE'
                        )
                      }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="medium-5 padding-right-1">
                  <label class="business-number-filter-label">
                    {{
                      $t(
                        'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.NUMBER_TYPE.BETA'
                      )
                    }}
                  </label>
                  <div class="business-number-multiple-checkbox">
                    <div class="checkbox-wrapper">
                      <input
                        id="beta"
                        v-model="beta"
                        type="checkbox"
                        value="beta"
                      />
                      <label for="beta">
                        {{
                          $t(
                            'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.NUMBER_TYPE.BETA_LABEL'
                          )
                        }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="medium-7 padding-right-1">
                  <label class="business-number-filter-label">
                    {{
                      $t(
                        'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.NUMBER_TYPE.EMEGRENCY'
                      )
                    }}
                  </label>
                  <div class="business-number-multiple-checkbox">
                    <div class="checkbox-wrapper">
                      <input
                        id="emergency"
                        v-model="emergency"
                        type="checkbox"
                        value="emergency"
                      />
                      <label for="emergency">
                        {{
                          $t(
                            'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.NUMBER_TYPE.EMEGRENCY_LABEL'
                          )
                        }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </section>
      </div>
      <section class="padding-top-2">
        <div v-if="isLoading" class="call-based-spinner">
          <spinner-hoory size="normal" />
        </div>
        <ve-table
          v-else-if="!isLoading && numbers.length"
          :fixed-header="true"
          max-height="calc(100vh - 17.4rem)"
          scroll-width="60rem"
          :columns="columns"
          :table-data="numbers"
          :border-around="false"
        />
        <empty-state v-else :title="emptyMessage" />
      </section>
    </main>
    <woot-modal :show.sync="showCallBasedPopup" :on-close="hideCallBasedPopup">
      <BusinessNumberPurchase :on-close="hideCallBasedPopup" />
    </woot-modal>
  </div>
</template>

<script>
import inboxMixin from 'shared/mixins/inboxMixin';
import { VeTable } from 'vue-easytable';
import BusinessNumberPurchase from './BusinessNumberPurchase';
import PageHeader from '../../../SettingsSubPageHeader';
import countries from 'shared/constants/countries.js';
import accountMixin from 'dashboard/mixins/account';
import billingMixin from 'dashboard/mixins/billing';
import alertMixin from 'shared/mixins/alertMixin';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import SpinnerHoory from 'shared/components/SpinnerHoory.vue';

export default {
  components: {
    PageHeader,
    VeTable,
    BusinessNumberPurchase,
    EmptyState,
    SpinnerHoory,
  },
  mixins: [inboxMixin, accountMixin, billingMixin, alertMixin],
  data() {
    return {
      countryCode: {
        name: 'United States',
        dial_code: '+1',
        emoji: '🇺🇸',
        id: 'US',
      },
      countries: countries,
      searchCriteria: 'Number',
      voiceEnabled: true,
      smsEnabled: true,
      searchText: '',
      showAdvanced: false,
      tollfree: false,
      mobile: false,
      local: true,
      beta: false,
      emergency: false,
      numbers: [],
      pageSize: 20,
      page: 0,
      emptyMessage:
        "We couldn't find any numbers that matched your search criteria.",
      isLoading: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          field: 'phone_number',
          key: 'phone_number',
          title: this.$t(
            'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.COLUMNS.NUMBER'
          ),
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div>
              <p class="fw-medium"> {row.phone_number} </p>
              <p>
                {' '}
                {this.countryCode.name}, {row.locality}{' '}
              </p>
            </div>
          ),
        },
        {
          field: 'type',
          key: 'type',
          title: this.$t(
            'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.COLUMNS.TYPE'
          ),
          align: 'left',
        },
        {
          field: 'capabilities',
          key: 'capabilities',
          title: this.$t(
            'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.COLUMNS.CAPABILITIES'
          ),
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div>
              {[
                row.capabilities.voice ? 'Voice' : '',
                row.capabilities.SMS ? 'SMS' : '',
              ]
                .filter(Boolean)
                .join(', ')}
            </div>
          ),
        },
        {
          field: 'fee',
          key: 'fee',
          title: this.$t(
            'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.COLUMNS.MONTHLY_FEE'
          ),
          align: 'left',
          renderBodyCell: ({ row }) => (
            <span>{this.formattedPrice(row.fee * 100)}</span>
          ),
        },
        {
          field: 'actions',
          key: 'actions',
          title: '',
          align: 'right',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div>
              <woot-button
                variant="smooth"
                color-scheme="secondary"
                size="medium"
                class="w-full"
                onClick={() => this.selectNumber(row)}
              >
                {this.$t(
                  'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.COLUMNS.SELECT_NUMBER'
                )}
              </woot-button>
            </div>
          ),
        },
      ];
    },
  },
  watch: {
    billingInfo(val) {
      if (!val) return;
      if (!this.hasDefaultPaymentMethod) {
        this.showCallBasedPopup = true;
      }
    },
  },
  async mounted() {
    await this.searchNumbers();
  },
  async created() {
    this.setNewInboxType('call');

    if (this.$route.query.redirected) {
      this.setNewInboxType('call');
    }
    if (this.newInboxType !== 'call') {
      this.goToGivenStep('settings_inbox_new');
    }
    if (this.isBillingDataLoaded && !this.hasDefaultPaymentMethod) {
      this.showCallBasedPopup = true;
    }
  },
  beforeDestroy() {
    this.setInboxActionMode(null);
  },
  methods: {
    selectNumber(number) {
      this.fetchCountryBasePricing({
        accountId: this.accountId,
        countryCode: number.iso_country,
      });
      this.setSelectedNumber(number);
      this.openCallBasedPopup();
    },
    countryNameWithCode({ name, id }) {
      if (!id) return name;
      if (!name && !id) return '';
      return `${name} (${id})`;
    },
    getCurrentPrice(prices, key) {
      let returnValue;
      prices.forEach(price => {
        if (price.numberType === key) {
          returnValue = price.currentPrice;
        }
      });
      return returnValue;
    },
    isNumber(value) {
      return typeof value === 'number' || !Number.isNaN(Number(value));
    },
    resetFilters() {
      this.searchCriteria = 'Number';
      this.voiceEnabled = true;
      this.smsEnabled = true;
      this.searchText = '';
      this.showAdvanced = false;
      this.tollfree = false;
      this.mobile = false;
      this.local = true;
      this.beta = false;
      this.emergency = false;
      this.countryCode = null;
      this.numbers = [];
    },
    async searchNumbers(reset = true) {
      if (!this.countryCode) {
        return this.showAlert(
          this.$t(
            'INBOX_MGMT.ADD.CALL_CHANNEL.BUSINESS_NUMBER.FORM.COUNTRY.REQUIRED'
          )
        );
      }

      if (reset) {
        this.numbers = [];
        this.page = 0;
      }

      let searchKey = '';
      if (this.searchCriteria === 'Number') {
        searchKey = 'contains';
      } else if (this.isNumber(this.searchText)) {
        searchKey = 'areaCode';
      } else {
        searchKey = 'inRegion';
      }

      const data = {
        account_id: this.accountId,
        countryCode: this.countryCode.id,
        page: this.page,
        pageSize: this.pageSize,
        ...(this.beta && { beta: false }),
        ...(this.emergency && { emergency: true }),
        ...(this.smsEnabled && { smsEnabled: true }),
        ...(this.voiceEnabled && { voiceEnabled: true }),
        ...(this.searchText && { [searchKey]: this.searchText }),
      };

      let numberTypes = [
        this.local && 'Local',
        this.mobile && 'Mobile',
        this.tollfree && 'TollFree',
      ].filter(Boolean);
      numberTypes = numberTypes.length ? numberTypes : ['Local'];

      this.isLoading = true;
      try {
        const results = await Promise.all(
          numberTypes.map(async type => {
            data.numberType = type;
            const resData = await this.$store.dispatch(
              'billing/searchNumbers',
              data
            );
            if (resData?.searchResult?.available_phone_numbers) {
              const currentPrice = this.getCurrentPrice(
                resData.numberPrices.prices,
                type
              );
              return resData.searchResult.available_phone_numbers.map(item => ({
                ...item,
                type,
                fee: currentPrice,
              }));
            }
            return [];
          })
        );
        this.numbers = results.flat();
      } catch (error) {
        this.showAlert(`Error: ${error?.message}`);
      } finally {
        this.isLoading = false;
      }

      return this.numbers;
    },
  },
};
</script>
<style scoped lang="scss">
.business-number-filters {
  margin-top: var(--space-large);
}
label.business-number-filter-label {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-default);
}
.business-number-multiple-checkbox {
  display: flex;
  margin-top: var(--space-small);
}
.no-label {
  margin-top: 27px;
}
.filters-wrapper {
  box-shadow: var(--shadow-medium);
  border-radius: var(--border-radius-large);
  background-color: var(--white);
  padding: 0 var(--space-snug) var(--space-snug);
}
.advansed-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--s-100);
  width: 100%;
  text-align: left;
  font-size: var(--font-size-default);
  padding: var(--space-normal) 0;
  cursor: pointer;
  background: transparent;
  transition: all 500ms ease;
}
.advansed-search:hover {
  border: none;
  border-radius: var(--border-radius-medium);
  background: var(--s-50);
  padding: var(--space-normal);
}
.advansed-search-body {
  padding-top: var(--space-medium);
}
.checkbox-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    height: 16px;
    width: 16px;
    margin: 0;
  }
}
.coming-soon-sms {
  font-size: var(--font-size-micro);
  margin-top: var(--space-minus-small);
}
</style>
