/* global axios */

import ApiClient from './ApiClient';

class CannedResponse extends ApiClient {
  constructor() {
    super('canned_responses', { accountScoped: true });
  }

  get({ searchKey, page }) {
    let url = page
      ? `${this.url}?page=${page}`
      : `${this.url}?search=${searchKey}`;
    return axios.get(url);
  }

  // eslint-disable-next-line class-methods-use-this
  getBySearch({ searchKey, page }) {
    return axios.get(`${this.url}?page=${page}&search=${searchKey}`);
  }
}

export default new CannedResponse();
