import { LocalStorage } from 'shared/helpers/localStorage';

export const ACTIONS = {
  INBOX_CREATED: 'inbox-created',
  WEB_INBOX_CREATED: 'web-inbox-created',
  WHATSAPP_INBOX_CREATED: 'whatsapp-inbox-created',
  API_INBOX_CREATED: 'api-inbox-created',
  EMAIL_INBOX_CREATED: 'email-inbox-created',
  SMS_INBOX_CREATED: 'sms-inbox-created',
  TELEGRAM_INBOX_CREATED: 'telegram-inbox-created',
  FACEBOOK_INBOX_CREATED: 'facebook-inbox-created',
  LINE_INBOX_CREATED: 'line-inbox-created',
  TWILIO_INBOX_CREATED: 'twilio-inbox-created',
  TWITTER_INBOX_CREATED: 'twitter-inbox-created',
  INBOX_AGENTS_UPDATED: 'inbox-agents-updated',
  INBOX_AGENTS_ADDED: 'inbox-agents-added',
  INBOX_AGENTS_DELETED: 'inbox-agents-deleted',
  INBOX_PRECHAT_FORM_ENABLED: 'inbox-prechat-form-enabled',
  INBOX_BUSINESS_HOURS_ENABLED: 'inbox-business-hours-enabled',
  INBOX_USER_IDENTITY_VALIDATION_COPIED:
    'inbox-user-identity-validation-copied',
  INBOX_SCRIPT_CODE_COPIED: 'inbox-script-code-copied',
  AUTOMATION_RULE_CREATED: 'automation-rule-created',
  PORTAL_CREATED: 'portal-created',
  COMPAIGN_CREATED: 'compaign-created',
  MACRO_CREATED: 'macro-created',
  LABEL_CREATED: 'label-created',
  CUSTOM_ATTRIBUTE_CREATED: 'custom-attribute-created',
  AGENT_INVITED: 'agent-invited',
  AGENT_DELETED: 'agent-deleted',
  AGENTBOT_CREATED: 'agentbot-created',
  RULE_BASED_AGENTBOT_CREATED: 'rule-based-agentbot-created',
  KNOWLEDGE_BASED_AGENTBOT_CREATED: 'knowledege-based-agentbot-created',
  AGENTBOT_ASSIGNED_TO_INBOX: 'agentbot-assigned-to-inbox',
  RULE_BASED_AGENTBOT_ASSIGNED_TO_INBOX:
    'rule-based-agentbot-assigned-to-inbox',
  KNOWLEDGE_BASED_AGENTBOT_ASSIGNED_TO_INBOX:
    'knowledege-based-agentbot-assigned-to-inbox',
};

export default {
  methods: {
    getChecklistAPI() {
      return window.productFruits?.api?.checklists;
    },
    async getActiveChecklists() {
      if (!window.productFruits?.api) return [];

      const api = this.getChecklistAPI();
      const cls = await api.getChecklists();
      const availableChecklists = (
        await Promise.all(
          cls
            .filter(cl => cl.status !== 'finished')
            .map(async chlist => {
              const status = await api.isChecklistEligible(chlist.id);
              return { checklist: chlist, isEligible: status === 'eligible' };
            })
        )
      ).filter(cl => cl.isEligible);
      return availableChecklists.map(c => c.checklist);
    },
    sendProductFruitsEvent(events) {
      if (!window.productFruits?.api) return;

      const eventsList = Array.isArray(events) ? events : [events];
      // eslint-disable-next-line no-restricted-syntax
      for (const event of eventsList) {
        if (typeof event === 'string') {
          window.productFruits.api.events.track(event, {});
          LocalStorage.set('pf_lastEvent', { id: event });
        } else if (typeof event === 'object') {
          window.productFruits.api.events.track(event.id, event.data);
          LocalStorage.set('pf_lastEvent', event);

          if (event.id === ACTIONS.INBOX_CREATED) {
            LocalStorage.set('pf_lastInboxCreatedEvent', event);
          }
        }
      }
    },
  },
};
