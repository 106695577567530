<template>
  <div
    class="avatar-container"
    :class="getColorCode"
    :style="style"
    aria-hidden="true"
  >
    {{ userInitial }}
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    username: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    style() {
      return {
        fontSize: `${Math.floor(this.size / 2.2)}px`,
      };
    },
    userInitial() {
      const parts = this.username.split(/[ -]/);
      let initials = parts.reduce((acc, curr) => acc + curr.charAt(0), '');

      if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '');
      }
      initials = initials.substring(0, 2).toUpperCase();

      return initials;
    },
    getColorCode() {
      const charCode = this.username.toUpperCase().charCodeAt(0);

      if (charCode % 4 === 0) {
        return '_red';
      }
      if (charCode % 4 === 1) {
        return '_blue';
      }
      if (charCode % 4 === 2) {
        return '_green';
      }
      if (charCode % 4 === 3) {
        return '_purple';
      }
      return '_sky';
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-container {
  display: flex;
  line-height: 100%;
  font-weight: var(--font-weight-black);
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--v-50);
  &._red {
    background: var(--r-300);
  }
  &._blue {
    background: var(--w-500);
  }
  &._green {
    background: var(--g-300);
  }
  &._purple {
    background: var(--v-300);
  }
  &._sky {
    background: var(--s-400);
  }
}
</style>
