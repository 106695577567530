/* global axios */
import ApiClient from './ApiClient';

class InboxSlug extends ApiClient {
  constructor() {
    super('inbox_slug', { accountScoped: true });
  }

  checkAvailability({ slug, channel_id }) {
    return axios.get(`${this.url}?slug=${slug}&channel_id=${channel_id}`);
  }
}

export default new InboxSlug();
