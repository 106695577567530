<template>
  <div class="columns profile--settings">
    <form v-if="!uiFlags.isFetchingItem" @submit.prevent="updateAccount">
      <div class="row profile--settings--row">
        <div class="columns">
          <h4 class="block-title">
            {{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.TITLE') }}
          </h4>
          <p>{{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.NOTE') }}</p>
          <label :class="{ error: $v.name.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.NAME.LABEL') }}
            <input
              v-model="name"
              type="text"
              :placeholder="$t('GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER')"
              data-testid="general-workspace-name-input"
              @blur="$v.name.$touch"
            />
            <span
              v-if="$v.name.$error"
              class="message"
              data-testid="general-workspace-name-error"
            >
              {{ $t('GENERAL_SETTINGS.FORM.NAME.ERROR') }}
            </span>
          </label>
          <label :class="{ error: $v.locale.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.LABEL') }}
            <select v-model="locale" data-testid="general-language-select">
              <option
                v-for="lang in languagesSortedByCode"
                :key="lang.iso_639_1_code"
                :value="lang.iso_639_1_code"
                :data-testid="`general-language-${lang.iso_639_1_code}-option`"
              >
                {{ lang.name }}
              </option>
            </select>
            <span
              v-if="$v.locale.$error"
              class="message"
              data-testid="general-language-error"
            >
              {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.ERROR') }}
            </span>
          </label>
          <label v-if="featureInboundEmailEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.FEATURES.INBOUND_EMAIL_ENABLED') }}
          </label>
          <label v-if="featureCustomReplyEmailEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.LABEL') }}
            <input
              v-model="supportEmail"
              type="text"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.PLACEHOLDER')
              "
              data-testid="general-support-email-input"
            />
          </label>

          <label v-if="showAutoResolutionConfig">
            {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.LABEL') }}
            <div class="share-row">
              <label :class="{ error: $v.autoResolveDurationDays.$error }">
                {{
                  $t(
                    'GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.INPUTS.LABELS.DAYS'
                  )
                }}
                <input
                  v-model="autoResolveDurationDays"
                  type="number"
                  :placeholder="
                    $t(
                      'GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.INPUTS.PLACEHOLDERS.DAYS'
                    )
                  "
                  data-testid="general-resolve-duration-days-input"
                  @blur="$v.autoResolveDurationDays.$touch"
                />
                <span v-if="$v.autoResolveDurationDays.$error" class="message">
                  {{
                    $t(
                      'GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.INPUTS.ERRORS.DAYS'
                    )
                  }}
                </span>
              </label>
              <label :class="{ error: $v.autoResolveDurationHours.$error }">
                {{
                  $t(
                    'GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.INPUTS.LABELS.HOURS'
                  )
                }}
                <input
                  v-model="autoResolveDurationHours"
                  type="number"
                  :placeholder="
                    $t(
                      'GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.INPUTS.PLACEHOLDERS.HOURS'
                    )
                  "
                  data-testid="general-resolve-duration-hours-input"
                  min="0"
                  max="24"
                  @blur="$v.autoResolveDurationHours.$touch"
                />
                <span v-if="$v.autoResolveDurationHours.$error" class="message">
                  {{
                    $t(
                      'GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.INPUTS.ERRORS.HOURS'
                    )
                  }}
                </span>
              </label>
              <label :class="{ error: $v.autoResolveDurationMinutes.$error }">
                {{
                  $t(
                    'GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.INPUTS.LABELS.MINUTES'
                  )
                }}
                <input
                  v-model="autoResolveDurationMinutes"
                  type="number"
                  :placeholder="
                    $t(
                      'GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.INPUTS.PLACEHOLDERS.MINUTES'
                    )
                  "
                  data-testid="general-resolve-duration-mins-input"
                  step="5"
                  min="0"
                  max="60"
                  @blur="$v.autoResolveDurationMinutes.$touch"
                />
                <span
                  v-if="$v.autoResolveDurationMinutes.$error"
                  class="message"
                >
                  {{
                    $t(
                      'GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.INPUTS.ERRORS.MINUTES'
                    )
                  }}
                </span>
              </label>
            </div>
          </label>
          <label :class="{ error: $v.autoArchiveDuration.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.AUTO_ARCHIVE_DURATION.LABEL') }}
            <input
              v-model="autoArchiveDuration"
              type="number"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.AUTO_ARCHIVE_DURATION.PLACEHOLDER')
              "
              data-testid="general-archive-duration-input"
              @blur="$v.autoArchiveDuration.$touch"
            />
            <p class="note">
              {{ $t('GENERAL_SETTINGS.FORM.AUTO_ARCHIVE_DURATION.NOTE') }}
            </p>
            <span v-if="$v.autoArchiveDuration.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.AUTO_ARCHIVE_DURATION.ERROR') }}
            </span>
          </label>
        </div>
      </div>

      <div class="profile--settings--row row">
        <div class="columns">
          <h4 class="block-title">
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.TITLE') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.NOTE') }}
          </p>
          <woot-code :script="getAccountId" />
        </div>
      </div>
      <!-- <div class="current-version">
        <div>{{ `v${globalConfig.appVersion}` }}</div>
        <div v-if="hasAnUpdateAvailable && globalConfig.displayManifest">
          {{
            $t('GENERAL_SETTINGS.UPDATE_CHATWOOT', {
              latestHooryVersion: latestHooryVersion,
            })
          }}
        </div>
        <div class="build-id">
          <div>{{ `Build ${globalConfig.gitSha}` }}</div>
        </div>
      </div> -->

      <woot-submit-button
        class="button nice success button--fixed-top"
        :button-text="$t('GENERAL_SETTINGS.SUBMIT')"
        :loading="isUpdating"
        data-testid="general-submit-button"
      />
    </form>

    <woot-loading-state v-if="uiFlags.isFetchingItem" />
  </div>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import { FEATURE_FLAGS } from '../../../../featureFlags';
const semver = require('semver');
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { getLanguageDirection } from 'dashboard/components/widgets/conversation/advancedFilterItems/languages';

const FIVE_MINS_IN_DAYS_UNIT = 4.99 / (24 * 60);

export default {
  mixins: [accountMixin, alertMixin, configMixin, uiSettingsMixin],
  data() {
    return {
      id: '',
      name: '',
      locale: 'en',
      domain: '',
      supportEmail: '',
      features: {},
      autoResolveDurationDays: null,
      autoResolveDurationHours: null,
      autoResolveDurationMinutes: null,
      autoArchiveDuration: null,
      latestHooryVersion: null,
    };
  },
  validations: {
    name: {
      required,
    },
    locale: {
      required,
    },
    autoResolveDurationDays: {
      minValue: minValue(0),
      maxValue: maxValue(365),
    },
    autoResolveDurationHours: {
      minValue: minValue(0),
      maxValue: maxValue(24),
    },
    autoResolveDurationMinutes: {
      minValue: minValue(0),
      maxValue: maxValue(60),
    },
    autoArchiveDuration: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(365),
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    showAutoResolutionConfig() {
      return this.isFeatureEnabledonAccount(
        this.accountId,
        FEATURE_FLAGS.AUTO_RESOLVE_CONVERSATIONS
      );
    },
    hasAnUpdateAvailable() {
      if (!semver.valid(this.latestHooryVersion)) {
        return false;
      }

      return semver.lt(this.globalConfig.appVersion, this.latestHooryVersion);
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },

    featureInboundEmailEnabled() {
      return !!this.features.inbound_emails;
    },

    featureCustomReplyEmailEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_email
      );
    },

    getAccountId() {
      return this.id.toString();
    },

    autoResolveDuration() {
      return {
        days: Number(this.autoResolveDurationDays),
        hours: Number(this.autoResolveDurationHours),
        minutes: Number(this.autoResolveDurationMinutes),
      };
    },
  },
  mounted() {
    if (!this.id) {
      this.initializeAccount();
    }
  },
  methods: {
    async initializeAccount() {
      try {
        await this.$store.dispatch('accounts/get');
        const {
          name,
          locale,
          id,
          domain,
          support_email,
          features,
          auto_resolve_duration,
          auto_archive_duration,
          latest_chatwoot_version: latestHooryVersion,
        } = this.getAccount(this.accountId);

        this.$root.$i18n.locale = locale;
        this.name = name;
        this.locale = locale;
        this.id = id;
        this.domain = domain;
        this.supportEmail = support_email;
        this.features = features;
        this.autoArchiveDuration = auto_archive_duration;
        this.latestHooryVersion = latestHooryVersion;

        if (auto_resolve_duration) {
          const {
            days: autoResolveDurationDays,
            hours: autoResolveDurationHours,
            minutes: autoResolveDurationMinutes,
          } = this.convertDayDurationToComponents(auto_resolve_duration);
          this.autoResolveDurationDays = autoResolveDurationDays;
          this.autoResolveDurationHours = autoResolveDurationHours;
          this.autoResolveDurationMinutes = autoResolveDurationMinutes;
        }
      } catch (error) {
        // Ignore error
      }
    },

    convertDayDurationToComponents(duration) {
      const days = Math.floor(duration);
      const hoursDecimal = (Number(duration) - days) * 24;
      const hours = Math.floor(hoursDecimal);
      const minutes = Math.round((hoursDecimal - hours) * 60);
      return {
        days,
        hours,
        minutes,
      };
    },

    convertTimeComponentsToDuration({ days, hours, minutes }) {
      const hoursToDays = hours / 24;
      const minutesToDays = minutes / (24 * 60);
      const totalDuration = days + hoursToDays + minutesToDays;
      return totalDuration.toFixed(10);
    },

    async updateAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
        return;
      }

      let autoResolveDuration = 0;
      if (
        !this.autoResolveDurationDays &&
        !this.autoResolveDurationHours &&
        !this.autoResolveDurationMinutes
      ) {
        autoResolveDuration = null;
      } else {
        autoResolveDuration = this.convertTimeComponentsToDuration(
          this.autoResolveDuration
        );
        if (autoResolveDuration < FIVE_MINS_IN_DAYS_UNIT) {
          this.showAlert(
            this.$t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.ERROR')
          );
          return;
        }
      }

      try {
        await this.$store.dispatch('accounts/update', {
          locale: this.locale,
          name: this.name,
          support_email: this.supportEmail,
          auto_resolve_duration: autoResolveDuration,
          auto_archive_duration: this.autoArchiveDuration,
        });
        this.$root.$i18n.locale = this.locale;
        document.title = this.$route.meta.title
          ? `Hoory - [${this.$t(this.$route.meta.title)}]`
          : 'Hoory';
        this.getAccount(this.id).locale = this.locale;
        this.updateDirectionView(this.locale);
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
        // reload the page to update the UI
        window.location.reload();
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
      }
    },

    updateDirectionView(locale) {
      const isRTLSupported = getLanguageDirection(locale);
      this.updateUISettings({
        rtl_view: isRTLSupported,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings {
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0px;
  @include main-container;
}

.profile--settings--row {
  @include border-normal-bottom;
  max-width: 50% !important;
  padding: $space-normal;
  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }
  .small-9 {
    padding: $space-normal;
  }
}

.current-version {
  font-size: var(--font-size-small);
  text-align: center;
  padding: var(--space-normal);
}

.note {
  font-size: var(--font-size-small);
  color: var(--b-500);
}

.share-row {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
</style>
