<template>
  <div v-if="showFilters && !!allCounts" class="dropdown-filters-counts">
    <p class="title">{{ $t('FILTER.ATTRIBUTES.APPLIED_FILTERS') }}</p>
    <p
      v-for="(filter, index) in conversationSelectedFilters"
      :key="index"
      class="count-items"
    >
      <span class="status-wrapper">
        <span v-if="isValidLocale(filter.attribute_key)">
          {{ getTranslatedFilter(filter.attribute_key) }}
        </span>
        <span v-else>{{ filter.attribute_key }}</span>
        <span class="status-wrapper__equality">{{
          getTranslateEquality(filter.filter_operator)
        }}</span>
      </span>
      <span v-if="Array.isArray(filter.values)">
        <span
          v-for="item in filter.values"
          :key="item.id"
          class="filter-values"
        >
          {{ item.name }}
        </span>
      </span>
      <span v-else class="filter-values">{{
        filter.values.name ? filter.values.name : filter.values
      }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    showFilters: {
      type: Boolean,
      default: false,
    },
    selectedFilters: {
      type: Array,
      default: () => [],
    },
    allCounts: {
      type: Number,
      default: 0,
    },
    conversationType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      memorizeFilters: {},
    };
  },
  computed: {
    conversationSelectedFilters() {
      if (this.conversationType === 'archived') {
        return this.selectedFilters.filter(
          item => item.attribute_key !== 'status'
        );
      }
      return this.selectedFilters;
    },
  },
  methods: {
    getTranslatedFilter(key) {
      if (this.memorizeFilters[key]) {
        return this.memorizeFilters[key];
      }
      const processedKey = key
        .replace('_id', '_name')
        .replace('_code', '_name')
        .toUpperCase();
      const translatedValue = this.$t(`FILTER.ATTRIBUTES.${processedKey}`);
      this.memorizeFilters[key] = translatedValue;

      return translatedValue;
    },
    getTranslateEquality(key) {
      if (this.memorizeFilters[key]) {
        return this.memorizeFilters[key];
      }
      const translatedValue = this.$t(`FILTER.OPERATOR_LABELS.${key}`);
      this.memorizeFilters[key] = translatedValue;

      return translatedValue;
    },
    isValidLocale(key) {
      if (this.memorizeFilters[`valid_${key}`]) {
        return this.memorizeFilters[`valid_${key}`];
      }
      const processedKey = key
        .replace('_id', '_name')
        .replace('_code', '_name')
        .toUpperCase();
      const isValid = this.$i18n.te(`FILTER.ATTRIBUTES.${processedKey}`);
      this.memorizeFilters[`valid_${key}`] = isValid;

      return isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-filters-counts {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  z-index: 999;
  padding: 8px 12px 8px 12px;
  gap: 16px;
  border-radius: 10px;
  background: white;
  min-width: 356px;
  width: auto;
  white-space: nowrap;
  box-shadow: var(--shadow-medium);
  .title {
    font-size: 16px !important;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 10px;
  }
  .count-items {
    display: flex;
    justify-content: space-between;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 20px;
    padding: 0 0 8px 0;
    letter-spacing: -0.02em;
    border: none;
    border-bottom: 1px solid var(--s-200);
    &:last-child {
      border-bottom: none;
    }
  }
}
.filter-values {
  display: block;
  width: 150px;
  white-space: normal;
  word-break: break-word;
  text-align: end;
}
.status-wrapper {
  display: grid;
  align-items: center;
  &__equality {
    color: var(--s-700);
    font-size: 14px;
  }
}
</style>
