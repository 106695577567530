import { mapGetters } from 'vuex';

import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { LocalStorage } from 'shared/helpers/localStorage';
import adminMixin from './isAdmin';

export default {
  mixins: [adminMixin],
  computed: {
    ...mapGetters({
      subscription: 'billing/getBillingInfo',
      uiFlagsBilling: 'billing/getUIFlags',
    }),
    shouldShowBanner() {
      return (
        !this.uiFlagsBilling.userDismissedBanner &&
        !this.isTrialDismissed() &&
        this.subscription &&
        this.subscription.isTrial &&
        this.isAdmin
      );
    },
  },
  methods: {
    isTrialDismissed() {
      const dismissExpire =
        LocalStorage.get(LOCAL_STORAGE_KEYS.DISMISSED_TRIAL_BANNER_EXPIRE) ||
        new Date().getTime();
      return Number(dismissExpire) > new Date().getTime();
    },
  },
};
