<template>
  <div
    class="row app-wrapper dashboard-root unset-flex-flow"
    :class="{ 'top-padding': shouldShowBanner }"
  >
    <sidebar
      :route="currentRoute"
      :show-secondary-sidebar="isSidebarOpen"
      @open-notification-panel="openNotificationPanel"
      @open-onboarding-panel="openOnBoardingPanel"
      @toggle-account-modal="toggleAccountModal"
      @open-key-shortcut-modal="toggleKeyShortcutModal"
      @close-key-shortcut-modal="closeKeyShortcutModal"
      @show-add-label-popup="showAddLabelPopup"
    />
    <div
      class="app-content-wrapper"
      :class="{ 'has-sticky-bar': getBannerVisibility }"
    >
      <template v-if="getBannerVisibility">
        <sticky-bar />
      </template>
      <section class="app-content columns bg-light position-relative ">
        <router-view />
        <command-bar />
        <account-selector
          :show-account-modal="showAccountModal"
          @close-account-modal="toggleAccountModal"
          @show-create-account-modal="openCreateAccountModal"
        />
        <add-account-modal
          :show="showCreateAccountModal"
          @close-account-create-modal="closeCreateAccountModal"
        />
        <woot-key-shortcut-modal
          :show.sync="showShortcutModal"
          @close="closeKeyShortcutModal"
          @clickaway="closeKeyShortcutModal"
        />
        <notification-panel
          v-if="isNotificationPanel"
          @close="closeNotificationPanel"
        />
        <on-boarding-panel
          v-if="isOnboardingPanelOpen"
          @close="closeOnboardingPanel"
        />
        <woot-modal
          :show.sync="showAddLabelModal"
          :on-close="hideAddLabelPopup"
        >
          <add-label-modal @close="hideAddLabelPopup" />
        </woot-modal>
      </section>
    </div>
  </div>
</template>

<script>
import Sidebar from '../../components/layout/Sidebar';
import CommandBar from './commands/commandbar.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import WootKeyShortcutModal from 'dashboard/components/widgets/modal/WootKeyShortcutModal';
import AddAccountModal from 'dashboard/components/layout/sidebarComponents/AddAccountModal';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector';
import AddLabelModal from 'dashboard/routes/dashboard/settings/labels/AddLabel';
import NotificationPanel from 'dashboard/routes/dashboard/notifications/components/NotificationPanel';
import OnBoardingPanel from 'dashboard/routes/dashboard/onboarding/components/OnBoardingPanel';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import bannerMixin from 'dashboard/mixins/banner';
import wootConstants from 'dashboard/constants/globals';
import { mapGetters } from 'vuex';
import StickyBar from 'dashboard/components/app/StickyBar.vue';
import callMixin from 'dashboard/mixins/call';

export default {
  components: {
    Sidebar,
    CommandBar,
    WootKeyShortcutModal,
    AddAccountModal,
    AccountSelector,
    AddLabelModal,
    NotificationPanel,
    OnBoardingPanel,
    StickyBar,
  },
  mixins: [uiSettingsMixin, bannerMixin, callMixin],
  data() {
    return {
      showAccountModal: false,
      showCreateAccountModal: false,
      showAddLabelModal: false,
      showShortcutModal: false,
      isNotificationPanel: false,
      displayLayoutType: '',
    };
  },
  computed: {
    ...mapGetters({
      isOnboardingPanelOpen: 'onboarding/isPanelOpen',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      bannerVisibility: 'stickyBanner/getBannerVisibility',
      currentAccountId: 'getCurrentAccountId',
    }),
    currentRoute() {
      return ' ';
    },
    getBannerVisibility() {
      return this.bannerVisibility || this.missedCallsCount >= 1;
    },
    isSidebarOpen() {
      const { show_secondary_sidebar: showSecondarySidebar } = this.uiSettings;
      return this.getConditionalToggleSidebarValue(showSecondarySidebar);
    },
    previouslyUsedDisplayType() {
      const {
        previously_used_conversation_display_type: conversationDisplayType,
      } = this.uiSettings;
      return conversationDisplayType;
    },
    previouslyUsedSidebarView() {
      const {
        previously_used_sidebar_view: showSecondarySidebar,
      } = this.uiSettings;
      return showSecondarySidebar;
    },
  },
  watch: {
    displayLayoutType() {
      const { LAYOUT_TYPES } = wootConstants;
      this.updateUISettings({
        conversation_display_type:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? LAYOUT_TYPES.EXPANDED
            : this.previouslyUsedDisplayType,
        show_secondary_sidebar:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? false
            : this.previouslyUsedSidebarView,
      });
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    bus.$on(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    bus.$off(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },

  methods: {
    handleResize() {
      const { SMALL_SCREEN_BREAKPOINT, LAYOUT_TYPES } = wootConstants;
      let throttled = false;
      const delay = 150;

      if (throttled) {
        return;
      }
      throttled = true;

      setTimeout(() => {
        throttled = false;
        if (window.innerWidth <= SMALL_SCREEN_BREAKPOINT) {
          this.displayLayoutType = LAYOUT_TYPES.EXPANDED;
        } else {
          this.displayLayoutType = LAYOUT_TYPES.CONDENSED;
        }
      }, delay);
    },
    toggleSidebar() {
      this.updateUISettings({
        show_secondary_sidebar: this.getConditionalToggleSidebarValue(
          !this.isSidebarOpen
        ),
        previously_used_sidebar_view: this.getConditionalToggleSidebarValue(
          !this.isSidebarOpen
        ),
      });
    },
    getConditionalToggleSidebarValue(value) {
      const allowedPages = [
        'home',
        'conversation_mentions',
        'conversation_unattended',
        'inbox_dashboard',
        'label_conversations',
        'inbox_conversation',
      ];
      if (allowedPages.includes(this.$route.name)) {
        return value;
      }
      return true;
    },
    openCreateAccountModal() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleKeyShortcutModal() {
      this.showShortcutModal = true;
    },
    closeKeyShortcutModal() {
      this.showShortcutModal = false;
    },
    showAddLabelPopup() {
      this.showAddLabelModal = true;
    },
    hideAddLabelPopup() {
      this.showAddLabelModal = false;
    },
    openNotificationPanel() {
      this.isNotificationPanel = true;
      if (this.isOnboardingPanelOpen)
        this.$store.dispatch('onboarding/setPanelOpen', { isOpen: false });
    },
    openOnBoardingPanel() {
      this.$store.dispatch('onboarding/setPanelOpen', { isOpen: true });
    },
    closeNotificationPanel() {
      this.isNotificationPanel = false;
    },
    closeOnboardingPanel() {
      this.$store.dispatch('onboarding/setPanelOpen', { isOpen: false });
    },
  },
};
</script>
