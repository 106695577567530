<template>
  <div class="profile-data-holder">
    <div class="image-holder">
      <thumbnail
        :src="currentUser.avatar_url"
        :username="currentUser.name"
        status=""
        size="44px"
        :should-show-status-always="false"
      />
    </div>
    <div class="data-holder">
      <div class="name">
        {{ currentUser.name }}
      </div>
      <div class="email">
        {{ currentUser.email }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../widgets/Thumbnail.vue';

export default {
  components: { Thumbnail },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
  mounted() {},
  methods: {},
};
</script>

<style>
.profile-data-holder {
  display: flex;
  flex-direction: row;
  margin: var(--space-one);

  .image-holder {
    margin-right: 6px;
    .profile-image {
      height: 5.5rem;
      width: 5.5rem;
      border-radius: 100%;
    }
  }

  .data-holder {
    margin-left: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name {
      font-size: var(--font-size-default);
      font-weight: var(--font-weight-medium);
      line-height: 1.5rem; /* 150% */
      letter-spacing: -0.02rem;
      margin-bottom: 0.75rem;
    }

    .email {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      letter-spacing: -0.0175rem;
      color: var(--b-600);
      max-width: 170px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      line-height: unset;
    }
  }
}
</style>
