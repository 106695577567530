const state = {
  audioRecords: [],
  activeRecord: null,
  playPause: 'pause',
  nextPrev: null,
  seekData: 0,
  rateData: 1,
  bannerVisibility: false,
  missedCallsVisibility: false,
};

export const getters = {
  getAudioRecords: $state => $state.audioRecords,
  getActiveRecord: $state => $state.activeRecord,
  getPlayPause: $state => $state.playPause,
  getNextPrev: $state => $state.nextPrev,
  getSeekData: $state => $state.seekData,
  getRateData: $state => $state.rateData,
  getBannerVisibility: $state => $state.bannerVisibility,
  getMissedCallsVisibility: $state => $state.missedCallsVisibility,
};

export const actions = {
  addAudioRecords: async ({ commit }, records) => {
    try {
      commit('addAudioRecords', records);
    } catch (error) {
      // Ignore error
    }
  },
  removeAudioRecords: async ({ commit }, messageId) => {
    try {
      commit('removeAudioRecords', messageId);
    } catch (error) {
      // Ignore error
    }
  },
};

export const mutations = {
  setAudioRecords($state, records) {
    $state.audioRecords = records;
  },
  addAudioRecords($state, data) {
    const { attachments, sender = {}, conversation, inbox_id } = data;
    const newAudioRecords = attachments.reduce((acc, attachment) => {
      if (attachment.file_type === 'audio') {
        acc.push({
          ...attachment,
          sender_id: sender.id || null,
          sender_name: sender.name || null,
          sender_avatar_url: sender.thumbnail || null,
          sender_flag: sender.custom_attributes?.country || null,
          sender_number: sender.phone_number || null,
          initial: false,
          conversation_id: conversation.id,
          inbox_id: inbox_id,
        });
      }
      return acc;
    }, []);

    if (newAudioRecords.length) {
      const existingIds = new Set($state.audioRecords.map(record => record.id));
      const uniqueRecords = newAudioRecords.filter(
        record => !existingIds.has(record.id)
      );
      $state.audioRecords = [...$state.audioRecords, ...uniqueRecords];
    }
  },
  removeAudioRecords($state, messageId) {
    $state.audioRecords = $state.audioRecords.filter(
      item => item.message_id !== messageId
    );
    if (messageId === $state.activeRecord.message_id) {
      $state.activeRecord = null;
      $state.bannerVisibility = false;
      $state.playPause = 'stop';
      $state.rateData = 1;
    }
  },
  setActiveRecord($state, record) {
    $state.activeRecord = record;
  },
  setSeekData($state, data) {
    $state.seekData = data;
  },
  setRateData($state, data) {
    $state.rateData = data;
  },
  setPlayPause($state, data) {
    $state.playPause = data;
  },
  setNextPrev($state, data) {
    $state.nextPrev = data;
  },
  setBannerVisibility($state, visible) {
    $state.bannerVisibility = visible;
  },
  setMissedCallsVisibility($state, visible) {
    $state.missedCallsVisibility = visible;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
