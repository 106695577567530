<template>
  <div class="small-6 medium-4 large-4 ">
    <button class="channel" @click="$emit('click')">
      <img :src="getImageSrc" :alt="title" />
      <h3 class="channel__title">
        {{ title }}
      </h3>
      <div v-if="title === 'Call'" class="new-channel">
        {{ $t('INBOX_MGMT.BETA') }}
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    svgKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    getImageSrc() {
      // eslint-disable-next-line global-require
      return require(`dashboard/assets/icons/channels/${this.svgKey}.svg`);
    },
  },
};
</script>

<style scoped lang="scss">
.inactive {
  filter: grayscale(100%) opacity(0.5);
}
.new-channel {
  position: absolute;
  right: var(--space-small);
  top: var(--space-small);
  padding: var(--space-smaller) var(--space-one);
  border-radius: var(--border-radius-medium);
  background: linear-gradient(
    277.71deg,
    #6fcf97 -23.75%,
    #56b77e 47.92%,
    #49a16d 122.51%
  );
  color: var(--white);
  border: 1px solid #dfd7f4;
  font-size: var(--font-size-small);
}
.channel {
  position: relative;
  background: var(--white);
  outline: 1px solid var(--s-100);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: var(--space-normal) 0;
  transition: all 0.2s ease-in;
  align-items: center;
  height: 95%;
  width: 97%;
  border-radius: var(--border-radius-medium);
  &:hover {
    outline: 1px solid var(--s-300);
    box-shadow: var(--shadow-medium);
    z-index: var(--z-index-high);
  }

  &.disabled {
    opacity: 0.6;
  }

  img {
    margin: var(--space-normal) auto;
    width: 30%;
    max-width: 60px;
  }

  .channel__title {
    color: var(--color-body);
    font-size: var(--font-size-default);
    text-align: center;
    text-transform: capitalize;
  }

  p {
    color: var(--b-500);
    width: 100%;
  }
}
</style>
