<template>
  <div class="filter-container">
    <reports-filters-date-range @on-range-change="onDateRangeChange" />
    <woot-date-range-picker
      v-if="isDateRangeSelected"
      show-range
      class="no-margin auto-width"
      :value="customDateRange"
      :selected-option="selectedOption"
      :confirm-text="$t('REPORT.CUSTOM_DATE_RANGE.CONFIRM')"
      :placeholder="$t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER')"
      @change="onCustomDateRangeChange"
    />
    <reports-filters-date-group-by
      v-if="showGroupByFilter && isGroupByPossible"
      :valid-group-options="validGroupOptions"
      :selected-option="selectedGroupByFilter"
      @on-grouping-change="onGroupingChange"
    />
    <reports-filters-agents
      v-if="showAgentsFilter"
      @agents-filter-selection="handleAgentsFilterSelection"
    />
    <reports-filters-agentbots
      v-if="showAgentbotsFilter"
      @agentbots-filter-selection="handleAgentbotsFilterSelection"
    />
    <reports-filters-labels
      v-if="showLabelsFilter"
      @labels-filter-selection="handleLabelsFilterSelection"
    />
    <reports-filters-teams
      v-if="showTeamFilter"
      @team-filter-selection="handleTeamFilterSelection"
    />
    <reports-filters-inboxes
      v-if="showInboxFilter"
      @inbox-filter-selection="handleInboxFilterSelection"
    />
    <reports-filters-ratings
      v-if="showRatingFilter"
      @rating-filter-selection="handleRatingFilterSelection"
    />
    <div v-if="showBusinessHoursSwitch" class="business-hours">
      <span class="business-hours-text">
        {{ $t('REPORT.BUSINESS_HOURS') }}
      </span>
      <span>
        <woot-switch v-model="businessHoursSelected" />
      </span>
    </div>
  </div>
</template>
<script>
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';
import ReportsFiltersDateRange from './Filters/DateRange.vue';
import ReportsFiltersDateGroupBy from './Filters/DateGroupBy.vue';
import ReportsFiltersAgents from './Filters/Agents.vue';
import ReportsFiltersAgentbots from './Filters/Agentbots.vue';
import ReportsFiltersLabels from './Filters/Labels.vue';
import ReportsFiltersInboxes from './Filters/Inboxes.vue';
import ReportsFiltersTeams from './Filters/Teams.vue';
import ReportsFiltersRatings from './Filters/Ratings.vue';
import subDays from 'date-fns/subDays';
import { DATE_RANGE_OPTIONS, GROUP_BY_OPTIONS } from '../constants';
import {
  getUnixStartOfDay,
  getUnixEndOfDay,
  getDateTime,
} from 'helpers/DateHelper';
import { getTodayRange } from '../../../../../../shared/helpers/DateHelper';

export default {
  components: {
    WootDateRangePicker,
    ReportsFiltersDateRange,
    ReportsFiltersDateGroupBy,
    ReportsFiltersAgents,
    ReportsFiltersAgentbots,
    ReportsFiltersLabels,
    ReportsFiltersInboxes,
    ReportsFiltersTeams,
    ReportsFiltersRatings,
  },
  props: {
    filterItemsList: {
      type: Array,
      default: () => [],
    },
    showGroupByFilter: {
      type: Boolean,
      default: false,
    },
    showAgentsFilter: {
      type: Boolean,
      default: false,
    },
    showAgentbotsFilter: {
      type: Boolean,
      default: false,
    },
    showLabelsFilter: {
      type: Boolean,
      default: false,
    },
    showInboxFilter: {
      type: Boolean,
      default: false,
    },
    showRatingFilter: {
      type: Boolean,
      default: false,
    },
    showTeamFilter: {
      type: Boolean,
      default: false,
    },
    showBusinessHoursSwitch: {
      type: Boolean,
      default: true,
    },
    selectedOption: {
      type: Object,
      default: () => GROUP_BY_OPTIONS.HOUR,
    },
  },
  data() {
    return {
      // default value, need not be translated
      selectedDateRange: DATE_RANGE_OPTIONS.TODAY,
      selectedGroupByFilter: this.selectedOption,
      selectedLabel: null,
      selectedInbox: null,
      selectedTeam: null,
      selectedRating: null,
      selectedAgents: [],
      selectedAgentbots: [],
      customDateRange: [new Date(), new Date()],
      businessHoursSelected: false,
    };
  },
  computed: {
    isDateRangeSelected() {
      return (
        this.selectedDateRange.id === DATE_RANGE_OPTIONS.CUSTOM_DATE_RANGE.id
      );
    },
    isGroupByPossible() {
      return this.selectedDateRange.id !== DATE_RANGE_OPTIONS.LAST_7_DAYS.id;
    },
    to() {
      if (this.isDateRangeSelected) {
        return getDateTime(this.customDateRange[1]);
      }
      if (this.selectedDateRange.id === DATE_RANGE_OPTIONS.TODAY.id) {
        return getTodayRange().to;
      }
      return getUnixEndOfDay(new Date());
    },
    from() {
      if (this.isDateRangeSelected) {
        return getDateTime(this.customDateRange[0]);
      }
      if (this.selectedDateRange.id === DATE_RANGE_OPTIONS.TODAY.id) {
        return getTodayRange().from;
      }
      const { offset } = this.selectedDateRange;
      const fromDate = subDays(new Date(), offset);
      return getUnixStartOfDay(fromDate);
    },
    validGroupOptions() {
      return this.selectedDateRange.groupByOptions;
    },
    validGroupBy() {
      if (!this.selectedGroupByFilter) {
        return this.validGroupOptions[0];
      }

      const validIds = this.validGroupOptions.map(opt => opt.id);
      if (validIds.includes(this.selectedGroupByFilter.id)) {
        return this.selectedGroupByFilter;
      }
      return this.validGroupOptions[0];
    },
  },
  watch: {
    businessHoursSelected() {
      this.emitChange();
    },
  },
  mounted() {
    this.emitChange();
  },
  methods: {
    emitChange() {
      const {
        from,
        to,
        selectedGroupByFilter: groupBy,
        businessHoursSelected: businessHours,
        selectedAgents,
        selectedAgentbots,
        selectedLabel,
        selectedInbox,
        selectedTeam,
        selectedRating,
      } = this;
      this.$emit('filter-change', {
        from,
        to,
        groupBy,
        businessHours,
        selectedAgents,
        selectedAgentbots,
        selectedLabel,
        selectedInbox,
        selectedTeam,
        selectedRating,
      });
    },
    onDateRangeChange(selectedRange) {
      this.selectedDateRange = selectedRange;
      this.selectedGroupByFilter = this.validGroupBy;
      this.emitChange();
    },
    onCustomDateRangeChange(value) {
      this.customDateRange = value;
      this.selectedGroupByFilter = this.validGroupBy;
      this.emitChange();
    },
    onGroupingChange(payload) {
      this.selectedGroupByFilter = payload;
      this.emitChange();
    },
    handleAgentsFilterSelection(selectedAgents) {
      this.selectedAgents = selectedAgents;
      this.emitChange();
    },
    handleAgentbotsFilterSelection(selectedAgentbots) {
      this.selectedAgentbots = selectedAgentbots;
      this.emitChange();
    },
    handleLabelsFilterSelection(selectedLabel) {
      this.selectedLabel = selectedLabel;
      this.emitChange();
    },
    handleInboxFilterSelection(selectedInbox) {
      this.selectedInbox = selectedInbox;
      this.emitChange();
    },
    handleTeamFilterSelection(selectedTeam) {
      this.selectedTeam = selectedTeam;
      this.emitChange();
    },
    handleRatingFilterSelection(selectedRating) {
      this.selectedRating = selectedRating;
      this.emitChange();
    },
  },
};
</script>

<style scoped>
.filter-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: var(--space-slab);

  margin-bottom: var(--space-normal);
  @media screen and (max-width: 1300px) {
    display: grid;
    grid-template-columns: auto;
  }

  @media screen and (min-width: 1301px) and (max-width: 1800px) {
    display: grid;
    grid-template-columns: auto auto;
  }
}
</style>
