<template>
  <svg
    :width="size"
    :height="size"
    fill="none"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-for="source in pathSource"
      :key="source"
      :d="source"
      :fill="fillColor"
      :fill-rule="fillRule"
      :stroke="strokeColor"
      :stroke-linecap="strokeLinecap"
      :stroke-linejoin="strokeLinejoin"
      :stroke-width="strokeWidth"
    />
  </svg>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '20',
    },
    type: {
      type: String,
      default: 'outline',
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
    fillRule: {
      type: String,
      required: false,
      default: 'nonzero',
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
    strokeColor: {
      type: String,
      default: 'currentColor',
    },
    strokeLinecap: {
      type: String,
      default: 'none',
    },
    strokeLinejoin: {
      type: String,
      default: 'none',
    },
    strokeWidth: {
      type: String,
      default: '0',
    },
  },

  computed: {
    pathSource() {
      // To support icons with multiple paths
      const path = this.icons[`${this.icon}-${this.type}`];
      if (path.constructor === Array) {
        return path;
      }
      return [path];
    },
  },
};
</script>
