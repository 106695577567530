<template>
  <woot-modal v-if="subscription" :show.sync="show" :on-close="onClose">
    <div v-if="isLoading || !currentPlan" class="spinner-cover">
      <spinner-hoory size="medium" color="#fff" />
    </div>
    <div class="column upgrade-plan-body">
      <woot-modal-header
        v-if="currentPlan"
        :header-title="$t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.UPDATE_PLAN')"
      />
      <div v-if="currentPlan" class="main-content">
        <div class="select-plan">
          <div class="fs-default fw-medium">
            {{ $t('BILLING_SETTINGS.CURRENT_PLAN.PLAN') }}
          </div>
          <div class="subscription-options">
            <multiselect
              v-model="currentInterval"
              class="no-margin margin-right-smaller plan-options"
              track-by="value"
              label="title"
              :placeholder="
                $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.CHOOSEN_INTERVAL')
              "
              :options="intervalItems"
              :allow-empty="false"
              :show-labels="false"
            />
            <multiselect
              v-model="currentPlan"
              class="no-margin plan-options"
              track-by="value"
              label="title"
              :placeholder="
                $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.CHOOSEN_PLAN')
              "
              :options="planItems"
              :allow-empty="false"
              :show-labels="false"
            />
          </div>
        </div>
        <div class="color-primary fs-default fw-medium">
          {{ planName }}
        </div>
        <div class="update-action-block">
          <div class="update-action-row">
            <div class="fw-medium fs-default">
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.AGENTS') }}
            </div>
            <div class="fw-medium fs-normal">
              $
              {{ (agentsCurrentCount * getAgentPricePerPlan).toFixed(2) }}
            </div>
          </div>
          <div class="update-action-row">
            <div class="counter-switch-parent">
              <div class="counter-switcher" @click="changeAgentsCount(false)">
                -
              </div>
              <div class="counter-amount">{{ agentsCurrentCount }}</div>
              <div class="counter-switcher" @click="changeAgentsCount(true)">
                +
              </div>
            </div>
            <div class="fs-small">
              $
              {{ getAgentPricePerPlan.toFixed(2) }}
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.PER_AGENT') }}/{{
                currentInterval.value
              }}
            </div>
          </div>
        </div>
        <div v-if="agentsReduceLimit" class="remove-agent-note">
          {{ $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.AGENTS_LIMIT_NOTE') }}
          <router-link
            :to="{
              name: 'agent_list',
            }"
          >
            <span class="color-primary text-underline">
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.GO_TO_AGENTS') }}
            </span>
          </router-link>
        </div>
        <div class="update-action-block">
          <div class="update-action-row">
            <div class="fw-medium fs-default">
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.EXTRA_INBOXES') }}
            </div>
            <div class="fw-medium fs-normal">
              $
              {{ (getExtraInboxPricePerPlan * inboxesCurrentCount).toFixed(2) }}
            </div>
          </div>
          <div class="update-action-row">
            <div class="counter-switch-parent">
              <div class="counter-switcher" @click="changeInboxesCount(false)">
                -
              </div>
              <div class="counter-amount">{{ inboxesCurrentCount }}</div>
              <div class="counter-switcher" @click="changeInboxesCount(true)">
                +
              </div>
            </div>
            <div class="fs-small">
              $
              {{ getExtraInboxPricePerPlan.toFixed(2) }}
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.PER_INBOX') }}/{{
                currentInterval.value
              }}
            </div>
          </div>
        </div>
        <div v-if="inboxesReduceLimit" class="remove-agent-note">
          {{ $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.INBOXES_LIMIT_NOTE') }}
          <router-link
            :to="{
              name: 'settings_inbox_list',
            }"
          >
            <span class="color-primary text-underline">{{
              $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.GO_TO_INBOXES')
            }}</span>
          </router-link>
        </div>
        <div class="upgrade-plan-total">
          <div class="color-primary fw-medium fs-default">
            {{ $t('BILLING_SETTINGS.CURRENT_PLAN.TOTAL') }}
          </div>
          <div class="fw-medium fs-default">
            $
            {{
              (
                getExtraInboxPricePerPlan * inboxesCurrentCount +
                agentsCurrentCount * getAgentPricePerPlan
              ).toFixed(2)
            }}
          </div>
        </div>
        <div class="upgrade-plan-info color-primary fs-small">
          {{
            $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE.AGENTS_INBOXES_COUNTS', {
              agentsInitialCount,
              inboxesInitialCount,
            })
          }}
        </div>
      </div>
      <div v-if="currentPlan" class="row">
        <woot-button
          :is-disabled="agentsReduceLimit || inboxesReduceLimit"
          size="medium"
          variant="flat"
          class="full upgrade-plan-btn"
          color-scheme="primary"
          @click="updateOrPreviewPlan(true)"
        >
          {{ $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE_PLAN') }}
        </woot-button>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import SpinnerHoory from 'shared/components/SpinnerHoory.vue';
import { mapGetters } from 'vuex';
import billingMixin from 'dashboard/mixins/billing';
import axios from 'axios';
import wootConstants from 'dashboard/constants/globals.js';
import accountMixin from 'dashboard/mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
const { SERVICES_PATH } = wootConstants;

export default {
  components: {
    SpinnerHoory,
  },
  mixins: [billingMixin, accountMixin, alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      SERVICES_PATH,
      show: true,
      isLoading: false,
      agentsCurrentCount: null,
      inboxesCurrentCount: null,
      agentsInitialCount: null,
      inboxesInitialCount: null,
      currentPlan: null,
      currentInterval: null,
      billingConfigs: null,
      planItems: [
        { value: 'personal', title: 'Personal' },
        { value: 'startup', title: 'Start Up' },
        { value: 'team', title: 'Team' },
        { value: 'business', title: 'Business' },
      ],
      intervalItems: [
        { value: 'month', title: this.$t('PRICING_PLANS.PLAN.MONTHLY') },
        { value: 'year', title: this.$t('PRICING_PLANS.PLAN.YEARLY') },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      agentList: 'agents/getAgents',
      inboxList: 'inboxes/getInboxes',
    }),
    agentsReduceLimit() {
      return this.agentList.length > this.agentsCurrentCount;
    },
    inboxesReduceLimit() {
      const planConfig = this.findPlanByName(
        this.billingConfigs,
        this.currentPlan.title
      );
      return (
        this.inboxList.length >
        this.inboxesCurrentCount + planConfig.plan.limits.inboxes
      );
    },
    getAgentPricePerPlan() {
      const planConfig = this.findPlanByName(
        this.billingConfigs,
        this.currentPlan.title
      );
      if (planConfig.prices) {
        const price = planConfig.prices.find(
          p => p.recurring.interval === this.currentInterval.value
        );
        return price ? price.unit_amount / 100 : 0;
      }
      return 0;
    },
    getExtraInboxPricePerPlan() {
      const planConfig = this.billingConfigs.extraInboxPrices;
      if (planConfig.prices) {
        const price = planConfig.prices.find(
          p => p.recurring.interval === this.currentInterval.value
        );
        return price ? price.unit_amount / 100 : 0;
      }
      return 0;
    },
  },
  async created() {
    await this.$store.dispatch('agents/get');
    const response = await axios.get(
      `/${this.SERVICES_PATH.BILLING}/v1/config`
    );
    this.billingConfigs = response.data;
    this.agentsCurrentCount = this.subscription.items.mainSubscriptionItem.quantity;
    this.inboxesCurrentCount =
      this.subscription.items.extraInboxesItem?.quantity || 0;
    this.agentsInitialCount = this.agentList.length;
    this.inboxesInitialCount = this.inboxList.length;
    this.currentPlan = this.planItems.find(
      plan => plan.value === this.subscription.product.name.toLowerCase()
    );
    this.currentInterval = this.intervalItems.find(
      interval => interval.value === this.subscription.interval
    );
  },
  methods: {
    async updateOrPreviewPlan(update = false) {
      if (this.agentsReduceLimit || this.inboxesReduceLimit) return;
      const reqData = {
        subscriptionType: this.currentPlan.value,
        interval: this.currentInterval.value,
        agents: this.agentsCurrentCount,
        extraInboxes: this.inboxesCurrentCount,
        accountId: this.accountId,
      };
      this.isLoading = true;
      try {
        await this.previewOrUpdateSubscriptionData(reqData, update);
        await this.$store.dispatch('billing/getBillingInfo', this.accountId);
        this.showAlert('Subscription has been updated!');
      } catch (error) {
        this.showAlert(`Error: ${error?.message}`);
      } finally {
        this.onClose();
        this.show = false;
        this.isLoading = false;
      }
    },
    findPlanByName(data, planName) {
      return (
        Object.values(data).find(
          item =>
            item.plan && item.plan.name.toLowerCase() === planName.toLowerCase()
        ) || null
      );
    },
    changeAgentsCount(add) {
      if (add) {
        this.agentsCurrentCount += 1;
      } else if (!this.agentsReduceLimit && this.agentsCurrentCount !== 1) {
        this.agentsCurrentCount -= 1;
      }
    },
    changeInboxesCount(add) {
      if (add) {
        this.inboxesCurrentCount += 1;
      } else if (!this.inboxesReduceLimit && this.inboxesCurrentCount !== 0) {
        this.inboxesCurrentCount -= 1;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.upgrade-plan-body {
  min-height: 37px;
  .main-content {
    padding: var(--space-medium) var(--space-medium) 0;
  }
  .select-plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .plan-options {
    width: 200px;
  }
  .update-action-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .update-action-block {
    margin-top: var(--space-small);
    border-bottom: 1px solid var(--s-100);
    padding-bottom: var(--space-one);
    .update-action-row {
      margin-top: var(--space-one);
    }
  }
  .remove-agent-note {
    margin: var(--space-snug) 0;
    font-size: var(--font-size-small);
    color: #7d7d7d;
  }
  .upgrade-plan-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--s-100);
    padding: var(--space-normal) 0;
  }
  .upgrade-plan-info {
    margin-top: var(--space-normal);
    border-radius: var(--border-radius-normal);
    background-color: #f7f7f7;
    padding: var(--space-normal);
    text-align: center;
  }
  .upgrade-plan-btn {
    width: 100%;
  }
  .counter-switch-parent {
    background-color: #f7f7f7;
    padding: var(--space-smaller);
    border-radius: var(--border-radius-medium);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96px;
    .counter-switcher {
      padding: 0 var(--space-small);
      background-color: #fff;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-medium);
      border-radius: var(--border-radius-medium);
      cursor: pointer;
    }
    .counter-amount {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-medium);
      margin: 0 var(--space-small);
    }
  }
  .subscription-options {
    display: flex;
    align-items: end;
  }
}
</style>
