<template>
  <div
    class="missed-call-item"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div v-if="!hover" class="missed-call-info missed-call-block">
      <div class="missed-call-avatar">
        <VFlag
          v-if="callerCountry"
          :flag="callerCountry"
          class="phone-number-flag margin-right-2"
        />
        <img v-if="callerImg" :src="callerImg" class="missed-call-avatar-img" />
        <Avatar v-else :username="callerName" class="missed-call-sender-name" />
      </div>
      <div class="missed-call-data">
        <div class="missed-call-user-name">
          <div class="fs-default fw-medium">
            <span class="caller-name">
              {{ callerName }}
            </span>
          </div>
        </div>
        <div class="missed-call-desc">
          <div class="fs-default missed-call-status">
            {{ $t('CONVERSATION.MISSED_CALL') }}
          </div>
          <div class="fw-medium fs-mini">
            {{ dynamicTime(missedCallData.created_at) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="missed-call-actions missed-call-block">
      <div class="missed-call-action-events">
        <div class="missed-call-action-event call" @click="callAgain()">
          <fluent-icon
            icon="missed-call"
            size="18"
            view-box="0 0 18 18"
            fill-color="#ffffff"
          />
          <span class="margin-left-1 margin-right-1 fw-medium">{{
            $t('CONVERSATION.CALL_BACK')
          }}</span>
        </div>
        <div
          class="missed-call-action-event go-to margin-left-1 margin-right-1"
          @click="execGoToConversation(missedCallData.primary_actor.id)"
        >
          <fluent-icon
            icon="call-widget-right-arrow"
            size="16"
            view-box="0 0 16 16"
            fill-color="#ffffff"
          />
        </div>
      </div>
      <div
        class="missed-call-action-event close"
        @click.stop="
          clearMissedCall(
            missedCallData.primary_actor_id,
            missedCallData.primary_actor_type,
            missedCallData.primary_actor.id
          )
        "
      >
        <span class="fw-medium">{{ $t('CONVERSATION.HEADER.CLEAR') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VFlag from 'dashboard/components/ui/VFlag';
import Avatar from 'dashboard/components/widgets/Avatar';
import callMixin from 'dashboard/mixins/call';
import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';

export default {
  components: { Avatar, VFlag },
  mixins: [callMixin, alertMixin, timeMixin],
  props: {
    missedCallData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      userId: 'getCurrentUserID',
    }),
    callerName() {
      return this.missedCallData?.primary_actor?.meta?.sender.name || '';
    },
    callerCountry() {
      return (
        this.missedCallData?.primary_actor?.meta?.sender?.custom_attributes
          .country || null
      );
    },
    callerImg() {
      return this.missedCallData?.primary_actor?.meta?.sender.thumbnail || null;
    },
  },
  methods: {
    clearMissedCall(primaryActorId, primaryActorType, conversationId) {
      this.$store.dispatch('call/readMissedCall', {
        primaryActorId,
        primaryActorType,
      });
      this.$store.dispatch('setConversationsCallStatus', {
        status: null,
        conversationId,
      });
      this.$store.dispatch(
        'contactConversations/setContactConversationsCallStatus',
        {
          status: null,
          conversationId,
        }
      );
    },
    async callAgain() {
      await this.checkMicrophonePermission(
        () => {
          if (
            this.userId === this.missedCallData.primary_actor.meta?.assignee?.id
          ) {
            this.setCallData(this.missedCallData.primary_actor);
            this.setCallStatus('outgoing');
          } else {
            this.showAlert(
              this.$t('INBOX_MGMT.CALL_WIDGET.OUTGOING_CALL_ALERT')
            );
          }
        },
        errorMessage => {
          this.showAlert(errorMessage);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.missed-call-block {
  padding: var(--space-normal);
  margin: var(--space-smaller);
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow-medium);
  background-color: var(--white);
}
.missed-call-info {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 80px;
  .missed-call-data {
    padding-left: var(--space-small);
    flex-grow: 1;
  }
  .missed-call-user-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .missed-call-status {
    color: #fb7a89;
  }
  .missed-call-avatar {
    display: flex;
    align-items: center;
    position: relative;
    .missed-call-avatar-img {
      width: 48px;
      height: 48px;
      border-radius: var(--border-radius-rounded);
    }
    .missed-call-sender-name {
      height: 48px;
      width: 48px;
      border-radius: 50%;
    }
  }
  .phone-number-flag {
    height: 18px;
    width: 18px;
    overflow: hidden;
    border-radius: var(--border-radius-rounded);
    position: absolute;
    left: 25px;
    top: -3px;
  }
  .missed-call-badge {
    background: #fb7a89;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: var(--space-smaller);
  }
  .missed-call-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.missed-call-actions {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .missed-call-action-events {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-grow: 1;
  }
  .missed-call-action-event {
    border-radius: var(--border-radius-semirounded);
    padding: var(--space-one);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: var(--font-size-normal);
    cursor: pointer;
  }
  .missed-call-action-event.close {
    padding: var(--space-small) var(--space-two);
  }
  .call {
    background-color: var(--g-350);
    color: var(--white);
  }
  .go-to {
    background-color: var(--v-400);
  }
  .close {
    background-color: var(--s-50);
  }
}
.caller-name {
  text-transform: capitalize;
}
</style>
