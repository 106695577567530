/* global axios */

import ApiClient from './ApiClient';

class Onboarading extends ApiClient {
  constructor() {
    super('', { accountScoped: true, apiVersion: 'v1' });
  }

  async sendTestMessage({ inboxId, assigneeId, message, contactName }) {
    const {
      data: {
        payload: { contact },
      },
    } = await axios.post(`${this.url}contacts`, {
      name: contactName,
      inbox_id: inboxId,
    });
    const { data: conversation } = await axios.post(
      `${this.url}conversations`,
      {
        account_id: this.accountIdFromRoute,
        inbox_id: inboxId,
        contact_id: contact.id,
        assignee_id: assigneeId,
      }
    );
    const msg = await axios.post(
      `${this.url}conversations/${conversation.id}/messages`,
      {
        content: message,
      }
    );
    return msg;
  }
}

export default new Onboarading();
