<!-- eslint-disable no-useless-escape -->
<template>
  <div />
</template>
<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import onboardingMixin from 'dashboard/mixins/onboardingMixin';
import onboardingApi from '../../api/onboarding';
import { LocalStorage } from 'shared/helpers/localStorage';

export default {
  name: 'ProductFruits',
  mixins: [adminMixin, onboardingMixin],
  data() {
    return { scriptElement: null };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      getProductFruitsHMAC: 'getProductFruitsHMAC',
      currentAccountId: 'getCurrentAccountId',
      subscription: 'billing/getBillingInfo',
    }),
    projectCode() {
      return window.productFruitWorkspaceCode;
    },
    language() {
      return 'en';
    },
    userData() {
      if (!this.currentUser || !this.subscription?.status) return null;
      return {
        username: this.currentUser.email,
        email: this.currentUser.email,
        firstname:
          this.currentUser.display_name || this.currentUser.available_name,
        lastname: '',
        signUpAt: new Date(this.subscription?.customer?.created * 1000),
        role: this.isAdmin ? 'Administrator' : 'Agent',
        props: {
          account_id: this.currentAccountId,
          user_id: this.currentUser.id,
          subscription_plan: this.subscription.product?.name,
          subscription_is_trial: this.subscription.isTrial,
        },
        hmac: {
          hash: this.getProductFruitsHMAC,
        },
      };
    },
  },
  watch: {
    currentAccountId() {
      this.reloadLibrary();
    },
    subscription() {
      this.reloadLibrary();
    },
  },
  mounted() {
    this.reloadLibrary();
    window.addEventListener('productfruits_ready', async () => {
      const checklists = await this.getActiveChecklists();
      this.$store.dispatch('onboarding/setChecklists', { checklists });

      if (checklists.length > 0) {
        const api = this.getChecklistAPI();
        api.listen('item-launched', () => {
          this.$store.dispatch('onboarding/setPanelOpen', { isOpen: false });
        });
        api.listen('item-completed', async itemId => {
          const updatedChecklists = await this.getActiveChecklists();
          this.$store.dispatch('onboarding/setChecklists', {
            checklists: updatedChecklists,
          });
          this.$store.dispatch('onboarding/setPanelOpen', { isOpen: true });
          const itemIndex = updatedChecklists[0].items.findIndex(
            chItem => chItem.id === itemId
          );
          if (itemIndex === 1) {
            const pfLastInboxEvent = LocalStorage.get(
              'pf_lastInboxCreatedEvent'
            );
            if (pfLastInboxEvent.data?.inbox_id) {
              await onboardingApi.sendTestMessage({
                inboxId: pfLastInboxEvent.data.inbox_id,
                message:
                  'Hey there, can you help me upgrade my subscription plan?',
                contactName: 'Customer',
                assigneeId: this.currentUser.id,
              });
            }
          }
        });

        api.listen('dismissed', () => {
          this.$store.dispatch('onboarding/setPanelOpen', { isOpen: false });
        });
      }

      window.$productFruits.push([
        'init',
        this.projectCode,
        'en',
        this.userData,
        {
          customNavigation: {
            use: true,
            navigate: url => {
              this.$router.push(url);
            },
            onGet() {
              return window.location.href;
            },
          },
        },
      ]);
    });
  },
  beforeDestroy() {
    if (!window.productFruits || !window.productFruits.services) return;
    window.productFruits.services.destroy();
    delete window.productFruits;
    delete window.productFruitsUser;
    window.productFruitsUnmounted = true;
    if (this.scriptElement) this.scriptElement.remove();
  },
  methods: {
    reloadLibrary() {
      const user = this.userData;
      if (user) {
        if (!window.productFruits || !window.productFruits.identifyUser) {
          window.productFruitsUser = user;
        } else {
          window.productFruits.identifyUser(user);
        }

        window.productFruits = window.productFruits || {};
        const fireLanguageChangedEvent =
          window.productFruits.language &&
          window.productFruits.language !== this.language;
        window.productFruits.language = this.language;
        window.productFruits.code = this.projectCode;
        if (fireLanguageChangedEvent) {
          document.dispatchEvent(new CustomEvent('pf:language_changed'));
        }
      }

      if (!this.scriptElement && user) {
        ((w, d, u, c) => {
          const headEl = d.getElementsByTagName('head')[0];
          const scriptEl = d.createElement('script');
          scriptEl.async = 1;
          scriptEl.src = `${u}?c=${c}`;
          this.scriptElement = scriptEl;
          headEl.appendChild(scriptEl);
        })(
          window,
          document,
          'https://app.productfruits.com/static/script.js',
          this.projectCode,
          this.language
        );
      }
      if (window.productFruitsUnmounted && window.productFruitsInit) {
        window.productFruitsInit();
        delete window.productFruitsUnmounted;
      }
    },
  },
};
</script>
