export default {
  update(el, binding) {
    // If the condition is true, adding the element into element
    if (binding.value) {
      let existingParentDiv = el.querySelector('.parent-element');
      if (!existingParentDiv) {
        existingParentDiv = document.createElement('div');
        existingParentDiv.className = 'parent-element';
        const childDiv = document.createElement('div');
        childDiv.className = 'filtered-circle';
        existingParentDiv.appendChild(childDiv);
        el.appendChild(existingParentDiv);
      }
    } else {
      const existingParentDiv = el.querySelector('.parent-element');
      if (existingParentDiv) {
        el.removeChild(existingParentDiv);
      }
    }
  },
};
