<template>
  <div class="date-picker">
    <date-picker
      :type="pickerType"
      :range="true"
      :confirm="true"
      :show-second="false"
      format="DD-MM-YY HH:mm"
      :clearable="false"
      :show-time-header="true"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="value"
      popup-class="custom-datepicker-popup"
      :time-picker-options="{
        start: '00:00',
        step: '00:10',
        end: '23:59',
        format: 'HH:mm',
      }"
      @change="handleChange"
    >
      <template v-slot:footer>
        <button
          class="mx-btn mx-datepicker-btn-confirm"
          style="cursor: pointer; margin-right: 10px"
          @click="switchToTime"
        >
          {{ $t('FORMS.MULTISELECT.SELECT') }}
          <span>{{
            $t(`FORMS.MULTISELECT.${pickerType === 'date' ? 'TIME' : 'DATE'}`)
          }}</span>
        </button>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
export default {
  components: { DatePicker },
  props: {
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pickerType: 'date',
    };
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
    switchToTime() {
      if (this.pickerType === 'date') {
        this.pickerType = 'time';
      } else {
        this.pickerType = 'date';
      }
    },
  },
};
</script>

<style lang="scss">
.custom-datepicker-popup {
  width: 496px !important;
}
.mx-datepicker-content {
  .mx-range-wrapper {
    height: 280px !important;
  }
}
.mx-time-content {
  height: 240px !important;
}
</style>
