/* global axios */
import ApiClient from './ApiClient';

class AccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createAccount(data) {
    return axios.post(`${this.apiVersion}/accounts`, data);
  }

  logActivity(data) {
    return axios.post(
      `${this.apiVersion}/accounts/${data.accountId}/log_activity`,
      data
    );
  }
}

export default new AccountAPI();
