export default {
  methods: {
    async replaceRoute(name, params = {}, force = false) {
      if (this.$route.name !== name) {
        return this.$router.replace({ name, params }).catch();
      }

      if (force) {
        return this.$router.replace({ name: 'redirect' }).then(() => {
          setTimeout(() => {
            return this.$router.replace({ name, params }).catch();
          }, 200);
        });
      }

      return undefined;
    },
  },
};
