var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns small-12"},[_c('div',{staticClass:"medium-12 columns"},[_c('page-header',{attrs:{"header-content":_vm.$t('INBOX_MGMT.ADD.EMAIL_DNS.DESC'),"header-title":_vm.$t('INBOX_MGMT.ADD.EMAIL_DNS.TITLE')}})],1),_vm._v(" "),(_vm.gettingDnsRecords)?_c('div',[_c('p',{staticClass:"center bold"},[_vm._v("\n      "+_vm._s(_vm.$t('INBOX_MGMT.ADD.EMAIL_DNS.GETTING_DNS_RECORDS'))+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.isVerifying)?_c('div',[_c('p',{staticClass:"center bold"},[_vm._v("\n      "+_vm._s(_vm.$t('INBOX_MGMT.ADD.EMAIL_DNS.VERIFYING_DNS_RECORDS'))+"\n    ")])]):_vm._e(),_vm._v(" "),(!_vm.gettingDnsRecords)?_c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"medium-12 columns"},[_c('div',{staticClass:"medium-12 columns"},_vm._l((_vm.getDnsRecords()),function(item,index){return _c('div',{key:item.host,staticClass:"medium-12 records-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"medium-1 header"},[_vm._v("\n              Type:\n            ")]),_vm._v(" "),_c('div',{staticClass:"medium-11 record"},[_vm._v("\n              CNAME ( "+_vm._s(index)+" )\n              "),(
                  _vm.verification_status[index] &&
                    _vm.verification_status[index]['valid']
                )?_c('span',{staticClass:"success-message"},[_vm._v("\n                "+_vm._s(_vm.$t('INBOX_MGMT.ADD.EMAIL_DNS.VERIFIED'))+"\n              ")]):_vm._e(),_vm._v(" "),(
                  _vm.verification_status[index] &&
                    !_vm.verification_status[index]['valid']
                )?_c('span',{staticClass:"error-message"},[_vm._v("\n                "+_vm._s(_vm.$t('INBOX_MGMT.ADD.EMAIL_DNS.FAILED'))+"\n              ")]):_vm._e()]),_vm._v(" "),(
                _vm.verification_status[index] !== undefined &&
                  !_vm.verification_status[index]['valid']
              )?_c('div',{staticClass:"medium-12 error-message"},[_vm._v("\n              "+_vm._s(_vm.verification_status[index]['reason'])+"\n            ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"medium-1 header"},[_vm._v("\n              Host:\n            ")]),_vm._v(" "),_c('div',{staticClass:"medium-11 record"},[_c('woot-code',{attrs:{"script":item['host']}})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"medium-1 header"},[_vm._v("\n              Value:\n            ")]),_vm._v(" "),_c('div',{staticClass:"medium-11 record"},[_c('woot-code',{attrs:{"script":item['data']}})],1)])])}),0)]),_vm._v(" "),_c('div',{staticClass:"medium-12 columns submit-btn-holder"},[_c('woot-button',{attrs:{"is-loading":_vm.isVerifying},on:{"click":function($event){return _vm.verifyDnsRecords()}}},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.EMAIL_DNS.VERIFY_BUTTON'))+"\n      ")]),_vm._v(" "),_c('woot-button',{attrs:{"is-loading":_vm.isSaving},on:{"click":function($event){return _vm.saveSenderMethod(true, _vm.isVerified)}}},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.EMAIL_DNS.SAVE_BUTTON'))+"\n      ")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }