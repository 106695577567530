<template>
  <div class="reply-editor">
    <div class="reply-editor__content">
      {{ $t('CONVERSATION.REPLYBOX.REPLYING_TO') }}
      <woot-button
        v-tooltip="$t('CONVERSATION.REPLYBOX.DISMISS_REPLY')"
        color-scheme="secondary"
        icon="dismiss"
        variant="clear"
        size="tiny"
        class="reply-editor__close"
        @click.stop="closeReply"
      />
    </div>
    <div class="reply-editor__message">
      <MessagePreview
        :message="message"
        :show-message-type="false"
        :default-empty-message="$t('CONVERSATION.REPLY_MESSAGE_NOT_FOUND')"
        class="inline"
      />
    </div>
  </div>
</template>

<script>
import MessagePreview from 'dashboard/components/widgets/conversation/MessagePreview.vue';
import { LocalStorage } from '../../../../shared/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from '../../../constants/localStorage';

export default {
  name: 'ReplyToMessage',
  components: { MessagePreview },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    LocalStorage() {
      return LocalStorage;
    },
  },
  methods: {
    closeReply() {
      this.$emit('dismiss');
      LocalStorage.remove(LOCAL_STORAGE_KEYS.MESSAGE_LAST_REPLY);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../../dashboard/assets/scss/widgets/reply-box';
</style>
