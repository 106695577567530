import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({ teams: 'teams/getTeams' }),
    hasAnAssignedTeam() {
      return !!this.currentChat?.meta?.team;
    },
    teamsWithConfirmedAgent() {
      const teamsData = this.teams.filter(team =>
        team.agents.some(agent => agent.confirmed)
      );
      return this.hasAnAssignedTeam
        ? [{ id: 0, name: 'None' }, ...teamsData]
        : teamsData;
    },
    teamsList() {
      return this.hasAnAssignedTeam
        ? [{ id: 0, name: 'None' }, ...this.teams]
        : this.teams;
    },
  },
  methods: {
    getTeamsWithConfirmedAgent(teams = null) {
      const teamsData = teams || this.teams;
      return teamsData.filter(team =>
        team.agents.some(agent => agent.confirmed)
      );
    },
  },
};
