<template>
  <div class="multiselect-wrap--small">
    <multiselect
      v-model="selectedOptions"
      class="no-margin"
      :options="options"
      track-by="id"
      label="name"
      :multiple="true"
      :limit="2"
      :limit-text="count => '+' + count"
      :close-on-select="false"
      :clear-on-select="false"
      :hide-selected="true"
      :placeholder="$t('CSAT_REPORTS.FILTERS.AGENTBOTS.PLACEHOLDER')"
      selected-label
      :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
      :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
      @input="handleInput"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReportsFiltersAgentbots',
  data() {
    return {
      selectedOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      options: 'agentBots/getKnowledgeBasedBots',
    }),
  },
  mounted() {
    this.$store.dispatch('agentBots/get');
  },
  methods: {
    handleInput() {
      this.$emit('agentbots-filter-selection', this.selectedOptions);
    },
  },
};
</script>
<style scoped lang="scss">
.no-margin {
  &::v-deep .multiselect__strong {
    margin-right: var(--space-smaller) !important;
    margin-bottom: 0 !important;
    line-height: 0 !important;
    font-weight: 400 !important;
    font-size: var(--font-size-small) !important;
  }
  &::v-deep .multiselect__tag {
    max-width: 150px !important;
    @media screen and (min-width: 1250px) {
      max-width: 80px !important;
    }
  }
}
</style>
