/**
 * Will call onBusy, onOnline, and onOffline
 */
export default {
  data() {
    return {
      debugEnabled: false,
      idleTimer: null,
      onlineTimer: null,
      offlineTimer: null,
      idleTimeout: 300000, // 300000 milliseconds = 5 minutes
      onlineInterval: 10000, // 10000 milliseconds = 10 seconds
      isOnline: true,
      isAgentBusy: true,
      activityLogEnabled: false, // Disable activity logs
    };
  },
  mounted() {
    if (this.activityLogEnabled) {
      // Attach event listeners on component mount
      this.attachEventListeners();

      // Start the online interval check
      this.startOnlineInterval();
    }
  },
  methods: {
    debug(...args) {
      if (this.debugEnabled) {
        // eslint-disable-next-line no-console
        console.log(...args);
      }
    },
    attachEventListeners() {
      // Listener for typing and activity
      document.addEventListener('keydown', this.resetIdleTimer);
      document.addEventListener('mousemove', this.resetIdleTimer);
      document.addEventListener('mousedown', this.resetIdleTimer);
      document.addEventListener('touchstart', this.resetIdleTimer);
      window.addEventListener('online', this.onOnlineStatus);
      window.addEventListener('offline', this.onOfflineStatus);

      // Start initial busy timer
      this.startIdleTimer();
    },
    startIdleTimer() {
      this.idleTimer = setTimeout(() => {
        this.isAgentBusy = false;
        if (this.isOnline) {
          this.debug('Agent stopped working, but Online');
          this.onOnline();
        }
      }, this.idleTimeout);
    },
    resetIdleTimer() {
      if (this.isOnline) {
        this.debug('Agent in busy');
        this.isAgentBusy = true;
        clearTimeout(this.idleTimer);
        this.startIdleTimer();
      }
    },
    startOnlineInterval() {
      this.onlineTimer = setInterval(() => {
        if (this.isAgentBusy) this.onBusy();
        else if (this.isOnline) {
          this.debug('Agent stopped working, but Online');
          this.onOnline();
        } else {
          this.debug('Agent stopped working and Offline');
          this.onOffline();
        }
      }, this.onlineInterval);
    },
    onOnlineStatus() {
      this.debug('Agent online');

      this.isOnline = true;
      this.onOnline();
    },
    onOfflineStatus() {
      this.debug('Agent offline');
      this.isOnline = false;
      this.onOffline();
    },
  },
  beforeDestroy() {
    if (this.activityLogEnabled) {
      // Clean up event listeners on component destroy
      document.removeEventListener('keydown', this.resetIdleTimer);
      document.removeEventListener('mousemove', this.resetIdleTimer);
      document.removeEventListener('mousedown', this.resetIdleTimer);
      document.removeEventListener('touchstart', this.resetIdleTimer);
      window.removeEventListener('online', this.onOnlineStatus);
      window.removeEventListener('offline', this.onOfflineStatus);

      clearTimeout(this.idleTimer);
      clearInterval(this.onlineTimer);
    }
  },
};
