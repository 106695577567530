<template>
  <div class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.IMAP.TITLE')"
      :sub-title="$t('INBOX_MGMT.IMAP.SUBTITLE')"
      :note="$t('INBOX_MGMT.IMAP.NOTE_TEXT')"
    >
      <div class="message">
        <h5>
          {{ $t('INBOX_MGMT.FINISH.CONFIGURATION_TITLE') }}
        </h5>
        <p
          v-dompurify-html="$t('INBOX_MGMT.FINISH.CONFIGURATION_DESCRIPTION')"
        />
        <p>{{ $t('INBOX_MGMT.FINISH.CONFIGURATION_CONTACT_SUPPORT') }}</p>
      </div>
      <form @submit.prevent="updateInbox">
        <div class="medium-12 columns">
          <label>
            <select v-model="selectedEmailType">
              <option
                v-for="item in $t('INBOX_MGMT.IMAP.EMAIL_TYPES')"
                :key="item.id"
                :value="item.id"
                class="mail-option"
              >
                {{ item.value }}
              </option>
            </select>
          </label>
        </div>
        <div v-if="selectedEmailType === 'single_email'">
          <label for="toggle-imap-enable">
            <input
              v-model="isIMAPEnabled"
              type="checkbox"
              name="toggle-imap-enable"
            />
            {{ $t('INBOX_MGMT.IMAP.TOGGLE_AVAILABILITY') }}
          </label>
          <p>{{ $t('INBOX_MGMT.IMAP.TOGGLE_HELP') }}</p>
          <div v-if="isIMAPEnabled" class="imap-details-wrap">
            <woot-input
              v-model.trim="address"
              :class="{ error: $v.address.$error }"
              class="medium-9 columns"
              :label="$t('INBOX_MGMT.IMAP.ADDRESS.LABEL')"
              :placeholder="$t('INBOX_MGMT.IMAP.ADDRESS.PLACE_HOLDER')"
              @blur="$v.address.$touch"
            />
            <woot-input
              v-model="port"
              type="number"
              :class="{ error: $v.port.$error }"
              class="medium-9 columns"
              :label="$t('INBOX_MGMT.IMAP.PORT.LABEL')"
              :placeholder="$t('INBOX_MGMT.IMAP.PORT.PLACE_HOLDER')"
              @blur="$v.port.$touch"
            />
            <woot-input
              v-model="login"
              :class="{ error: $v.login.$error }"
              class="medium-9 columns"
              :label="$t('INBOX_MGMT.IMAP.LOGIN.LABEL')"
              :placeholder="$t('INBOX_MGMT.IMAP.LOGIN.PLACE_HOLDER')"
              @blur="$v.login.$touch"
            />
            <woot-input
              v-model="password"
              :class="{ error: $v.password.$error }"
              class="medium-9 columns"
              :label="$t('INBOX_MGMT.IMAP.PASSWORD.LABEL')"
              :placeholder="$t('INBOX_MGMT.IMAP.PASSWORD.PLACE_HOLDER')"
              type="password"
              @blur="$v.password.$touch"
            />
            <label for="toggle-enable-ssl">
              <input
                v-model="isSSLEnabled"
                type="checkbox"
                name="toggle-enable-ssl"
              />
              {{ $t('INBOX_MGMT.IMAP.ENABLE_SSL') }}
            </label>
          </div>
          <woot-submit-button
            :button-text="$t('INBOX_MGMT.IMAP.UPDATE')"
            :loading="uiFlags.isUpdatingIMAP"
            :disabled="($v.$invalid && isIMAPEnabled) || uiFlags.isUpdatingIMAP"
          />
        </div>
        <div v-if="selectedEmailType === 'domain_email'">
          <AddDns />
        </div>
      </form>
    </settings-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import SettingsSection from 'dashboard/components/SettingsSection';
import { required, minLength } from 'vuelidate/lib/validators';
import AddDns from './AddDns.vue';

export default {
  components: {
    AddDns,
    SettingsSection,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isIMAPEnabled: false,
      address: '',
      port: '',
      login: '',
      password: '',
      isSSLEnabled: true,
      dns_enabled: false,
      dns_verified: false,
      selectedEmailType: 'select_email_type',
    };
  },
  validations: {
    address: { required },
    port: { required, minLength: minLength(2) },
    login: { required },
    password: { required },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      inboxes: 'inboxes/getInboxes',
    }),
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      const {
        imap_enabled,
        imap_address,
        imap_port,
        imap_login,
        imap_password,
        imap_enable_ssl,
        dns_enabled,
        dns_verified,
      } = this.inbox;
      this.isIMAPEnabled = imap_enabled;
      this.address = imap_address;
      this.port = imap_port;
      this.login = imap_login;
      this.password = imap_password;
      this.isSSLEnabled = imap_enable_ssl;
      this.dns_enabled = dns_enabled;
      this.dns_verified = dns_verified;
      this.selectedEmailType = this.getSelectedEmailType();
    },
    async updateInbox() {
      try {
        this.loading = true;
        let payload = {
          id: this.inbox.id,
          formData: false,
          channel: {
            imap_enabled: this.isIMAPEnabled,
            imap_address: this.address,
            imap_port: this.port,
            imap_login: this.login,
            imap_password: this.password,
            imap_enable_ssl: this.isSSLEnabled,
            dns_enabled: false,
            imap_inbox_synced_at: this.isIMAPEnabled
              ? new Date().toISOString()
              : undefined,
          },
        };

        if (!this.isIMAPEnabled) {
          payload.channel.smtp_enabled = false;
        }

        await this.$store.dispatch('inboxes/updateInboxIMAP', payload);
        this.showAlert(this.$t('INBOX_MGMT.IMAP.EDIT.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(error.message);
      }
    },
    getSelectedEmailType() {
      if (this.dns_enabled) {
        return 'domain_email';
      }
      if (this.isIMAPEnabled) {
        return 'single_email';
      }
      return 'select_email_type';
    },
  },
};
</script>
<style lang="scss" scoped>
.imap-details-wrap {
  margin-bottom: var(--space-medium);
}
.mail-option {
  font-size: 15px !important;
}

.message h5 {
  margin-top: 25px;
  font-weight: var(--font-weight-bold);
}
</style>
