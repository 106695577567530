<template>
  <div ref="mentionsListContainer" class="mention--box">
    <span v-if="!dataLoaded" class="spinner" />
    <ul v-else-if="items.length" class="vertical dropdown menu">
      <template v-for="(item, index) in items">
        <woot-dropdown-item
          v-if="item"
          :id="`mention-item-${index}`"
          :key="item.key"
          @mouseover="onHover(index)"
        >
          <woot-button
            class="canned-item__button"
            :variant="index === selectedIndex ? '' : 'clear'"
            :class="{ active: index === selectedIndex }"
            @click="onListItemSelection(index)"
          >
            <strong>{{ item.label }}</strong> -
            {{ getDescription(item.description) }}
          </woot-button>
        </woot-dropdown-item>
      </template>
    </ul>
    <div v-else-if="dataLoaded && !items.length" class="no-match">
      {{ $t('CONVERSATION.REPLYBOX.NO_MATCHING_RESULTS') }}
    </div>
  </div>
</template>

<script>
import mentionSelectionKeyboardMixin from './mentionSelectionKeyboardMixin';
import { removeExtraLines } from 'dashboard/helper/commons.js';

export default {
  mixins: [mentionSelectionKeyboardMixin],
  props: {
    items: {
      type: Array,
      default: () => {},
    },
    dataLoaded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  watch: {
    items(newItems) {
      if (newItems.length < this.selectedIndex + 1) {
        this.selectedIndex = 0;
      }
    },
    selectedIndex() {
      const container = this.$refs.mentionsListContainer;
      if (!container) return;

      const item = container.querySelector(
        `#mention-item-${this.selectedIndex}`
      );
      if (item) {
        const itemTop = item.offsetTop;
        const itemBottom = itemTop + item.offsetHeight;
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.offsetHeight;
        if (itemTop < containerTop) {
          container.scrollTop = itemTop;
        } else if (itemBottom + 34 > containerBottom) {
          container.scrollTop = itemBottom - container.offsetHeight + 34;
        }
      }
    },
  },
  methods: {
    getDescription(desc) {
      return removeExtraLines(desc);
    },
    handleKeyboardEvent(e) {
      this.processKeyDownEvent(e);
    },
    onHover(index) {
      this.selectedIndex = index;
    },
    onListItemSelection(index) {
      this.selectedIndex = index;
      this.onSelect();
    },
    onSelect() {
      this.$emit('mention-select', this.items[this.selectedIndex]);
    },
  },
};
</script>

<style scoped lang="scss">
.mention--box {
  background: var(--white);
  border-radius: var(--border-radius-normal);
  border-top: 1px solid var(--color-border);
  box-shadow: var(--shadow-medium);
  left: 0;
  bottom: 100%;
  max-height: 15.6rem;
  overflow: auto;
  padding: var(--space-small) var(--space-small) 0;
  position: absolute;
  width: 100%;
  z-index: 100;
  min-height: var(--space-larger);

  .dropdown-menu__item:last-child {
    padding-bottom: var(--space-small);
  }

  .active {
    color: var(--white);

    &:hover {
      color: var(--v-700);
    }
  }

  .button {
    transition: none;
    height: var(--space-large);
    line-height: 1.4;
  }
}

.canned-item__button::v-deep .button__content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-match {
  width: 100%;
  text-align: left;
  color: var(--s-700);
  padding: var(--space-small);
  font-size: var(--font-size-small);
}
</style>
