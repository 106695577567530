const showNotification = () => {
  window.hasLocalStorageError = true;
};

export const LocalStorage = {
  clearAll() {
    try {
      window.localStorage.clear();
    } catch (error) {
      showNotification();
    }
  },

  get(key) {
    let value = null;
    try {
      value = window.localStorage.getItem(key);
    } catch (error) {
      showNotification();
      return null;
    }
    try {
      return typeof value === 'string' ? JSON.parse(value) : value;
    } catch (error) {
      return value;
    }
  },
  set(key, value) {
    try {
      if (typeof value === 'object') {
        window.localStorage.setItem(key, JSON.stringify(value));
      } else {
        window.localStorage.setItem(key, value);
      }
      window.localStorage.setItem(key + ':ts', Date.now());
    } catch (error) {
      showNotification();
    }
  },

  remove(key) {
    try {
      window.localStorage.removeItem(key);
      window.localStorage.removeItem(key + ':ts');
    } catch (error) {
      showNotification();
    }
  },

  updateJsonStore(storeName, key, value) {
    try {
      const storedValue = this.get(storeName) || {};
      storedValue[key] = value;
      this.set(storeName, storedValue);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error updating JSON store in localStorage', e);
    }
  },

  getFromJsonStore(storeName, key) {
    try {
      const storedValue = this.get(storeName) || {};
      return storedValue[key] || null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting value from JSON store in localStorage', e);
      return null;
    }
  },

  deleteFromJsonStore(storeName, key) {
    try {
      const storedValue = this.get(storeName);
      if (storedValue && key in storedValue) {
        delete storedValue[key];
        this.set(storeName, storedValue);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error deleting entry from JSON store in localStorage', e);
    }
  },
};
